
%splat-text {
  color: inherit;
  display: block;
  line-height: 1;
}


%splat-icon {
  height: 100%;
  overflow: hidden; // Avoid icon overlaying content
  position: absolute;
    top: 0;
    left: 0;
  width: 100%;

  &:before {
    font-size: inherit !important; // needed for overriding specificity from icon mixin
    line-height: 1;
    margin-top: -0.5em;
    position: absolute;
      left: 0;
      top: 50%;
    width: 100%;
  }
}

$splat-base-font: $font-size-large !default;
$splat-size: px-to-em(65px, $splat-base-font) !default;
$splat-size-large: px-to-em(140px, $splat-base-font) !default;
$splat-size-xlarge: px-to-em(160px, $splat-base-font) !default;

.splat {
  @extend %border-circle;
  background-color: transparent;
  color: $color-text-inverse;
  display: block;
  height: $splat-size;
  text-align: center;
  position: relative;
  width: $splat-size;

  &__inner {
    display: table-cell;
    height: 100%;
    vertical-align: middle;
    width: 100%;
  }

  // Element needed for having both border-radius and border on iOS (bug)
  &__border {
    @extend %border-circle;
    display: table;
    height: $splat-size;
    position: absolute;
      left: 0;
      top: 0;
    width: $splat-size;
    @include box-sizing(border-box);
  }

  &__text {
    @extend %splat-text;
    font-weight: 500;
    text-transform: uppercase;
    @include rem( margin, 2px 0 );
    &--primary {
      @extend .splat__text;
      font-size: px-to-em(18px, $splat-base-font);
      font-weight: 700;
    }
    &--secondary {
      @extend .splat__text;
      font-size: px-to-em(12px, $splat-base-font);
    }
  }

  &__image {
    width: 65%;

    @include susy-breakpoint( $layout-l... ) { width: auto; }
  }

  &--secondary {
    @extend .splat;
    background-color: $brand-color-quaternary;
    color: $color-text-inverse;
  }

  &--tertiary {
    @extend .splat;
    background-color: $brand-color-senary;
    color: $color-display;
  }

  &--large {
    @extend .splat;
    height: $splat-size-large;
    width: $splat-size-large;

    // Setting size on different layouts
    font-size: px-to-em(8px, $font-size-small);
    @include susy-breakpoint( $layout-l... ) { font-size: px-to-em(12px, $font-size-large); }
    @include susy-breakpoint( $layout-xl... ) { font-size: px-to-em(10px, $font-size-large); }
    @include susy-breakpoint( $layout-xxl... ) { font-size: px-to-em($splat-base-font, $font-size-large); }

    .splat__text {
      font-weight: 300;
      text-transform: none;
      @include rem( margin, 6px 0 );
      &--primary {
        font-size: px-to-em(24px, $splat-base-font);
        text-transform: uppercase;
      }
      &--secondary { font-size: px-to-em(16px, $splat-base-font); }
      &--tertiary {
        @extend .splat__text;
        font-size: px-to-em(11px, $splat-base-font);
      }
    }
  }

  &--large-alt {
    @extend .splat--large;
    @extend .splat--tertiary;
    display: table;
    .splat__text {
      &--primary {
        font-size: px-to-em(40px, $splat-base-font);
        font-weight: bold;
        margin: 0;
      }
      &--secondary {
        font-size: px-to-em(25px, $splat-base-font);
        margin-bottom: px-to-em(2px, 25px);
        text-transform: uppercase;
      }
      &--tertiary {
        font-size: px-to-em(13px, $splat-base-font);
        margin-top: px-to-em(2px, 13px);
      }
    }
  }

  // Bestseller
  &--bestseller {
    @extend .splat;
    background-color: $white;
    color: $brand-color-primary;
    height: px-to-em(78px, $splat-base-font); // Making space for leaves
    padding: px-to-em(10px, $splat-base-font); // push text
    position: relative;
    width: px-to-em(78px, $splat-base-font); // Making space for leaves
    @include box-sizing(border-box);
  }

  &__icon--bestseller {
    @extend %splat-icon;
    font-size: px-to-em(69px, $splat-base-font);

    &::before {
      color: $brand-color-quaternary;
      @include use-icon(leaves);
    }
  }

  // Text
  &__text--bestseller {
    @extend .splat__text--primary;
    font-size: px-to-em(12px, $splat-base-font);
  }

  // Bestseller - large
  &--bestseller--large {
    @extend .splat--large;
    @extend .splat--bestseller;
    display: block;
    height: $splat-size-xlarge;
    width: $splat-size-xlarge;

    .splat__border {
      border: 2px dotted $brand-color-primary;
      height: $splat-size-xlarge;
      width: $splat-size-xlarge;
      @include rem(padding, 0 20px); // push text
    }
  }

  &__icon--bestseller--large {
    @extend %splat-icon;
    font-size: px-to-em(123px, $splat-base-font);

    &:before {
      color: $brand-color-quaternary;
      @include use-icon(leaves);
    }
  }

  // Bedst i test
  &--bit {
    @extend .splat;
    background-color: $brand-color-quaternary;
    height: $splat-size;
    position: relative;
    width: $splat-size;
  }

  &--jul {
    position: absolute;
      left: 0;
      top: 0;
  }

  &__icon--bit {
    @extend %splat-icon;
    font-size: px-to-em(57px, $splat-base-font);

    &:before {
      color: $white;
      @include use-icon(stars);
    }
  }

  // Text
  &__text--bit {
    display: block;
    font-size: px-to-em(11px, $splat-base-font);
    line-height: 1;
    // Push text
    padding: px-to-em(3px, $splat-base-font) px-to-em(6px, $splat-base-font) 0;
  }

  // Bedst i test - large
  &--bit--large {
    @extend .splat--large;
    @extend .splat--bit;
    height: $splat-size-xlarge;
    width: $splat-size-xlarge;

    .splat__text--bit {
      font-size: px-to-em(24px, $splat-base-font);
      padding: 0;
    }
  }

  &__icon--bit--large {
    @extend %splat-icon;
    font-size: px-to-em(130px, $splat-base-font);

    &:before {
      color: $white;
      @include use-icon(stars);
    }
  }
}
