// ---------------------------------------------------------------------------
// Icons

@font-face {
    font-family: 'icons';
    src: url('../fonts/icons.eot');
    src: url('../fonts/icons.eot?#iefix') format('embedded-opentype'),
         url('../fonts/icons.woff') format('woff'),
         url('../fonts/icons.ttf') format('truetype'),
         url('../fonts/icons.svg#icons') format('svg');
}

@font-face {
    font-family: 'Open Sans';
    src: url('../fonts/opensans-light-webfont.woff2') format('woff2'),
         url('../fonts/opensans-light-webfont.woff') format('woff');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Open Sans';
    src: url('../fonts/opensans-regular-webfont.woff2') format('woff2'),
         url('../fonts/opensans-regular-webfont.woff') format('woff');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Open Sans';
    src: url('../fonts/opensans-semibold-webfont.woff2') format('woff2'),
         url('../fonts/opensans-semibold-webfont.woff') format('woff');
    font-weight: 600;
    font-style: normal;
}
