// All elements, which are exclusive and native to the navigation, belong here.
// It can be the main-, touch-, sub- or footer navigation.
// It's very easy to label an element as a navigation-element.
// You are encouraged to create similar partials which make sense for your project.

// ==========================================================================
// Accordion behavior

%accordion-target {
  max-height: 0;
  overflow: hidden;

  &.active {
    max-height: none;
  }
}

@mixin disable-accordion {
  overflow: visible;
  max-height: none !important;
}

// ==========================================================================
// Mobile fullscreen card

%fullscreen-card {
  overflow: scroll;
  position: fixed;
    bottom: 0;
    left: 0;
    top: $header-height-mobile;
  width: 100%;
  -webkit-overflow-scrolling: touch;
}

// ==========================================================================
// Page overlay

body {
  // Preventing scrollbars on mobile
  @include susy-breakpoint( $layout-m-down... ) {
    .no-js#scanpan:target &,
    .no-js &:target,
    &.show-menu,
    &.show-basket {
      overflow: hidden;
      position: fixed;
        top: 0;
    }
  }
}

.page-overlay {
  background-color: rgba(#000, 0.5);
  display: none;
  height: 100%;
  position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    z-index: 3;
  width: 100%;

  .no-js#scanpan:target &,
  .no-js #shop:target &,
  .show-menu &,
  .show-basket & { display: block; }

  @include susy-breakpoint( $layout-l... ) {
    .no-js#scanpan:target ,
    .show-menu & { display: none; }
  }
}

// ==========================================================================
// General nav

@mixin nav-link-mobile {
  border-bottom: 1px solid $nav-border-color;
  display: block;
  padding: 0.8em 8%;
  @include font-size(16px);
}

@mixin nav-link-mobile-arrow {
  position: relative;

  &:hover:after { color: inherit; }

  &:after {
    color: $color-light;
    float: right;
    margin-top: -6px;
    position: absolute;
      right: 10px;
      top: 50%;
    @include font-size(11px);
    @include use-icon(arrow-down, true);
  }
}



.nav {
  @include susy-breakpoint( $layout-l... ) { text-align: center; }

  &__list {
    @extend %list-clean;
    & & {
      @include susy-breakpoint( $layout-l... ) {
        background-color: $nav-background;
        min-width: 256px;
        position: absolute;
        text-align: left;

        .nav__item {
          border-top: 1px solid $nav-border-color;

          &:first-child { border-top: none; }
        }
      }
    }

    @include susy-breakpoint( $layout-m-down... ) {
      .nav__item.active > & { display: block; }
    }
    @include susy-breakpoint( $layout-l... ) {
      display: inline-block;

      .no-js .nav__item:hover > &,
      .nav__item.hover > & { display: block; }
    }
  }

  &__item {
    @include susy-breakpoint( $layout-l... ) {
      float: left;
      & & { float: none; }
      &:last-child {
        position: absolute;
        right: 0;
      }
    }
  }

  &__item--has-children {
    @extend .nav__item !optional;
    > .nav__list {
      @extend %accordion-target;
      @include susy-breakpoint( $layout-l... ) {
        display: none;
        @include disable-accordion;
      }
    }
  }

  &__link {
    display: block;
    font-weight: 300;
    padding: 15px;
    width: 100%;
    @include box-sizing(border-box);

    &,
    &:hover,
    &:focus,
    &:active {
      color: $nav-link-color;
      text-decoration: none;
    }

    @include susy-breakpoint( $layout-l... ) {
      display: inline-block; // Necessary to show icons correctly in IE

      .no-js .nav__item--has-children:hover > &,
      .nav__item--has-children.hover > & {
        background-color: $nav-background;
        color: $brand-color-secondary;

        &:after { color: inherit; }
      }
    }

    .nav__item--has-children > & {
      @include nav-link-mobile-arrow;

      &.active:after { @include rotateZ(0deg); }
    }

    @include susy-breakpoint( $layout-m-down... ) {
      @include nav-link-mobile;

      .nav__item--has-children .nav__list & {
        color: $nav-link-color-secondary;
        padding: 0.8em 12%;
      }
    }

    @include susy-breakpoint( $layout-l... ) {
      &:hover,
      &:focus,
      &:active {
        background-color: $nav-background;
        color: $brand-color-secondary;

        &:after { color: inherit; }
      }

      .nav__item.active > & {
        color: $brand-color-secondary;

        &.highlight--secondary { color: $brand-color-primary; }
      }
    }

    @include susy-breakpoint( $layout-xl... ) {
      padding: 19px 23px;
      @include font-size(16px);

      .nav__item--has-children .nav__list & { @include font-size(16px); }

      &:after {
        position: relative;
          top: -2px;
      }
    }
  }

  &__highlight {
    &--senary {
      color: $brand-color-primary;
      background-color: $brand-color-senary;
    }
  }
}

// --------------------------------------------------
// Header Nav (Layout Partial)
// --------------------------------------------------

.header-nav {
  box-sizing: border-box;
  display: none;
  position: relative;

  @include susy-breakpoint( $layout-l... ) {
    display: block;
    //padding: 9px 0 0;
    text-align: center;
  }
}

.header-nav__list {
  padding: 0 0 0 20px;
  margin: 0;

  @include susy-breakpoint( $layout-xxl... ) {
    padding: 0;
    display: inline-block;
  }

  &.has-level-1 {
    float: left;
    margin: 60px 0;
    width: 100%;
  }

  &.has-level-2 {
    margin: 0;
    width: 100%;
  }
}

.header-nav__container { position: relative; }

.header-nav__item {
  float: left;
  margin-left: 20px;

  &.last-child {
    @include susy-breakpoint( $layout-xxl... ) {
      position: absolute;
      right: 0;
      .header-nav__link {
        padding-right: 0;
        &:after {
          padding: 0;
          left: 0;
        }
      }
    }
    @media (max-width: 1380px) {
      position: relative;
    }
   }

  .has-column-count-4 & {
    float: left;
    width: percentage(1/4);

    @include susy-breakpoint( $layout-l... ) {
      &:last-child {
        position: relative;
      }
    }

    @include susy-breakpoint( $layout-xl... ) {
      width: percentage(1/4);

      &:nth-child(5n+1) { clear: none; }
      &:nth-child(3n+1) { clear: none; }
      &:nth-child(4n+1) { clear: left; }
    }
  }

  .has-level-1 > & {
    float: left;
    margin: 0 0 30px;
    position: relative;

    &:nth-child(-n+2)::after {
      @include susy-breakpoint( $layout-xl... ) {
        background-color: darken($white, 11%);
        content: "";
        height: 110%;
        position: absolute;
          right: 20%;
          top: 0;
        width: 1px;
      }
    }
  }

  .has-level-2 & {
    float: none;
    margin: 0;
    width: 75%;

    &:first-child .header-nav__link { margin: 0; }
  }

  &:first-child {
    margin-left: 0;

    .header-nav__link {
      padding-left: 0;

      &.header-nav__highlight--secondary,
      &.header-nav__highlight--senary { padding-left: 10px;}
    }
  }

}

.tablet {
  .header-nav > .header-nav__list > .header-nav__item.has-children:not(.last-child) > span {
    padding: 0 0.7em;
    position: relative;

    &::after {
      @include use-icon(arrow-down, true);
      line-height: 67px;
      color: $white;
      font-size: 9px;
      position: absolute;
    }
  }

  .header-nav > .header-nav__list > .header-nav__item.is-active.has-children:not(.last-child) > span::after {
    transform: rotate(180deg);
    top: 35px;
  }
}


.header-nav__top-level {
  position: absolute;
  display: none;
  top: 22px;

  &.is-active { display: block; }

  .header-nav__top-level--link {
    text-decoration: none;
    font-family: $font;
    font-size: $base-font-size;
    border: 1px solid $black;
    padding: 5px 10px;
    border-radius: 5px;
  }
}

.header-nav__link {
  color: $white;
  display: block;
  font-family: $font;
  font-size: 16px;
  text-decoration: none;
  padding: 20px 10px;
  position: relative;
  z-index: 1;
  display: inline-block;

  .is-active > & {
    &::after {
      border-bottom: 7px solid #AE9C57;
      content: "";
      padding: 0 6px;
      position: absolute;
      left: -11px;
      bottom: 0;
      width: 100%;
    }
  }

  .is-active > &,
  &.is-active,
  &.is-inpath { color: lighten($white, 20%); }

  .is-current > &,
  .is-inpath > & { color: $white; }

  &:hover,
  &:focus,
  &:active {
    color: $white;
    text-decoration: none;
  }

  .has-level-1 & {
    font-size: 21px;
    height: auto;
    line-height: normal;
    margin-bottom: 30px;
    padding: 0;
    color: $black;

    &:hover,
    &:focus,
    &:active {
      color: $black;
      text-decoration: none;
    }

    &::after { display: none; }
  }

  .has-level-2 & {
    color: lighten($black, 40%);
    font-size: 16px;
    margin: 10px 0 0;

    &::after { display: none; }

    &:hover { color: $black; }
  }
}



.header-nav__highlight--secondary,
.header-nav__highlight--senary {
  color: $brand-color-primary;
  font-weight: 600;

  &::after{ border-bottom: none; }
}

.header-nav__highlight--secondary { background-color: $brand-color-secondary; }

.header-nav__highlight--senary { background-color: $brand-color-senary; }

.header-nav__link.header-nav__highlight--secondary:hover,
.header-nav__link.header-nav__highlight--senary:hover { color: $brand-color-primary; }

.header-nav__item.is-active {
  .header-nav__highlight--secondary,
  .header-nav__highlight--senary {
    color: $brand-color-primary;

    &::after { border-bottom: none; }
  }

  .header-nav__highlight--secondary { background-color: lighten($brand-color-secondary, 5%); }
  .header-nav__highlight--senary { background-color: lighten($brand-color-senary, 5%); }
}

.nav-dropdown {
  background-color: $white;
  box-shadow: 0 7px 49px 0 rgba($black,0.25);
  height: 0;
  min-height: 350px;
  margin: 0 auto;
  opacity: 0;
  overflow: hidden;
  position: absolute;
    left: 0;
    right: 0;
  transition: visibility 0ms linear 250ms, opacity 250ms ease;
  visibility: hidden;
  width: 100%;
  z-index: 9;
  text-align: left;

  // Show mega menu when hovering over nav item (desktop/no-touch)
  .is-active + &,
  .is-active > & {
    height: auto;
    opacity: 1;
    transition: visibility 0 linear, opacity 250ms ease;
    visibility: visible;
  }

  // Show sub menu when tapping on menu item (mobile/touch)
  .is-active + & { display: block; }
}

.header-nav__cta {
  box-sizing: border-box;
  float: left;
  position: relative;
  transition: opacity 250ms ease;
  z-index: 10;
  margin-top: -30px;

  .splat {
    $splat-offset: $gutter-width - 10;
    background: #AE9C57;
    height: auto;
    pointer-events: none;
    position: absolute;
      top: 0;
      right: 0;
    width: 50px;
    height: 44px;
    z-index: 10;
    text-align: center;
    color: $black;
    padding: 18px 15px;

    .from {
      font-size: 15px;
      font-style: italic;
      font-family: 'Times New Roman', Times, serif;
      line-height: 0.8;
    }

    .price {
      font-size: 21px;
      font-weight: 600;
    }

    @include susy-breakpoint( $layout-l... ) {
      transform: scale(0.5, 0.5);
    }

    @include susy-breakpoint( $layout-xl... ) {
      transform: none;
      top: -50px;
    }
  }
}

.header-nav__cta-image {
  max-width: 80%;
}

.header-nav__top-button {
  position: fixed;
    left: 50%;
    top: 73px;
  min-width: 225px;
  transform: translateX(-50%);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  background-color: $black;
  padding: 0 10px 11px 30px;
  margin: 0;
  z-index: 10;

  &:hover {
    background-color: lighten($black, 10%);
  }

  .icon-next-circle {
    &::before {
      height: 26px;
      width: 26px;
      font-size: 26px;
      margin-left: 15px;
    }
  }

  @include susy-breakpoint( $layout-l... ) {
    top: 115px;
  }

  @include susy-breakpoint( $layout-xl... ) {
    position: relative;
    top: auto;
    width: auto;
    background-color: $brand-color-primary;
    box-shadow: none;
    border-radius: 0;
    display: inline-block;
    padding: 0 20px;
    line-height: 4;
    height: 64px;

    &:hover {
      background-color:  darken($color-selection, 10%);
    }

    .icon-next-circle {
      top: 0.75em;

      &::before {
        height: 35px;
        width: 35px;
        font-size: 35px;
      }
    }
  }
}
