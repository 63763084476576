// Custom functions and mixins

// ---------------------------------------------------------------------------
// Text behavior mixins

@mixin set-type-properties ( $properties ) {
  @each $property, $value in $properties {
    #{ $property }: $value;
  }
}

$types: () !default;

@mixin use-type( $name, $force: false ) {
  $type : map-get( $types, $name );
  @if $type == null {
    @warn "The $types map did not contain the key: " + $name;
  } @else {
    @if $force {
      @each $layout, $data in $type {
        @if $layout == defaults {
          @include set-type-properties ( $data );
        } @else if $layout == extend {
          @each $selector in $data {
            @extend #{ $selector } !optional;
          }
        } @else {
          @include susy-breakpoint( $layout... ) {
            @include set-type-properties ( $data );
          }
        }
      }
    } @else {
      @extend %#{ $name };
    }
  }
}

@mixin set-type-placeholders {
  @each $name, $settings in $types {
    %#{ $name } {
      @include use-type( $name, $force: true );
    }
  }
}

// ---------------------------------------------------------------------------
// At retina mixin
// As from https://github.com/raahede/ezy/blob/master/sass/ezy/_sprites.scss

@mixin at-retina( $ratio: 2, $query: false ) {
  @if $query {
    // Extending retina media query with breakpoint query
    @media
    only screen and (-webkit-min-device-pixel-ratio: #{ $ratio }) and #{ $query },
    only screen and (   min--moz-device-pixel-ratio: #{ $ratio }) and #{ $query },
    only screen and (   -moz-min-device-pixel-ratio: #{ $ratio }) and #{ $query },
    only screen and (    -ms-min-device-pixel-ratio: #{ $ratio }) and #{ $query },
    only screen and (     -o-min-device-pixel-ratio: #{ $ratio }/1) and #{ $query },
    only screen and (        min-device-pixel-ratio: #{ $ratio }) and #{ $query },
    only screen and (                min-resolution: #{ $ratio * 96 }dpi) and #{ $query },
    only screen and (                min-resolution: #{ $ratio }dppx) and #{ $query } {
      @content;
    }
  } @else {
    @media
    only screen and (-webkit-min-device-pixel-ratio: #{ $ratio }),
    only screen and (   min--moz-device-pixel-ratio: #{ $ratio }),
    only screen and (   -moz-min-device-pixel-ratio: #{ $ratio }),
    only screen and (    -ms-min-device-pixel-ratio: #{ $ratio }),
    only screen and (     -o-min-device-pixel-ratio: #{ $ratio }/1),
    only screen and (        min-device-pixel-ratio: #{ $ratio }),
    only screen and (                min-resolution: #{ $ratio * 96 }dpi),
    only screen and (                min-resolution: #{ $ratio }dppx) {
      @content;
    }
  }
}

// ---------------------------------------------------------------------------
// Scaffold helper functions

@function strip-units($number) {
  @return $number / ($number * 0 + 1);
}

@function px-to-em( $px, $base: 16px ) {
  @return strip-units($px) / strip-units($base) * 1em;
}

// ---------------------------------------------------------------------------
// Scaffold Mixins

// Rem mixin with pixel fallback
@mixin rem($property, $px-values) {
  // Convert the baseline into rems
  $baseline-rem: $font-base-size-px / 1rem;

  // Print the first line in pixel values
  #{$property}: $px-values;

  // If there is only one (numeric) value, return the property/value line for it.
  @if type-of($px-values) == "number" {
    #{$property}: $px-values / $baseline-rem;
  } @else {
    // Create an empty list that we can dump values into
    $rem-values: unquote("");

    @each $value in $px-values {
      // If the value is zero, return 0
      @if $value == 0 {
        $rem-values: append($rem-values, $value); }
      @else {
        $rem-values: append($rem-values, $value / $baseline-rem);
      }
    }

    // Return the property and its list of converted values
    #{$property}: $rem-values;
  }
}

// Font-size wrapper using our rem converter mixin
@mixin font-size($px-value) {
  @include rem(font-size, $px-value);
}

@mixin td-span( $columns, $base, $gutter: true ) {
  @if $gutter {
    width: span( $columns of $base ) + gutter( $base ) * 2;
    padding: {
      left: gutter( $base );
      right: gutter( $base );
    }
  } @else {
    width: span( $columns of $base );
  }
}

// Custom mixin doing a block of code in each layout breakpoint
@mixin each-layout {
  @each $layout in $layouts {
    @include susy-breakpoint($layout...) {
      @content;
    }
  }
}

// Keyframe animation
@mixin animation($animate...) {
  $max: length($animate);
  $animations: ();

  @for $i from 1 through $max {
    $animations: append($animations, nth($animate, $i), comma);
  }

  -webkit-animation: $animations;
  -moz-animation:    $animations;
  -o-animation:      $animations;
  animation:         $animations;
}

@mixin keyframes($animationName) {
  @-webkit-keyframes #{$animationName} {
    @content;
  }
  @-moz-keyframes #{$animationName} {
    @content;
  }
  @-o-keyframes #{$animationName} {
    @content;
  }
  @keyframes #{$animationName} {
    @content;
  }
}

// ---------------------------------------------------------------------------
// Shapes

// When pointing up or down:
// $size is the arrow's height, the width will be twice that
@mixin css-arrow( $direction, $size, $color ) {
  width: 0;
  height: 0;

  // Vertical
  @if $direction == up or $direction == down {
    border-left: $size solid transparent;
    border-right: $size solid transparent;
  }

  // Horizontal
  @if $direction == right or $direction == left {
    border-top: $size solid transparent;
    border-bottom: $size solid transparent;
  }

  // Individual
  @if $direction == up {
    border-bottom: $size solid $color;
  } @else if $direction == down {
    border-top: $size solid $color;
  } @else if $direction == right {
    border-left: $size solid $color;
  } @else if $direction == left {
    border-right: $size solid $color;
  }
}
