html { font-size: $font-base-size; }

body {
  margin: 0 auto;
  /**
 * Remove default margin. and scrollbar
 */
  overflow: -moz-scrollbars-none; 
  -ms-overflow-style: none; 
  // Setting default text behavior
  @include use-type( text-default, true );
  // Manual use of ems
  font-size: px-to-em($font-size-small, $font-base-size-px);
  @include susy-breakpoint($layout-l...) { font-size: px-to-em($font-size-large, $font-base-size-px); }

  // Show layout labels when in development mode
  @if $development == true {
    &::before {
      @include border-bottom-right-radius(10px);
      background: rgba(#000, .2);
      content: 'layout-xs (implicit)';
      display: block;
      padding: 10px;
      position: absolute;
        top: 0;
        left: 0;
      text-shadow: 1px 1px 0 rgba(#fff, 0.75);
      @include susy-breakpoint($layout-s...) {   content: "layout-s"; }
      @include susy-breakpoint($layout-m...) {   content: "layout-m"; }
      @include susy-breakpoint($layout-l...) {   content: "layout-l"; }
      @include susy-breakpoint($layout-xl...) {  content: "layout-xl (with IE fallback)"; }
      @include susy-breakpoint($layout-xxl...) { content: "layout-xxl"; }
    }
  }
}

/* Alin */
body::-webkit-scrollbar {
  width: 0 !important;
}
