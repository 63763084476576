
// ==========================================================================
// Bullets

%bullets {
  cursor: default;
  display: inline-block;
  padding: 0;
  text-align: center;
}

%bullet-item {
  @extend %border-circle;
  background-color: $bullets-background-color;
  border: $bullets-border-width solid $bullets-border-color;
  cursor: pointer;
  display: inline-block;
  height: $bullets-size;
  position: relative;
  width: $bullets-size;

  &:after {
    $offset: ($bullets-size - $bullets-dot-size) / 2;
    @extend %border-circle;
    background-color: $bullets-selected-color;
    content: "";
    display: none;
    position: absolute;
      bottom: $offset;
      left: $offset;
      right: $offset;
      top: $offset;
  }

  &.selected {
    border-color: $bullets-selected-color;

    &:after { display: block; }
  }
}


// Variant 1
.bullets {
  @extend %bullets;

  a { @extend %bullet-item; }
}

// ==========================================================================
// Thumbnail bullets

%thumbnail-bullets { @extend %bullets; }

%thumbnail-bullet-item {
  @extend %bullet-item;

  @include susy-breakpoint( $layout-xl... ) {
    height: auto;
    line-height: 0;
    width: auto;
    @include border-radius(0);

    &.selected:after { display: none; }
  }
}

%thumbnail-bullet-image {
  display: none;

  @include susy-breakpoint( $layout-xl... ) { display: inline-block; }
}

.thumbnail-bullets {
  @extend %thumbnail-bullets;

  a { @extend %thumbnail-bullet-item; }

  img { @extend %thumbnail-bullet-image; }
}
