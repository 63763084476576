$message-button-size: 20px !default;

@mixin message-theme( $background-color, $button-color, $text-color ) {
  background-color: $background-color;
  color: $text-color;

  .global-message__close {
    background-color: $button-color;
    border-color: $button-color;
    color: $background-color;

    &:hover {
      background-color: $background-color;
      color: $button-color;
    }
  }
}

.global-message {
  @extend .global-message--primary !optional;
  border-bottom: 1px solid $header-border-color;
  max-height: 0;
  opacity: 0;
  overflow: hidden; // Avoid message content from blocking page interaction
  width: 100%;
  // Animate out
  @include transition( opacity 300ms ease, max-height 200ms ease 300ms );

  &:first-child { border-top: none; }

  &.is-active {
    opacity: 1;
    max-height: 150px;
    // Animate in (no animation)
    @include transition(none);
    @include susy-breakpoint( $layout-l... ) { max-height: 80px; }
    @include susy-breakpoint( $layout-xl... ) { max-height: 60px; }
  }

  .header & {
    @include susy-breakpoint( $layout-m-down... ) {
      position: absolute;
        top: $header-height-mobile;
    }
  }

  /**
   * Rendering theme colors and selectors
   */
  @each $name, $settings in $message-themes {
    $background-color : map-get( $settings, background-color );
    $button-color     : map-get( $settings, button-color );
    $text-color       : map-get( $settings, text-color );

    &--#{ $name } {
      @extend .global-message;
      @include message-theme($background-color, $button-color, $text-color );
    }
  }

  &__content {
    @extend %container;
    padding: {
      bottom: 10px;
      top: 8px;
    }
    position: relative;
  }

  &__text {
    text-align: center;
    @include rem( padding-right, $message-button-size + 10px );

    p { display: inline; }
  }

  &__counter {
    font-weight: bold;
    padding-right: $message-button-size + 10px;
    text-align: center;
  }

  &__close {
    @extend %close-button; // found in core/_templates.scss
    height: $message-button-size;
    position: absolute;
      margin-top: -($message-button-size / 2);
      top: 50%;
    width: $message-button-size;
    @include rem( right, $grid-padding / 2 );

    @include susy-breakpoint( $layout-l... ) { @include rem( right, $grid-padding ); }

    &:after {
      font-size: $message-button-size;
      line-height: $message-button-size - 2; // subtracting border
    }
  }
}

.global-message--primary {
  a, p, p strong { color:$white; }
}

.global-message--quinary,
.global-message--senary {
  a, p, p strong { color:$color-text; }
}

