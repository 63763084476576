.social {
  @extend %clearfix;

  @at-root {
    %social-item {
      display: inline-block;
      height: 30px;
      overflow: hidden;
      width: 120px;
    }
  }

  &__item {
    &--facebook { @extend %social-item; }
    &--twitter { @extend %social-item; }
    &--gplus { @extend %social-item; }
  }
}
