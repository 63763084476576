// ------------------------------------
// Permission bar
// ------------------------------------

@include keyframes(popup) {
  0%   { @include transform(translateY(0px)) }
  40%  { @include transform(translateY( -($permission-bar-protrusion + 10) )) }
  60%  { @include transform(translateY( -($permission-bar-protrusion - 4) )) }
  80%  { @include transform(translateY( -($permission-bar-protrusion + 3) )) }
  100% { @include transform(translateY( -($permission-bar-protrusion) )) }
}

@include keyframes(teaser) {
  0%   { @include transform(translateY( -($permission-bar-protrusion) )) }
  40%  { @include transform(translateY( -($permission-bar-protrusion + 30) )) }
  60%  { @include transform(translateY( -($permission-bar-protrusion + 30) )) }
  70%  { @include transform(translateY( -($permission-bar-protrusion - 4) )) }
  85%  { @include transform(translateY( -($permission-bar-protrusion + 3) )) }
  100% { @include transform(translateY( -($permission-bar-protrusion) )) }
}

.permission-bar {
  background-color: $parmission-bar-background-color;
  display: none;
  height: $permission-bar-height;
  overflow: hidden;
  position: fixed;
    bottom: -$permission-bar-height;
    right: $permission-bar-offset;
    z-index: 9; // just beneath cookiebar
  text-decoration: none;
  width: $permission-bar-width;

  @include box-sizing(border-box);
  @include font-size(16px);
  @include rem(padding, $permission-bar-protrusion 20px 20px 20px);
  @include transition(bottom 0.3s ease-out);
  @include animation(popup 1s 6s forwards, teaser 2s 30s);

  @include susy-breakpoint($layout-xl...) { display: block; }

  &:hover {
    bottom: -($permission-bar-height - 5px);
    color: $white;
  }

  &.is-active { bottom: -($permission-bar-protrusion); }

  // Adding room for polyfill labels
  .lt-ie10 & {
    $permission-bar-polyfill-height: $permission-bar-height + 2 * 22px;
    bottom: 0;
    padding-bottom: 0;
    height: $permission-bar-protrusion;

    &:hover { height: $permission-bar-protrusion + 5; }

    &.is-active {
      height: $permission-bar-polyfill-height;
      @include rem(padding-bottom, 20px);
    }
  }

  &__headline {
    color: $parmission-bar-header-color;
    cursor: pointer;
    height: $permission-bar-protrusion;
    font-weight: 300;
    margin: 0;
    position: absolute;
      left: 0;
      top: 0;
    text-align: center;
    text-decoration: none;
    width: 100%;
    @include box-sizing(border-box);
    @include font-size(34px);
    @include rem(padding, 12px 0 0);

    &:hover {
      color: $parmission-bar-header-color;
      text-decoration: none;
    }
  }

  &__text {
    color: $parmission-bar-text-color;
    font-family: $font;
    margin-top: 0;
    text-align: center;
    @include font-size(16px);

    // Styling rich-text output
    p:first-child { margin-top: 0; }
    a { color: $parmission-bar-link-color; }
    img {
      // Centering image and removing bottom gap
      display: block;
      margin: auto;
    }
  }

  &__button {
    @extend .button--small;
    width: 100%;
    @include rem(margin-top, 10px);
  }

  &__label { @extend %placeholder-label; }

  &__input {
    @include font-size(14px);
    @include rem(margin, 0 0 10px);
    @include rem(height, 39px); // same as button small
  }

  &__close {
    @extend %close-button; // found in core/_templates.scss
    position: absolute;
      right: 4px;
      top: -($close-button-size);
    @include transition(top 0.3s 0.5s ease-out);

    .is-active & { top: 4px; }
  }

}
