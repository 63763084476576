
$form-field-height: 58px !default;
$form-field-height-small: 28px !default;
$form-field-padding: 10px 10px !default;
$form-field-padding-small: 4px 8px !default;

%form-element-margin { @include rem( margin, 10px 0 20px ); }

%form-text {
  @extend %form-element-margin;
  @extend %border-box;
  border: 1px solid $form-border-color;
  display: block;
  height: $form-field-height-small;
  line-height: 1.3;
  width: 100%;
  @include box-sizing( border-box );
  @include font-size(18px);
  @include rem( padding, $form-field-padding-small );
  @include use-type( lead );

  @include susy-breakpoint( $layout-l... ) {
    height: $form-field-height;
    @include font-size(16px);
    @include rem( padding, $form-field-padding );
  }
}

%placeholder-label {
  display: none;

  .lt-ie10 & { display: block; }
}

%form-check-label {
  display: block;
  line-height: 24px;
  min-height: 24px;
  @include rem( margin, 5px 0 );
  @include rem( padding, 0 0 0 30px );
}

%form-element-container {
  display: block;
  padding-bottom: $form-field-height-small;
  position: relative;
  @include rem( margin, 10px 0 20px );

  @include susy-breakpoint( $layout-l... ) { padding-bottom: $form-field-height; }
}

// Input - text
%form-text-element {
  @extend %form-text;
  background-color: $form-field-background;

  &:focus,
  &.focus { background-color: $form-field-background-focus; }
}

label { @include rem( margin-top, 10px ); }

[type=tel],
[type=email],
[type=number],
[type=text],
[type=search] {
  @extend %form-text-element;

  &::-ms-clear {
    // display: none not used intentionally
    //   for details see: http://stackoverflow.com/questions/14007655/remove-ie10s-clear-field-x-button-on-certain-inputs#answer-14739092
    width : 0;
    height: 0;
  }
}

[type=search] { @include box-sizing(border-box); }

textarea {
  @extend %form-text-element;
  height: auto;
}

// Input - select
select {
  @extend %no-rounding;
  @extend %form-text;
  background: $form-field-background url('../images/../images/forms/select-arrow.png') right 10px center no-repeat;
  height: $form-field-height-small;
  line-height: 22px;
  padding: 0 0 0 nth( $form-field-padding-small, 2 );
  @include appearance(none);

  @include at-retina {
    background-image: url('../images/forms@2x/select-arrow.png');
    background-size: image-width('forms@2x/select-arrow.png') / 2 auto;
  }

  @include susy-breakpoint( $layout-l... ) {
    height: $form-field-height;
    padding: 0 0 0 nth( $form-field-padding, 2 );
  }

  &::-ms-expand { display: none; }

  @at-root {
    @-moz-document url-prefix() {
      select {
        padding-top: 15px;

        // removing arrow in FF
        //   for details see: http://stackoverflow.com/questions/6787667/what-is-the-correct-moz-appearance-value-to-hide-dropdown-arrow-of-a-select#answer-18327666
        text-indent: 0.01px;
        text-overflow: '';
        @include appearance(none);
      }
    }
  }

  &.is-required { border-color: red; }

  &:focus,
  &.focus { background-color: $form-field-background-focus; }
}

// Input - radio
$form-radio-icon: '../images/forms/radio-btn-normal.png' !default;
$form-radio-icon-checked: '../images/forms/radio-btn-checked.png' !default;
$form-radio-icon-retina: '../images/forms/radio-btn-normal.png' !default;
$form-radio-icon-retina-checked: '../images/forms/radio-btn-checked.png' !default;
$form-radio-darken: 20% !default;
$form-radio-lighten: 3% !default;

[type=radio] {
  @at-root {
    // we cannot use "display: block" or "visibility: hidden"
    // because tab will stop working on radio button
    // below is work around for this

    // move radio button outside bounding box
    .radio-container & {
      position: absolute;
        left: -50px;
        top: 0;
    }

    // and use "overflow: hidden"
    .radio-container {
      overflow: hidden;
      position: relative;
    }
  }

  & + label {
    @extend %form-check-label;
    background: url( $form-radio-icon ) left center no-repeat;

    @include at-retina {
      background-image: url( $form-radio-icon-retina );
      background-size: image-width( $form-radio-icon-retina ) / 2 auto;
    }
  }

  & + label:hover,
  &:focus + label,
  &:checked + label {
    color: darken(
      $color-text,
      $form-radio-darken
    );
  }

  &:checked + label {
    background-image: url( $form-radio-icon-checked );

    @include at-retina { background-image: url( $form-radio-icon-retina-checked ); }
  }

  &:disabled + label {
    color: lighten(
      $color-text,
      $form-radio-lighten
    );
  }
}

// Input - checkbox
$form-checkbox-icon: '../images/forms/checkbox-btn-normal.png' !default;
$form-checkbox-icon-checked: '../images/forms/checkbox-btn-checked.png' !default;
$form-checkbox-icon-retina: '../images/forms/checkbox-btn-normal.png' !default;
$form-checkbox-icon-retina-checked: '../images/forms/checkbox-btn-checked.png' !default;
$form-checkbox-darken: 20% !default;
$form-checkbox-lighten: 10% !default;

[type=checkbox] {
  @at-root {
    // we cannot use "display: block" or "visibility: hidden"
    // because tab will stop working on radio button
    // below is work around for this

    // move radio button outside bounding box
    .checkbox-container & {
      position: absolute;
        left: -50px;
        top: 0;
    }

    // and use "overflow: hidden"
    .checkbox-container {
      overflow: hidden;
      position: relative;
    }
  }

  & + label {
    @extend %form-check-label;
    background: url( $form-checkbox-icon ) left center no-repeat;

    @include at-retina {
      background-image: url( $form-checkbox-icon-retina );
      background-size: image-width( $form-checkbox-icon-retina ) / 2 auto;
    }

    &:hover {
      color: darken(
        $color-text,
        $form-checkbox-darken
      );
    }
  }

  &:focus + label,
  &:checked + label {
    color: darken(
      $color-text,
      $form-checkbox-darken
    );
  }

  &:checked + label {
    background-image: url( $form-checkbox-icon-checked );

    @include at-retina {
      background-image: url( $form-checkbox-icon-retina-checked );
    }
  }

  &:disabled + label {
    color: lighten(
      $color-text,
      $form-checkbox-lighten
    );
  }
}

// Number
[type=number] {
  @extend %form-text-element;
  // hides the spin-button for firefox
  -moz-appearance: textfield;

  // Remove webkit spin buttons
  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
  }
}

$number-button-size: $form-field-height !default;
$number-button-size-small: $form-field-height-small !default;

.number-container {
  @extend %form-element-container;
  padding: {
    left: $number-button-size-small + 10px;
    right: $number-button-size-small + 10px;
  }

  @include susy-breakpoint( $layout-l... ) {
    padding: {
      left: $number-button-size + 10px;
      right: $number-button-size + 10px;
    }
  }
}

.number__input {
  .number-container & {
    float: left;
    margin: 0;
    text-align: center;
  }
}

%number-button {
  @extend %border-circle;
  background: $brand-color-quinary;
  border: none;
  height: $number-button-size-small;
  line-height: $number-button-size-small;
  outline: 0;
  padding: 0;
  position: absolute;
    top: 0;
  width: $number-button-size-small;

  &:after {
    position: absolute;
      left: 0;
      top: 0;
    text-indent: 0;

    @include susy-breakpoint( $layout-l... ) { font-size: 20px; }

    .number-container & {
      height: 100%;
      line-height: inherit;
      width: 100%;
    }
  }

  @include susy-breakpoint( $layout-l... ) {
    height: $number-button-size;
    line-height: $number-button-size;
    width: $number-button-size;
  }
}

.number__increase {
  @extend %number-button;
  @extend %icon-only;
  right: 0;

  &:after { @extend %icon--plus; }
}

.number__decrease {
  @extend %number-button;
  @extend %icon-only;
  left: 0;

  &:after { @extend %icon--minus; }
}

// Range styles inspired by
// http://brennaobrien.com/blog/2014/05/style-input-type-range-in-every-browser.html
$range-width: 200px !default;
$range-thumb-size: 16px !default;
$range-thumb-color: #1CE600 !default;
$range-track-size: 5px !default;
$range-track-color: #ddd !default;
$range-track-color-focus: #ccc !default;
$range-track-color-lower: $range-track-color !default;
$range-track-color-lower-focus: $range-track-color-focus !default;

[type=range] {
  /*removes default webkit styles*/
  -webkit-appearance: none;

  /*fix for FF unable to apply focus style bug */
  border: 1px solid white;
  padding: 0;

  /*required for proper track sizing in FF*/
  width: $range-width;

  // Little hack for same offset x-browser
  height: $range-thumb-size;
  @include susy-breakpoint( $layout-m-down... ) { width: 100%; }

  &::-webkit-slider-runnable-track {
    height: $range-track-size;
    background: $range-track-color;
    border: none;
    border-radius: $range-track-size;
    width: $range-width;
    @include susy-breakpoint( $layout-m-down... ) { width: 100%; }
  }
  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    border: none;
    height: $range-thumb-size;
    width: $range-thumb-size;
    border-radius: 50%;
    background: $range-thumb-color;
    margin-top: -( $range-thumb-size - $range-track-size )/2;
  }
  &:focus {
    outline: none;
  }
  &:focus::-webkit-slider-runnable-track {
    background: $range-track-color-focus;
  }
  &::-moz-range-track {
    height: $range-track-size;
    background: $range-track-color;
    border: none;
    border-radius: $range-track-size;
    width: $range-width;
    @include susy-breakpoint( $layout-m-down... ) { width: 100%; }
  }
  &::-moz-range-thumb {
    border: none;
    height: $range-thumb-size;
    width: $range-thumb-size;
    border-radius: 50%;
    background: $range-thumb-color;
  }

  /*hide the outline behind the border*/
  &:-moz-focusring{
    outline: 1px solid white;
    outline-offset: -1px;
  }
  &::-ms-track {
    height: $range-track-size;
    background: $range-track-color;
    border: none;
    border-radius: $range-track-size;
    /*remove default tick marks*/
    color: transparent;
    width: $range-width;
    @include susy-breakpoint( $layout-m-down... ) { width: 100%; }
  }
  &::-ms-fill-lower {
    outline: none;
    background: $range-track-color-lower;
    border-radius: $range-track-size 0 0 $range-track-size;
  }
  &::-ms-thumb {
    border: none;
    height: $range-thumb-size;
    width: $range-thumb-size;
    border-radius: 50%;
    background: $range-thumb-color;
  }
  &:focus::-ms-track {
    background: $range-track-color-focus;
  }
  &:focus::-ms-fill-lower {
    background: $range-track-color-lower-focus;
  }
}
