
@mixin button( $background-color, $background-color-active, $border-color ) {
  background-color: $background-color;
  border-color: $border-color;

  &:hover,
  &:active,
  &.hover,
  &.pressed { background-color: $background-color-active; }

  &:disabled,
  &.disabled { background-color: $background-color; }
}

%button {
  border: 0 solid transparent;
  border-bottom-width: 0.3em;
  border-radius: 0;
  color: $color-text-inverse;
  cursor: pointer;
  display: inline-block;
  font-weight: 500;
  padding: 0.8em 3em 0.6em;
  text-align: center;
  text-transform: uppercase;
  @include user-select(none);
  @include use-type( button );
  @include button(
    $background-color:        $button-primary-color,
    $background-color-active: lighten( $button-primary-color, 5% ),
    $border-color:            darken( $button-primary-color, 5% )
  );

  @include susy-breakpoint( $layout-m... ) { white-space: nowrap; }

  &:hover,
  &.hover,
  &:active,
  &:focus,
  &.pressed {
    color: $color-text-inverse;
    text-decoration: none;
  }

  &:active,
  &:focus,
  &.pressed {
    border-bottom-width: 0;
    border-top-width: 0.3em;
    padding: {
      top: 0.6em;
      bottom: 0.8em;
    }
  }

  &:disabled,
  &.disabled {
    border-bottom-width: 0.3em;
    border-top-width: 0;
    opacity: 0.5;
    padding: {
      top: 0.8em;
      bottom: 0.6em;
    }
  }

  &--small {
    @extend %button;
    @include use-type( button-small );
  }
}

.button { @extend %button; }

.button--small { @extend %button--small; }

.button--primary {
  @extend %button;
  @include button(
    $background-color:        $button-primary-color,
    $background-color-active: lighten( $button-primary-color, 5% ),
    $border-color:            darken( $button-primary-color, 5% )
  );
}

.button--secondary {
  @extend %button;
  @include button(
    $background-color:        $button-secondary-color,
    $background-color-active: lighten( $button-secondary-color, 5% ),
    $border-color:            darken( $button-secondary-color, 5% )
  );
}

.button--primary--small {
  @extend %button--small;
  @extend .button--primary;
}

.button--secondary--small {
  @extend %button--small;
  @extend .button--secondary;
}

.button--tertiary {
  @extend %button;
  @include button(
    $background-color:        $button-tertiary-color,
    $background-color-active: lighten( $button-tertiary-color, 5% ),
    $border-color:            darken( $button-tertiary-color, 7% )
  );
}

.button--tertiary--small {
  @extend %button--small;
  @extend .button--tertiary;
}

.button--buy {
  $buy-icon-size: 32px;
  @extend %button;
  width: 100%;
  padding: 0.5em 3em 0.3em;
  font-family: $font;
  font-weight: 600;
  text-transform: uppercase;
  // @extend .icon--basket;
  // padding-right: 3.5em; // adding a bit on the right

  &:before {
    display: inline-block;
    font-size: $buy-icon-size; // can't use ems to scope pseudo element font size on IE
    margin-right: px-to-em(12px, $buy-icon-size);
    margin-top: px-to-em(-12px, $buy-icon-size);
    position: relative;
      top: px-to-em(8px, $buy-icon-size);
  }
}

