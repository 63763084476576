
.blog-topimage {
  width: 100%;
  background-color: $black;
  height: 30vw;
  overflow: hidden;
  position: relative;
  min-height: 340px;
  max-height: 500px;
}

.blog-topimage__image {
  opacity: 0.4;
  position: absolute;
    left: 50%;
  vertical-align: top;
  height: 100%;
  transform: translate(-50%, 0);

  @include susy-breakpoint( $layout-xl... ) {
    transform: translate(0, -50%);
    top: 50%;
    left: auto;
    width: 100%;
    height: auto;
  }
}

.blog-nav {
  margin-top: 44px;
  padding: 10px 0 2px;

  &__title {
    display: inline-block;
    float: left;
    @include rem(padding, 5px 10px 5px 0);
  }

  &__share {
    @extend %clearfix;
    @include rem(margin-bottom, 8px);
    @include susy-breakpoint( $layout-xl... ) { float: right; }
  }

  &__share-tools {
    float: left;
    @include rem(margin-top, 5px);
  }
}

.blog-nav__tags {
  box-sizing: border-box;
  height: auto !important;
  margin-bottom: 30px;
  opacity: 1;
  overflow: hidden;
  text-align: center;
  transition: opacity 0.5s ease-in-out;
  width: 100%;

  &.is-loading {
    height: 0 !important;
    opacity: 0;
  }

  .rsThumb {
    display: inline-block;
    float: none;
  }

  .rsThumbsContainer {
    text-align: center;
  }

  .rsThumbs { width: 100% !important; }

  .rsNavSelected { border: none; }

}

.blog-nav__tags--blue {
  background-color: #394d64;
  height: 56px !important;
  text-align: center;
  width: 100%;
  margin: 0;

  .rsNavItem { border: none; }

  .blog-nav__tags-item {
    color: $white;
    font-size: 16px;
    font-weight: 100;
    opacity: 0.5;
    margin: 0 10px;
    padding: 14px 0 18px;
    position: relative;

    &.is-active { opacity: 1; }

    &.is-active::after {
      content: '';
      position: absolute;
        bottom: 0;
        left: 0;
      width: 100%;
      height: 3px;
      background-color: $white;
    }
  }
}

.blog__header { text-align: center; }

.blog-nav__tags-list { @extend %list-clean; }

.blog-nav__tags-item { display: inline-block; }

.blog-nav__tag {
  padding: 5px 14px;
  background-color: transparent;
  display: inline-block;
  border: 1px solid $gray;
  border-radius: 3px;
  transition: background-color 0.3s ease-in-out;
  width: 100px;

  &:hover { background-color: lighten($gray, 5%); }
}

.blog-post__author-image {
  border-radius: 50%;
  overflow: hidden;
  width: 63px;
  height: 63px;
  margin: 10px auto;
}

.blog-post__top-content {
  -webkit-font-smoothing: antialiased;
  color: $white;
  margin: 0;
  position: absolute;
    left: 50%;
    top: 50%;
  text-align: center;
  transform: translate(-50%, -50%);
  z-index: 1;
}

.blog-post__header {
  color: $white;
  margin-top: 0;
  display: inline-block;

  @include susy-breakpoint( $layout-m... ) { text-align: center; }

  @include susy-breakpoint( $layout-xl... ) { font-size: 55px; }
}

.blog-post__subheader {
  color: $black;
  margin: 0;
  font-size: 24px;
  margin: 0 auto 40px;
  line-height: 1.6;
  max-width: 700px;
}

.blog-post__date {
  font-size: 16px;
  font-style: italic;
  font-family: $font-display;
  text-align: center;
}

.blog-post {
  @extend .section;
  margin-top: 0;
  padding-top: 0;
  border: none;

  &__details {
    font-family: $font-display;
    font-style: italic;
    text-align: center;
  }

  &__comment { min-height: 150px; }
}

.blog-post__body{
  box-sizing: border-box;
  margin: 0 auto;
  max-width: 980px;
  padding: 0 10px;

  // Richtext image
  img {
    display: block;
    max-width: 100%;
  }
}

.blog-item {
  // Layouts
  @include susy-breakpoint( $layout-m... ) {
    padding: 0 20px;
    min-height: px-to-em(190px, $font-size-large);
    @include span(2);
  }

  @include susy-breakpoint( $layout-l... ) { @include span(4); }

  @include susy-breakpoint( $layout-xl... ) {
    min-height: px-to-em(260px, $font-size-large);
    @include span(4);
  }

  &:nth-of-type(3) { clear: left; }

  &__image-container {
    line-height: 0; // Avoid gap below image
    position: relative;
  }

  &__image { width: 100%; }

  &__content {
    padding: 20px;
    // Setting min-height to align bottom
    @include susy-breakpoint( $layout-m... ) { min-height: 130px; }
  }

  &__date {
    color: $black;
    float: right;
    font-family: $font-display;
    font-size: 14px;
    font-style: italic;
    opacity: 0.5;
  }

  &__tags {
    color: $yellow;
    font-family: $font;
    font-size: 14px;
    font-weight: 600;
  }

  &__header { @include use-type(text-xxlarge); }

}

// Large item
.blog-item--large {
  width: 100%;

  @include susy-breakpoint( $layout-l... ) {
    float: left;
    width: 50%;
  }

  .blog-item__content {
    @include susy-breakpoint( $layout-m... ) { min-height: 180px; }
  }

  .blog-item__header {
    @include susy-breakpoint( $layout-l... ) { font-size: 24px; }
  }
}


.blog-comment {
  margin: 0 auto;
  @include susy-breakpoint( $layout-xl... ) { width: span(8 of 10); }
  @include susy-breakpoint( $layout-xxl... ) { width: span(6 of 8); }

  &__item {
    @extend .blog-post__body;
    background-color: $white;
    @include box-sizing(border-box);
    @include rem(margin-bottom, 1px);
  }

  &__text-container { float: left; }

  &__header {
    font-family: $font-display;
    font-style: italic;
    margin-top: 0;
    @include use-type(text-default);
  }

  &__image {
    float: left;
    height: 50px;
    width: 50px;
    @include rem(margin-right, 20px);
  }
}
