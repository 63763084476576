
.checkout {
  &__accept-checkbox-container {
    @extend .checkbox-container;
    display: inline-block;
    float: left;
    margin-bottom: 18px;
    margin-right: 2em;
    @include susy-breakpoint( $layout-xxl... ) {
      margin-top: 10px;
      margin-bottom: 0;
    }
  }

  &__basket-anchor {
    @extend .button--buy;
    @extend .button--secondary--small;
    @include susy-breakpoint( $layout-xl... ) { display: none; }

    // Setting icon size
    &:before { font-size: 25px; }
  }

  &__shippingmethod { display: none; }

  &__submit {
    @extend .button;
    margin: 0;

    @include susy-breakpoint( $layout-xxl... ) { float: right; }

    &--us {
      @extend .checkout__submit;
      position: relative;
      text-indent: 0;
      @include transition( all 200ms ease-in-out );

      &:disabled {
        text-indent: -300px;

        &:before {
          background: transparent url('../images/loader.gif') no-repeat;
          content: '';
          height: 32px;
          position: absolute;
            left: 50%;
            top: 50%;
          width: 32px;
          @include rem( margin, -16px 0 0 -16px );
        }
      }
    }
  }

  &__payment {
    &--is-hidden {
      display: none;
    }
  }

  &__payment-icon { vertical-align: middle; }

  &__phone {
    @extend .icon--phone;
    display: block;
    font-weight: 300;
    @include rem( margin, 10px 0 );
    @include use-type(text-xxlarge);

    &:before {
      color: $brand-color-secondary;
      font-size: 22px;
      margin-right: 12px;
    }
  }

  &__opening { margin: 0; }

  &__gls {
    @extend .section;
    margin-top: 0;
  }

  &__gls-results {
    clear: both;
    @include rem( margin-bottom, 20px );
  }

  &__hidden {
    display: none;

    &.is-active { display: block; }
  }
}

.discount {
  position: relative;

  &__input { @include use-type(text-xxlarge); }

  &__submit {
    @extend .button;
    position: absolute;
      top: 0;
      right: 0;
    @include rem( padding, 14px 40px 10px );

    @include susy-breakpoint( $layout-xl... ) { @include rem( padding, 12px 40px 8px ); }
  }
}

.main--checkout {
  .billing__address-container {
    display: inline-block;
    width: 100%;
  }
  .shippingmethods {
    &__method {
      display: block;
      margin: 0;
      padding: 5px 0;
      line-height: 20px;

      @include susy-breakpoint( $layout-xxl... ) {
        width: 100%;
        float: left;
      }
  
      input[type="radio"] {
        position: relative;
        top: 2px;
      }
    }
  
    &__methods { 
      overflow: auto; 

      @include susy-breakpoint( $layout-xxl... ) {
        display: flex;
        margin: 0 -5px;
      }

      &-container {
        width: 100%;
        box-sizing: border-box;
        
        @include susy-breakpoint( $layout-xxl... ) {
          width: 50%;
          padding: 0 5px;
        }
      }
    }
  }

  .section--highlight  {
    .container { position: relative;}

    .checkbox-container { 
      @include susy-breakpoint( $layout-xl-down... ) {width: 100% ;}
    }
    .checkout__submit {
      @include susy-breakpoint( $layout-xxl... ) {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 20px ;
      }
    }
  }
  &__field-invalid {
    border-color: red;
  }

  &__invalid-text {
    color: red;
    a {
      color: red;
    }
  }
}
