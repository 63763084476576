// Settings, variables and mixins for this particular project.
//
// e.g. $color-alert-background: #f00;

// Turns on grid and breakpoint gauge
$development: false; // true, false

// ==========================================================================
// Typography

// Base font size
$font-base-size: 62.5%; // 10px
$font-base-size-px: (16px / 100) * strip-units($font-base-size); // 100% of the font-size in default font size

// For Compass's Vertical Rhythm and rem conversion etc.
// when used with Susy
$base-font-size: $font-base-size-px;
$rem-with-px-fallback: false;

// Typograhpy (create a sane font-stack)
$font: "Open Sans", sans-serif;
$font-display: "Times", "Times New Roman", serif;

$font-size-small: 12px; // base font size on small screens
$font-size-large: 14px; // base font size on large screens ($layout-l)

// ==========================================================================
// Colors

// Brand colors
// --------------------------

$brand-color-primary:    #272324; // light black
$brand-color-secondary:  #af9b56; // gold
$brand-color-tertiary:   #384d64; // dark blue/turquoise
$brand-color-quaternary: #6d9343; // green
$brand-color-quinary:    #f7f7f7; // light grey
$brand-color-senary:     #fcdf7a; // matte yellow

$white : #fff;
$black : #000;
$yellow: #B69D4B;
$gray  : #d6d6d6;

$brand-roundness: 0px;

// Text
// --------------------------
$color-text             : #666;
$color-text-inverse     : $white;

$color-display          : $brand-color-primary;
$color-light            : #999;

$color-selection        : #fd0;
$color-selection-bg     : #1BB2E0;

$color-border           : #dbdbdb;

$color-link             : $black;
$color-link-hover       : $color-link;
$color-link-active      : $brand-color-primary;


// ==========================================================================
// Modules

$table-border-color: #d8d7d7;
$table-row-background: #f5f5f5;

// ===================
// Header

$header-background-primary    : #231f20;
$header-background-secondary  : #373334;
$header-background-tertiary   : #2e292a;
$header-display               : #b09c57;
$header-border-color          : #404040;
$header-button-width-s        : 20%;
$header-button-width-m        : 16%;
$header-search-background     : #1e1e1e;
$header-height-mobile         : 60px;
$header-height-tablet         : 50px;
$header-height-desktop        : 50px;
$header-nav-height            : 65px;
$header-basket-background     : #394D64;
$header-usp-bullet            : #AE9C57;

// ===================
// Navigation

$nav-background           : $header-background-secondary;
$nav-link-color           : $color-text-inverse;
$nav-link-color-secondary : $color-light;
$nav-border-color         : $header-border-color;

// ===================
// Search

$search-field-height-mobile       : 70px;

$search-results-header-background : #f1f1f1;
$search-results-border            : $search-results-header-background;
$search-results-hover-background  : #fafafa;

// ===================
// Footer

$footer-background-primary    : $header-background-primary;
$footer-background-secondary  : $header-background-secondary;
$footer-border-color          : $header-border-color;
$footer-discreet              : #9f9e9e;
$footer-link-color            : $footer-discreet;
$footer-icon-color            : #4b4b4b;

// ===================
// Forms

$form-field-height            : 50px;
$form-field-height-small      : $form-field-height;
$form-field-padding           : 10px 18px;
$form-field-padding-small     : $form-field-padding;
$form-border-color            : #ccc;
$form-field-background        : $white;
$form-field-background-focus  : $brand-color-quinary;

$form-radio-icon: '../images/forms/radio-btn-normal.png';
$form-radio-icon-checked: '../images/forms/radio-btn-checked.png';
$form-radio-icon-retina: '../images/forms/radio-btn-normal.png';
$form-radio-icon-retina-checked: '../images/forms/radio-btn-checked.png';
$form-radio-darken: 50%; // on hover/checked
$form-radio-lighten: 45%; // on disabled

$form-checkbox-icon: '../images/forms/checkbox-btn-normal.png';
$form-checkbox-icon-checked: '../images/forms/checkbox-btn-checked.png';
$form-checkbox-icon-retina: '../images/forms/checkbox-btn-normal.png';
$form-checkbox-icon-retina-checked: '../images/forms/checkbox-btn-checked.png';
$form-checkbox-darken: $form-radio-darken; // on hover/checked
$form-checkbox-lighten: $form-radio-lighten; // on disabled

// Range input
$range-width: 180px;
$range-thumb-size: 32px;
$range-thumb-color: $brand-color-tertiary;
$range-track-size: 10px;
$range-track-color: $brand-color-quinary;
$range-track-color-focus: darken( $brand-color-quinary, 7% );
$range-track-color-lower: $range-track-color;
$range-track-color-lower-focus: $range-track-color-focus;

// ===================
// Basket

$basket-number-field-height-small   : 28px;
$basket-number-button-size-small    : $basket-number-field-height-small;

// ===================
// Message

$message-information-color  : #ffdf00;
$message-feedback-color     : $brand-color-quaternary;
$message-error-color        : #d62c2d;
$message-error-background   : #F7AFAF;

// ===================
// Close button

$close-button-size: 29px;
$close-button-background-color: #393937;
$close-button-border-color: #000;
$close-button-color: $white;

// ===================
// Message

$message-button-size: 24px;

// Used in base/_icons
$message-themes : (
  primary : (
    background-color : $brand-color-primary,
    button-color : $color-light,
    text-color : $color-text-inverse
  ),
  secondary : (
    background-color : $brand-color-secondary,
    button-color : darken($brand-color-secondary, 18%),
    text-color : $color-text-inverse
  ),
  tertiary : (
    background-color : $brand-color-tertiary,
    button-color : lighten($brand-color-tertiary, 28%),
    text-color : $color-text-inverse
  ),
  quaternary : (
    background-color : $brand-color-quaternary,
    button-color : lighten($brand-color-quaternary, 20%),
    text-color : $color-text-inverse
  ),
  quinary : (
    background-color : $brand-color-quinary,
    button-color : $brand-color-primary,
    text-color : $color-text
  ),
  senary : (
    background-color : $brand-color-senary,
    button-color : darken($brand-color-senary, 32%),
    text-color : $color-text
  ),
  xmas : (
    background-color : #ea4e49,
    button-color : darken(#ea4e49, 18%),
    text-color : $color-text-inverse
  ),
  xmas-alt : (
    background-color : #310041,
    button-color : #ba5bd9,
    text-color : $color-text-inverse
  )
);

// ===================
// Cookie bar

$cookie-bar-background: rgba(#333, 0.8);
$cookie-bar-background-ie: #333;
$cookie-bar-color: #ccc;
$cookie-bar-link-color: $white;

// ===================
// Permission bar

$parmission-bar-background-color: #1b1b19;
$parmission-bar-text-color: $color-text-inverse;
$parmission-bar-link-color: $brand-color-secondary;
$parmission-bar-header-color: $brand-color-secondary;
$permission-bar-height: 540px;
$permission-bar-width: 390px;
$permission-bar-offset: 20px; // offset from right
$permission-bar-protrusion: 70px; // how much the bar sticks up when inactive (also header height)

// ===================
// Buttons

$button-primary-color   : $brand-color-quaternary;
$button-secondary-color : $brand-color-tertiary;
$button-tertiary-color : #353535;

// ===================
// Arrow

$arrow-width              : 50px;
$arrow-height             : 50px;

// ===================
// Bullets

$bullets-size             : 12px;
$bullets-background-color : $white;
$bullets-border-color     : #cdcdcd;
$bullets-border-width     : 2px;
$bullets-selected-color   : $brand-color-tertiary;
$bullets-dot-size         : 6px;


// ==========================================================================
// Icons

// General icons
// This variable is responsible for setting all icons.
// Used in base/_icons
$icons : (
  plus                : ( char : "\e614", size : 14px, color : false ),
  minus               : ( char : "\e611", size : 14px, color : false ),
  arrow-circle-right  : ( char : "\e603", size : 48px, color : false ),
  arrow-circle-left   : ( char : "\e602", size : 48px, color : false ),
  tick                : ( char : "\e618", size : 18px, color : $message-feedback-color ),
  error               : ( char : "\e607", size : 21px, color : $message-error-color ),
  basket              : ( char : "\e900", size : 20px, color : false ),
  search              : ( char : "\e615", size : 14px, color : $header-usp-bullet ),
  phone               : ( char : "\e613", size : 20px, color : false ),
  arrow-right         : ( char : "\e605", size : 25px, color : false ),
  arrow-down          : ( char : "\e604", size : 18px, color : false ),
  menu-burger         : ( char : "\e60e", size : 33px, color : false ),
  delete              : ( char : "\e61a", size : 25px, color : false ),
  leaves              : ( char : "\e61c", size : 50px, color : false ),
  stars               : ( char : "\e61d", size : 50px, color : false )
);

// Social icons
$icons-social : (
  facebook            : ( char : "\e608", size : 30px, color : #3c5a98 ),
  instagram           : ( char : "\e60c", size : 30px, color : #33648d )
);

// Feature icons
$icons-features : (
  feature-40c         : ( char : "\e600", size : 18px, color : false ),
  feature-104f        : ( char : "\e601", size : 18px, color : false ),
  feature-dishwasher  : ( char : "\e606", size : 18px, color : false ),
  feature-freezer     : ( char : "\e609", size : 18px, color : false ),
  feature-halogen     : ( char : "\e60a", size : 18px, color : false ),
  feature-induction   : ( char : "\e60b", size : 18px, color : false ),
  feature-gas         : ( char : "\e61b", size : 18px, color : false ),
  feature-ceramic     : ( char : "\e60d", size : 18px, color : false ),
  feature-microwave   : ( char : "\e60f", size : 18px, color : false ),
  feature-ovenproof   : ( char : "\e612", size : 18px, color : false ),
  feature-radiantrin  : ( char : "\e617", size : 18px, color : false ),
  feature-solidplate  : ( char : "\e619", size : 18px, color : false )
);

// Merging icon maps
$icons : map-merge( $icons, $icons-social );
$icons : map-merge( $icons, $icons-features );

// ==========================================================================
// Susy grid settings

// Breakpoints (minimum widths!)
$bp-xs-s:   320px;
$bp-s-m:    400px;
$bp-m-l:    481px;
$bp-l-xl:   769px;
$bp-l-xl-up:936px;
$bp-xl-xxl: 1176px; // 12 columns of 98px
// Special breakpoints
$bp-m-down: (max-width px-to-em($bp-m-l - 1));
$bp-l-down: (max-width px-to-em($bp-l-xl - 1));
$bp-xl-down: (max-width px-to-em($bp-xl-xxl - 1));
$bp-xl-up: (min-width px-to-em($bp-l-xl-up - 1));
$bp-l-xl-only: (min-width px-to-em($bp-m-l)) (max-width px-to-em($bp-l-xl - 1));
$bp-xl-xxl-only: (min-width px-to-em($bp-l-xl)) (max-width px-to-em($bp-xl-xxl - 1));


$susy: (
  columns: 12,
  gutters: 0,
  gutter-position: inside, // split, inside, after, before ...
  container: px-to-em( $bp-xl-xxl, $font-size-large )
  // debug: (
  //   image: show,
  //   color: rgba(blue, 0.5),
  //   output: overlay,
  //   toggle: top right,
  // )
);

// $shorthand: 12 0 fluid float inside;

// Columns
$cols-s:   1;
$cols-m:   4;
$cols-l:   8;
$cols-xl:  12;
$cols-xxl: 12;

// Layouts
@include breakpoint-set('no query fallbacks', true);
$layout-ie-fallback: ".lt-ie9";

$layout-s:          join(( px-to-em($bp-xs-s), $cols-s), $layout-ie-fallback);
$layout-m-down:     $bp-m-down, $cols-m;
$layout-m:          join(( px-to-em($bp-s-m), $cols-m), $layout-ie-fallback);
$layout-l-down:     $bp-l-down, $cols-l;
$layout-l-xl-only:  join(( $bp-l-xl-only, $cols-l), $layout-ie-fallback);
$layout-l:          join(( px-to-em($bp-m-l), $cols-l), $layout-ie-fallback);
$layout-xl-down:    $bp-xl-down, $cols-xl;
$layout-xl-xxl-only:$bp-xl-xxl-only, $cols-xl;
$layout-xl:         join(( px-to-em($bp-l-xl), $cols-xl), $layout-ie-fallback);
$layout-xl-up:      $bp-xl-up, $cols-xl;
$layout-xxl:        px-to-em($bp-xl-xxl), $cols-xxl;

// Height based breakpoints
$breakpoint-hs: max-height 508px;

// Store all layouts for this project
$layouts: $layout-m $layout-l $layout-xl $layout-xxl;

// Custom grid settings - not used by susy
// Gutter width: necessary for putting spacing between sections
$gutter-width: 30px;
$grid-padding: 20px;
$section-padding: 30px; // used in sections with background-color

