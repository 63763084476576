table { width: 100%; }

tr {
  background-color: $table-row-background;
  border-bottom: 1px solid $table-border-color;
}

tr:nth-child(2n),
thead tr { background-color: transparent; }

th {
  text-align: left;
  font-weight: 300;
  @include use-type(header-small-1);
}

th, td { padding: 15px 20px; }
