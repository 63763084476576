blockquote {
  @extend %border-box;
  border-left: 2px solid $color-border;
  font-weight: bold;
  margin: 0;
  width: 50%;

  @include rem( padding, 0 0 0 20px );
  @include use-type( header-large-3 );

  p:before { content: '“'; }

  p:after { content: '”'; }

  cite {
    @include use-type( text-default, true );
    display: block;
  }
}
