@charset "UTF-8";
@font-face {
  font-family: 'icons';
  src: url("../fonts/icons.eot");
  src: url("../fonts/icons.eot?#iefix") format("embedded-opentype"), url("../fonts/icons.woff") format("woff"), url("../fonts/icons.ttf") format("truetype"), url("../fonts/icons.svg#icons") format("svg"); }

@font-face {
  font-family: 'Open Sans';
  src: url("../fonts/opensans-light-webfont.woff2") format("woff2"), url("../fonts/opensans-light-webfont.woff") format("woff");
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url("../fonts/opensans-regular-webfont.woff2") format("woff2"), url("../fonts/opensans-regular-webfont.woff") format("woff");
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url("../fonts/opensans-semibold-webfont.woff2") format("woff2"), url("../fonts/opensans-semibold-webfont.woff") format("woff");
  font-weight: 600;
  font-style: normal; }

.tick-list, .feature-list, .nav__list, .blog-nav__tags-list {
  list-style-type: none;
  margin: 0;
  padding: 0; }

.section, .errors, .image-block, .image-block--left, .image-block--right, .checkout__gls, .blog-post, .social, .blog-nav__share {
  *zoom: 1; }
  .section:before, .errors:before, .image-block:before, .image-block--left:before, .image-block--right:before, .checkout__gls:before, .blog-post:before, .social:before, .blog-nav__share:before, .section:after, .errors:after, .image-block:after, .image-block--left:after, .image-block--right:after, .checkout__gls:after, .blog-post:after, .social:after, .blog-nav__share:after {
    content: " ";
    display: table; }
  .section:after, .errors:after, .image-block:after, .image-block--left:after, .image-block--right:after, .checkout__gls:after, .blog-post:after, .social:after, .blog-nav__share:after {
    clear: both; }

[type=tel],
[type=email],
[type=number],
[type=text],
[type=search], textarea, select, blockquote {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

.splat, .splat--secondary, .splat--tertiary, .splat--large-alt, .splat--large, .splat--bestseller--large, .splat--bit--large, .splat--bestseller, .splat--bit, .splat__border, .number__increase, .number__decrease, .bullets a, .thumbnail-bullets a, .bullets a:after, .thumbnail-bullets a:after {
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  -ms-border-radius: 100%;
  -o-border-radius: 100%;
  border-radius: 100%; }

select {
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  -o-border-radius: 0;
  border-radius: 0; }

.section--dark {
  background-color: #384d64; }

.section--highlight {
  background-color: #f7f7f7; }

.section--campaign {
  background-color: #fcdf7a; }

.global-message__close, .permission-bar__close {
  background-color: #393937;
  border: 1px solid #000;
  color: #fff;
  cursor: pointer;
  height: 29px;
  overflow: hidden;
  position: relative;
  text-align: center;
  text-indent: 99px;
  width: 29px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }
  .global-message__close:hover, .permission-bar__close:hover {
    color: #fff; }
  .global-message__close:after, .permission-bar__close:after {
    content: "\d7";
    display: inline-block;
    font-size: 29px;
    line-height: 25px;
    text-indent: 0;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%; }

.blog-comment__header {
  color: #666;
  line-height: 1.5;
  font-family: "Open Sans", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 300; }
  @media (min-width: 48.0625em) {
    .blog-comment__header {
      font-size: 14px; } }
  .lt-ie9 .blog-comment__header {
    font-size: 14px; }

h4,
.h4, th, .subject, .section__header-subject, .section__text-subject, .image-block__subject, .tick-list__item, .button--small, .permission-bar__button, .button--primary--small, .button--secondary--small, .checkout__basket-anchor, .button--tertiary--small, .button, .checkout__submit, .checkout__submit--us, .discount__submit, .button--primary, .button--secondary, .button--tertiary, .button--buy, .lead, [type=tel],
[type=email],
[type=number],
[type=text],
[type=search], textarea, select, .image-block__content {
  font-size: 14px;
  line-height: 1.3; }
  @media (min-width: 48.0625em) {
    h4,
    .h4, th, .subject, .section__header-subject, .section__text-subject, .image-block__subject, .tick-list__item, .button--small, .permission-bar__button, .button--primary--small, .button--secondary--small, .checkout__basket-anchor, .button--tertiary--small, .button, .checkout__submit, .checkout__submit--us, .discount__submit, .button--primary, .button--secondary, .button--tertiary, .button--buy, .lead, [type=tel],
    [type=email],
    [type=number],
    [type=text],
    [type=search], textarea, select, .image-block__content {
      font-size: 16px; } }
  .lt-ie9 h4, .lt-ie9
  .h4, .lt-ie9 th, .lt-ie9 .subject, .lt-ie9 .section__header-subject, .lt-ie9 .section__text-subject, .lt-ie9 .image-block__subject, .lt-ie9 .tick-list__item, .lt-ie9 .button--small, .lt-ie9 .permission-bar__button, .lt-ie9 .button--primary--small, .lt-ie9 .button--secondary--small, .lt-ie9 .checkout__basket-anchor, .lt-ie9 .button--tertiary--small, .lt-ie9 .button, .lt-ie9 .checkout__submit, .lt-ie9 .checkout__submit--us, .lt-ie9 .discount__submit, .lt-ie9 .button--primary, .lt-ie9 .button--secondary, .lt-ie9 .button--tertiary, .lt-ie9 .button--buy, .lt-ie9 .lead, .lt-ie9 [type=tel], .lt-ie9
  [type=email], .lt-ie9
  [type=number], .lt-ie9
  [type=text], .lt-ie9
  [type=search], .lt-ie9 textarea, .lt-ie9 select, .lt-ie9 .image-block__content {
    font-size: 16px; }

h3,
.h3, blockquote {
  line-height: 1.4;
  font-size: 14px; }
  @media (min-width: 48.0625em) {
    h3,
    .h3, blockquote {
      font-size: 18px; } }
  .lt-ie9 h3, .lt-ie9
  .h3, .lt-ie9 blockquote {
    font-size: 18px; }

.checkout__phone, .discount__input, .blog-item__header {
  line-height: 1.4;
  font-size: 16px; }
  @media (min-width: 48.0625em) {
    .checkout__phone, .discount__input, .blog-item__header {
      font-size: 20px; } }
  .lt-ie9 .checkout__phone, .lt-ie9 .discount__input, .lt-ie9 .blog-item__header {
    font-size: 20px; }

h1,
.h1, .image-block__header, .section__text-header, h2,
.h2, h3,
.h3, blockquote, .subject, .section__header-subject, .section__text-subject, .image-block__subject, .tick-list__item, .button--small, .permission-bar__button, .button--primary--small, .button--secondary--small, .checkout__basket-anchor, .button--tertiary--small, .button, .checkout__submit, .checkout__submit--us, .discount__submit, .button--primary, .button--secondary, .button--tertiary, .button--buy, .lead, [type=tel],
[type=email],
[type=number],
[type=text],
[type=search], textarea, select {
  color: #272324;
  font-weight: 300;
  margin-bottom: 0.5em; }

h1,
.h1, .image-block__header, .section__text-header {
  line-height: 1.1;
  font-size: 24px; }
  @media (min-width: 48.0625em) {
    h1,
    .h1, .image-block__header, .section__text-header {
      font-size: 48px; } }
  .lt-ie9 h1, .lt-ie9
  .h1, .lt-ie9 .image-block__header, .lt-ie9 .section__text-header {
    font-size: 48px; }

h2,
.h2 {
  line-height: 1.2;
  font-size: 18px; }
  @media (min-width: 48.0625em) {
    h2,
    .h2 {
      font-size: 28px; } }
  .lt-ie9 h2, .lt-ie9
  .h2 {
    font-size: 28px; }

h3,
.h3, blockquote {
  font-family: "Times", "Times New Roman", serif;
  font-style: italic; }

h4,
.h4, th, strong,
b {
  color: #272324;
  line-height: 1.4;
  font-weight: bold;
  margin-bottom: 0.8em; }

.subject, .section__header-subject, .section__text-subject, .image-block__subject, .tick-list__item, .button--small, .permission-bar__button, .button--primary--small, .button--secondary--small, .checkout__basket-anchor, .button--tertiary--small, .button, .checkout__submit, .checkout__submit--us, .discount__submit, .button--primary, .button--secondary, .button--tertiary, .button--buy, .lead, [type=tel],
[type=email],
[type=number],
[type=text],
[type=search], textarea, select {
  line-height: 1.63; }

.subject, .section__header-subject, .section__text-subject, .image-block__subject, .tick-list__item {
  font-family: "Times", "Times New Roman", serif;
  font-style: italic; }

.button--small, .permission-bar__button, .button--primary--small, .button--secondary--small, .checkout__basket-anchor, .button--tertiary--small, figcaption,
.caption,
.label {
  font-size: 10px; }
  @media (min-width: 48.0625em) {
    .button--small, .permission-bar__button, .button--primary--small, .button--secondary--small, .checkout__basket-anchor, .button--tertiary--small, figcaption,
    .caption,
    .label {
      font-size: 12px; } }
  .lt-ie9 .button--small, .lt-ie9 .permission-bar__button, .lt-ie9 .button--primary--small, .lt-ie9 .button--secondary--small, .lt-ie9 .checkout__basket-anchor, .lt-ie9 .button--tertiary--small, .lt-ie9 figcaption, .lt-ie9
  .caption, .lt-ie9
  .label {
    font-size: 12px; }

strong,
b {
  line-height: 1.5; }

.lead {
  margin-bottom: 1em; }
  @media (min-width: 48.0625em) {
    .lead {
      margin-bottom: 2em; } }
  .lt-ie9 .lead {
    margin-bottom: 2em; }
  @media (min-width: 73.5em) {
    .lead {
      margin-bottom: 3em; } }

p {
  margin-bottom: 10px;
  margin-bottom: 1rem; }
  p a {
    border-bottom: 2px solid #af9b56; }

a {
  color: #000;
  font-weight: normal;
  text-decoration: none; }
  a:hover, a:focus {
    color: #000; }
  a:focus {
    box-shadow: 0;
    outline: dotted thin #000; }
  a:active {
    color: #272324;
    text-decoration: none; }
  a[href^=tel] {
    color: inherit;
    text-decoration: none; }

::selection {
  background: #1BB2E0;
  color: #fd0;
  text-shadow: none; }

::-moz-selection {
  background: #1BB2E0;
  color: #fd0;
  text-shadow: none; }

em {
  font-style: italic; }

.invert-text {
  color: #fff; }
  .invert-text a {
    color: #ffdf00; }
  .invert-text headings(1)headings(3),
  .invert-text strong,
  .invert-text b {
    color: inherit; }
  .invert-text .button--small, .invert-text .permission-bar__button, .invert-text .button--primary--small, .invert-text .button--secondary--small, .invert-text .checkout__basket-anchor, .invert-text .button--tertiary--small, .invert-text .button, .invert-text .checkout__submit, .invert-text .checkout__submit--us, .invert-text .discount__submit, .invert-text .button--primary, .invert-text .button--secondary, .invert-text .button--tertiary, .invert-text .button--buy {
    color: inherit; }

.subject, .section__header-subject, .section__text-subject, .image-block__subject {
  display: block; }
  .subject:after, .section__header-subject:after, .section__text-subject:after, .image-block__subject:after {
    border-bottom: 1px solid #272324;
    content: "";
    display: inline-block;
    margin-left: 5px;
    position: relative;
    bottom: -3px;
    width: 1.5em; }
    .invert-text .subject:after, .invert-text .section__header-subject:after, .invert-text .section__text-subject:after, .invert-text .image-block__subject:after {
      border-color: #fff; }

/*

// Extending icon selector
.button { @extend .icon--button; }

// Extending placeholder selector (must be applied to pseudo element)
.button {
  &:before { @extend %icon--button; }
}

// Using mixin (must be applied to pseudo element)
// Extends a placeholder selector %icon--button
.button {
  &:before { @include use-icon( button ); }
}

// Icon only (text is hidden)
.arrow {
  @extend %icon-only;
  @extend .icon--arrow;
}

*/
/*

// Forcing style output rather than extending a placeholder selector
.button:before {
  @include susy-breakpoint( $bp-l-xl ) {
    @include use-icon( button, true );
  }
}

*/
/*
In most cases it's not necessary to use all available icons in a given syntax.
This way, we can hand pick the icons we want to use.
Put # in front of { $name } before use

SCSS
====

.footer {
  &__icon {
    @each $name in facebook, instagram {
      &--{ $name } {
        @extend .icon--{ $name };
      }
    }
  }
}

Output
======

.footer__icon--facebook { ... }
.footer__icon--instagram { ... }

*/
/**
 * Returns an icon map base on its name
 * If a property is provided, the value of that property is returned
 * @type  {function}
 * @param {String}  $name       [required] icon name
 * @param {String}  $property   [optional] property name
 */
/**
 * Set icon
 * Can be used without $icons map
 * Must be applied to a pseudo element (:before, :after)
 * @type  {mixin}
 * @param {String}  $char  [required] hexadecimal letter for the icon
 * @param {Bool}    $force [optional] if set to true, the icon styles
 *                                    are rendered inline rather than
 *                                    through @extend (for embedding
 *                                    in media queries)
 */
/**
 * Use icon
 * Requires $icons map to be defined
 * Must be applied to a pseudo element (:before, :after)
 * @type  {mixin}
 * @param {String}  $name   [required] name of icon to use
 * @param {Bool}    $force  [optional] if set to true, the icon styles
 *                                     are rendered inline rather than
 *                                     through @extend (for embedding
 *                                     in media queries)
 */
.icon--plus:before, .number__increase:after, .icon--minus:before, .number__decrease:after, .icon--arrow-circle-right:before, .icon--arrow-circle-left:before, .icon--tick:before, .tick-list__item:before, .icon--error:before, .icon--basket:before, .icon--search:before, .icon--phone:before, .checkout__phone:before, .icon--arrow-right:before, .icon--arrow-down:before, .icon--menu-burger:before, .icon--delete:before, .icon--leaves:before, .splat__icon--bestseller::before, .splat__icon--bestseller--large:before, .icon--stars:before, .splat__icon--bit:before, .splat__icon--bit--large:before, .icon--facebook:before, .icon--instagram:before, .icon--feature-40c:before, .feature-list__item--feature-40c:before, .icon--feature-104f:before, .feature-list__item--feature-104f:before, .icon--feature-dishwasher:before, .feature-list__item--feature-dishwasher:before, .icon--feature-freezer:before, .feature-list__item--feature-freezer:before, .icon--feature-halogen:before, .feature-list__item--feature-halogen:before, .icon--feature-induction:before, .feature-list__item--feature-induction:before, .icon--feature-gas:before, .feature-list__item--feature-gas:before, .icon--feature-ceramic:before, .feature-list__item--feature-ceramic:before, .icon--feature-microwave:before, .feature-list__item--feature-microwave:before, .icon--feature-ovenproof:before, .feature-list__item--feature-ovenproof:before, .icon--feature-radiantrin:before, .feature-list__item--feature-radiantrin:before, .icon--feature-solidplate:before, .feature-list__item--feature-solidplate:before, .icon:before {
  font-family: "icons";
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  height: 1em;
  line-height: 1em;
  speak: none;
  text-indent: 0;
  text-transform: none;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased; }

.number__increase, .number__decrease {
  text-indent: -999px;
  overflow: hidden;
  display: inline-block; }
  .number__increase:before, .number__decrease:before, .number__increase:after, .number__decrease:after {
    float: inherit;
    text-align: center; }

.icon--plus:before, .number__increase:after {
  content: "";
  font-size: 14px;
  font-size: 1.4rem; }

.icon--minus:before, .number__decrease:after {
  content: "";
  font-size: 14px;
  font-size: 1.4rem; }

.icon--arrow-circle-right:before {
  content: "";
  font-size: 48px;
  font-size: 4.8rem; }

.icon--arrow-circle-left:before {
  content: "";
  font-size: 48px;
  font-size: 4.8rem; }

.icon--tick:before, .tick-list__item:before {
  color: #6d9343;
  content: "";
  font-size: 18px;
  font-size: 1.8rem; }

.icon--error:before {
  color: #d62c2d;
  content: "";
  font-size: 21px;
  font-size: 2.1rem; }

.icon--basket:before {
  content: "";
  font-size: 20px;
  font-size: 2rem; }

.icon--search:before {
  color: #AE9C57;
  content: "";
  font-size: 14px;
  font-size: 1.4rem; }

.icon--phone:before, .checkout__phone:before {
  content: "";
  font-size: 20px;
  font-size: 2rem; }

.icon--arrow-right:before {
  content: "";
  font-size: 25px;
  font-size: 2.5rem; }

.icon--arrow-down:before {
  content: "";
  font-size: 18px;
  font-size: 1.8rem; }

.icon--menu-burger:before {
  content: "";
  font-size: 33px;
  font-size: 3.3rem; }

.icon--delete:before {
  content: "";
  font-size: 25px;
  font-size: 2.5rem; }

.icon--leaves:before, .splat__icon--bestseller::before, .splat__icon--bestseller--large:before {
  content: "";
  font-size: 50px;
  font-size: 5rem; }

.icon--stars:before, .splat__icon--bit:before, .splat__icon--bit--large:before {
  content: "";
  font-size: 50px;
  font-size: 5rem; }

.icon--facebook:before {
  color: #3c5a98;
  content: "";
  font-size: 30px;
  font-size: 3rem; }

.icon--instagram:before {
  color: #33648d;
  content: "";
  font-size: 30px;
  font-size: 3rem; }

.icon--feature-40c:before, .feature-list__item--feature-40c:before {
  content: "";
  font-size: 18px;
  font-size: 1.8rem; }

.icon--feature-104f:before, .feature-list__item--feature-104f:before {
  content: "";
  font-size: 18px;
  font-size: 1.8rem; }

.icon--feature-dishwasher:before, .feature-list__item--feature-dishwasher:before {
  content: "";
  font-size: 18px;
  font-size: 1.8rem; }

.icon--feature-freezer:before, .feature-list__item--feature-freezer:before {
  content: "";
  font-size: 18px;
  font-size: 1.8rem; }

.icon--feature-halogen:before, .feature-list__item--feature-halogen:before {
  content: "";
  font-size: 18px;
  font-size: 1.8rem; }

.icon--feature-induction:before, .feature-list__item--feature-induction:before {
  content: "";
  font-size: 18px;
  font-size: 1.8rem; }

.icon--feature-gas:before, .feature-list__item--feature-gas:before {
  content: "";
  font-size: 18px;
  font-size: 1.8rem; }

.icon--feature-ceramic:before, .feature-list__item--feature-ceramic:before {
  content: "";
  font-size: 18px;
  font-size: 1.8rem; }

.icon--feature-microwave:before, .feature-list__item--feature-microwave:before {
  content: "";
  font-size: 18px;
  font-size: 1.8rem; }

.icon--feature-ovenproof:before, .feature-list__item--feature-ovenproof:before {
  content: "";
  font-size: 18px;
  font-size: 1.8rem; }

.icon--feature-radiantrin:before, .feature-list__item--feature-radiantrin:before {
  content: "";
  font-size: 18px;
  font-size: 1.8rem; }

.icon--feature-solidplate:before, .feature-list__item--feature-solidplate:before {
  content: "";
  font-size: 18px;
  font-size: 1.8rem; }

.icon:before {
  content: attr(data-icon); }

html {
  font-size: 62.5%; }

body {
  margin: 0 auto;
  /**
 * Remove default margin. and scrollbar
 */
  overflow: -moz-scrollbars-none;
  -ms-overflow-style: none;
  color: #666;
  line-height: 1.5;
  font-family: "Open Sans", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  font-size: 1.2em; }
  @media (min-width: 48.0625em) {
    body {
      font-size: 14px; } }
  .lt-ie9 body {
    font-size: 14px; }
  @media (min-width: 30.0625em) {
    body {
      font-size: 1.4em; } }
  .lt-ie9 body {
    font-size: 1.4em; }

/* Alin */
body::-webkit-scrollbar {
  width: 0 !important; }

.splat__text, .splat__text--primary, .splat__text--bestseller, .splat__text--secondary, .splat--large .splat__text--tertiary, .splat--large-alt .splat__text--tertiary, .splat--bestseller--large .splat__text--tertiary, .splat--bit--large .splat__text--tertiary {
  color: inherit;
  display: block;
  line-height: 1; }

.splat__icon--bestseller, .splat__icon--bestseller--large, .splat__icon--bit, .splat__icon--bit--large {
  height: 100%;
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%; }
  .splat__icon--bestseller:before, .splat__icon--bestseller--large:before, .splat__icon--bit:before, .splat__icon--bit--large:before {
    font-size: inherit !important;
    line-height: 1;
    margin-top: -0.5em;
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%; }

.splat, .splat--secondary, .splat--tertiary, .splat--large-alt, .splat--large, .splat--bestseller--large, .splat--bit--large, .splat--bestseller, .splat--bit {
  background-color: transparent;
  color: #fff;
  display: block;
  height: 4.6428571429em;
  text-align: center;
  position: relative;
  width: 4.6428571429em; }
  .splat__inner {
    display: table-cell;
    height: 100%;
    vertical-align: middle;
    width: 100%; }
  .splat__border {
    display: table;
    height: 4.6428571429em;
    position: absolute;
    left: 0;
    top: 0;
    width: 4.6428571429em;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box; }
  .splat__text, .splat__text--primary, .splat__text--bestseller, .splat__text--secondary, .splat--large .splat__text--tertiary, .splat--large-alt .splat__text--tertiary, .splat--bestseller--large .splat__text--tertiary, .splat--bit--large .splat__text--tertiary {
    font-weight: 500;
    text-transform: uppercase;
    margin: 2px 0;
    margin:  0.2rem 0; }
    .splat__text--primary, .splat__text--bestseller {
      font-size: 1.2857142857em;
      font-weight: 700; }
    .splat__text--secondary {
      font-size: 0.8571428571em; }
  .splat__image {
    width: 65%; }
    @media (min-width: 30.0625em) {
      .splat__image {
        width: auto; } }
    .lt-ie9 .splat__image {
      width: auto; }
  .splat--secondary {
    background-color: #6d9343;
    color: #fff; }
  .splat--tertiary, .splat--large-alt {
    background-color: #fcdf7a;
    color: #272324; }
  .splat--large, .splat--large-alt, .splat--bestseller--large, .splat--bit--large {
    height: 10em;
    width: 10em;
    font-size: 0.6666666667em; }
    @media (min-width: 30.0625em) {
      .splat--large, .splat--large-alt, .splat--bestseller--large, .splat--bit--large {
        font-size: 0.8571428571em; } }
    .lt-ie9 .splat--large, .lt-ie9 .splat--large-alt, .lt-ie9 .splat--bestseller--large, .lt-ie9 .splat--bit--large {
      font-size: 0.8571428571em; }
    @media (min-width: 48.0625em) {
      .splat--large, .splat--large-alt, .splat--bestseller--large, .splat--bit--large {
        font-size: 0.7142857143em; } }
    .lt-ie9 .splat--large, .lt-ie9 .splat--large-alt, .lt-ie9 .splat--bestseller--large, .lt-ie9 .splat--bit--large {
      font-size: 0.7142857143em; }
    @media (min-width: 73.5em) {
      .splat--large, .splat--large-alt, .splat--bestseller--large, .splat--bit--large {
        font-size: 1em; } }
    .splat--large .splat__text, .splat--large-alt .splat__text, .splat--bestseller--large .splat__text, .splat--bit--large .splat__text, .splat--large .splat__text--primary, .splat--large-alt .splat__text--primary, .splat--bestseller--large .splat__text--primary, .splat--bit--large .splat__text--primary, .splat--large .splat__text--bestseller, .splat--large-alt .splat__text--bestseller, .splat--bestseller--large .splat__text--bestseller, .splat--bit--large .splat__text--bestseller, .splat--large .splat__text--secondary, .splat--large-alt .splat__text--secondary, .splat--bestseller--large .splat__text--secondary, .splat--bit--large .splat__text--secondary, .splat--large .splat__text--tertiary, .splat--large-alt .splat__text--tertiary, .splat--bestseller--large .splat__text--tertiary, .splat--bit--large .splat__text--tertiary {
      font-weight: 300;
      text-transform: none;
      margin: 6px 0;
      margin:  0.6rem 0; }
      .splat--large .splat__text--primary, .splat--large-alt .splat__text--primary, .splat--bestseller--large .splat__text--primary, .splat--bit--large .splat__text--primary, .splat--large .splat__text--bestseller, .splat--large-alt .splat__text--bestseller, .splat--bestseller--large .splat__text--bestseller, .splat--bit--large .splat__text--bestseller {
        font-size: 1.7142857143em;
        text-transform: uppercase; }
      .splat--large .splat__text--secondary, .splat--large-alt .splat__text--secondary, .splat--bestseller--large .splat__text--secondary, .splat--bit--large .splat__text--secondary {
        font-size: 1.1428571429em; }
      .splat--large .splat__text--tertiary, .splat--large-alt .splat__text--tertiary, .splat--bestseller--large .splat__text--tertiary, .splat--bit--large .splat__text--tertiary {
        font-size: 0.7857142857em; }
  .splat--large-alt {
    display: table; }
    .splat--large-alt .splat__text--primary, .splat--large-alt .splat__text--bestseller {
      font-size: 2.8571428571em;
      font-weight: bold;
      margin: 0; }
    .splat--large-alt .splat__text--secondary {
      font-size: 1.7857142857em;
      margin-bottom: 0.08em;
      text-transform: uppercase; }
    .splat--large-alt .splat__text--tertiary {
      font-size: 0.9285714286em;
      margin-top: 0.1538461538em; }
  .splat--bestseller, .splat--bestseller--large {
    background-color: #fff;
    color: #272324;
    height: 5.5714285714em;
    padding: 0.7142857143em;
    position: relative;
    width: 5.5714285714em;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box; }
  .splat__icon--bestseller {
    font-size: 4.9285714286em; }
    .splat__icon--bestseller::before {
      color: #6d9343; }
  .splat__text--bestseller {
    font-size: 0.8571428571em; }
  .splat--bestseller--large {
    display: block;
    height: 11.4285714286em;
    width: 11.4285714286em; }
    .splat--bestseller--large .splat__border {
      border: 2px dotted #272324;
      height: 11.4285714286em;
      width: 11.4285714286em;
      padding: 0 20px;
      padding:  0 2rem; }
  .splat__icon--bestseller--large {
    font-size: 8.7857142857em; }
    .splat__icon--bestseller--large:before {
      color: #6d9343; }
  .splat--bit, .splat--bit--large {
    background-color: #6d9343;
    height: 4.6428571429em;
    position: relative;
    width: 4.6428571429em; }
  .splat--jul {
    position: absolute;
    left: 0;
    top: 0; }
  .splat__icon--bit {
    font-size: 4.0714285714em; }
    .splat__icon--bit:before {
      color: #fff; }
  .splat__text--bit {
    display: block;
    font-size: 0.7857142857em;
    line-height: 1;
    padding: 0.2142857143em 0.4285714286em 0; }
  .splat--bit--large {
    height: 11.4285714286em;
    width: 11.4285714286em; }
    .splat--bit--large .splat__text--bit {
      font-size: 1.7142857143em;
      padding: 0; }
  .splat__icon--bit--large {
    font-size: 9.2857142857em; }
    .splat__icon--bit--large:before {
      color: #fff; }

ul {
  list-style: disc; }

ol {
  list-style: decimal; }

nav ul {
  list-style: none; }

.tick-list__item:before, .feature-list__item:before, .feature-list__item--feature-40c:before, .feature-list__item--feature-104f:before, .feature-list__item--feature-dishwasher:before, .feature-list__item--feature-freezer:before, .feature-list__item--feature-halogen:before, .feature-list__item--feature-induction:before, .feature-list__item--feature-gas:before, .feature-list__item--feature-ceramic:before, .feature-list__item--feature-microwave:before, .feature-list__item--feature-ovenproof:before, .feature-list__item--feature-radiantrin:before, .feature-list__item--feature-solidplate:before {
  display: inline;
  position: relative;
  bottom: -0.1428571429em;
  margin-right: 10px;
  margin-right: 1rem; }

.tick-list__item {
  margin-bottom: 0; }
  @media (min-width: 30.0625em) {
    .tick-list__item {
      display: inline-block;
      margin-right: 2.5em; }
      .tick-list__item:last-child {
        margin-right: 0; } }
  .lt-ie9 .tick-list__item {
    display: inline-block;
    margin-right: 2.5em; }
    .lt-ie9 .tick-list__item:last-child {
      margin-right: 0; }

.feature-list__item.deactive, .deactive.feature-list__item--feature-40c, .deactive.feature-list__item--feature-104f, .deactive.feature-list__item--feature-dishwasher, .deactive.feature-list__item--feature-freezer, .deactive.feature-list__item--feature-halogen, .deactive.feature-list__item--feature-induction, .deactive.feature-list__item--feature-gas, .deactive.feature-list__item--feature-ceramic, .deactive.feature-list__item--feature-microwave, .deactive.feature-list__item--feature-ovenproof, .deactive.feature-list__item--feature-radiantrin, .deactive.feature-list__item--feature-solidplate, .feature-list__item.deactive:before, .deactive.feature-list__item--feature-40c:before, .deactive.feature-list__item--feature-104f:before, .deactive.feature-list__item--feature-dishwasher:before, .deactive.feature-list__item--feature-freezer:before, .deactive.feature-list__item--feature-halogen:before, .deactive.feature-list__item--feature-induction:before, .deactive.feature-list__item--feature-gas:before, .deactive.feature-list__item--feature-ceramic:before, .deactive.feature-list__item--feature-microwave:before, .deactive.feature-list__item--feature-ovenproof:before, .deactive.feature-list__item--feature-radiantrin:before, .deactive.feature-list__item--feature-solidplate:before {
  color: #999; }

.feature-list__item.deactive .feature-list__item-text, .deactive.feature-list__item--feature-40c .feature-list__item-text, .deactive.feature-list__item--feature-104f .feature-list__item-text, .deactive.feature-list__item--feature-dishwasher .feature-list__item-text, .deactive.feature-list__item--feature-freezer .feature-list__item-text, .deactive.feature-list__item--feature-halogen .feature-list__item-text, .deactive.feature-list__item--feature-induction .feature-list__item-text, .deactive.feature-list__item--feature-gas .feature-list__item-text, .deactive.feature-list__item--feature-ceramic .feature-list__item-text, .deactive.feature-list__item--feature-microwave .feature-list__item-text, .deactive.feature-list__item--feature-ovenproof .feature-list__item-text, .deactive.feature-list__item--feature-radiantrin .feature-list__item-text, .deactive.feature-list__item--feature-solidplate .feature-list__item-text {
  text-decoration: line-through; }

.feature-list__item:before, .feature-list__item--feature-40c:before, .feature-list__item--feature-104f:before, .feature-list__item--feature-dishwasher:before, .feature-list__item--feature-freezer:before, .feature-list__item--feature-halogen:before, .feature-list__item--feature-induction:before, .feature-list__item--feature-gas:before, .feature-list__item--feature-ceramic:before, .feature-list__item--feature-microwave:before, .feature-list__item--feature-ovenproof:before, .feature-list__item--feature-radiantrin:before, .feature-list__item--feature-solidplate:before {
  color: #272324;
  font-size: 1.7857142857em; }

.button--small, .permission-bar__button, .button--primary--small, .button--secondary--small, .checkout__basket-anchor, .button--tertiary--small, .button, .checkout__submit, .checkout__submit--us, .discount__submit, .button--primary, .button--secondary, .button--tertiary, .button--buy {
  border: 0 solid transparent;
  border-bottom-width: 0.3em;
  border-radius: 0;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-weight: 500;
  padding: 0.8em 3em 0.6em;
  text-align: center;
  text-transform: uppercase;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: #6d9343;
  border-color: #60813b; }
  .button--small:hover, .permission-bar__button:hover, .button--primary--small:hover, .button--secondary--small:hover, .checkout__basket-anchor:hover, .button--tertiary--small:hover, .button:hover, .checkout__submit:hover, .checkout__submit--us:hover, .discount__submit:hover, .button--primary:hover, .button--secondary:hover, .button--tertiary:hover, .button--buy:hover, .button--small:active, .permission-bar__button:active, .button--primary--small:active, .button--secondary--small:active, .checkout__basket-anchor:active, .button--tertiary--small:active, .button:active, .checkout__submit:active, .checkout__submit--us:active, .discount__submit:active, .button--primary:active, .button--secondary:active, .button--tertiary:active, .button--buy:active, .hover.button--small, .hover.permission-bar__button, .hover.button--primary--small, .hover.button--secondary--small, .hover.checkout__basket-anchor, .hover.button--tertiary--small, .hover.button, .hover.checkout__submit, .hover.checkout__submit--us, .hover.discount__submit, .hover.button--primary, .hover.button--secondary, .hover.button--tertiary, .hover.button--buy, .pressed.button--small, .pressed.permission-bar__button, .pressed.button--primary--small, .pressed.button--secondary--small, .pressed.checkout__basket-anchor, .pressed.button--tertiary--small, .pressed.button, .pressed.checkout__submit, .pressed.checkout__submit--us, .pressed.discount__submit, .pressed.button--primary, .pressed.button--secondary, .pressed.button--tertiary, .pressed.button--buy {
    background-color: #7aa54b; }
  .button--small:disabled, .permission-bar__button:disabled, .button--primary--small:disabled, .button--secondary--small:disabled, .checkout__basket-anchor:disabled, .button--tertiary--small:disabled, .button:disabled, .checkout__submit:disabled, .checkout__submit--us:disabled, .discount__submit:disabled, .button--primary:disabled, .button--secondary:disabled, .button--tertiary:disabled, .button--buy:disabled, .disabled.button--small, .disabled.permission-bar__button, .disabled.button--primary--small, .disabled.button--secondary--small, .disabled.checkout__basket-anchor, .disabled.button--tertiary--small, .disabled.button, .disabled.checkout__submit, .disabled.checkout__submit--us, .disabled.discount__submit, .disabled.button--primary, .disabled.button--secondary, .disabled.button--tertiary, .disabled.button--buy {
    background-color: #6d9343; }
  @media (min-width: 25em) {
    .button--small, .permission-bar__button, .button--primary--small, .button--secondary--small, .checkout__basket-anchor, .button--tertiary--small, .button, .checkout__submit, .checkout__submit--us, .discount__submit, .button--primary, .button--secondary, .button--tertiary, .button--buy {
      white-space: nowrap; } }
  .lt-ie9 .button--small, .lt-ie9 .permission-bar__button, .lt-ie9 .button--primary--small, .lt-ie9 .button--secondary--small, .lt-ie9 .checkout__basket-anchor, .lt-ie9 .button--tertiary--small, .lt-ie9 .button, .lt-ie9 .checkout__submit, .lt-ie9 .checkout__submit--us, .lt-ie9 .discount__submit, .lt-ie9 .button--primary, .lt-ie9 .button--secondary, .lt-ie9 .button--tertiary, .lt-ie9 .button--buy {
    white-space: nowrap; }
  .button--small:hover, .permission-bar__button:hover, .button--primary--small:hover, .button--secondary--small:hover, .checkout__basket-anchor:hover, .button--tertiary--small:hover, .button:hover, .checkout__submit:hover, .checkout__submit--us:hover, .discount__submit:hover, .button--primary:hover, .button--secondary:hover, .button--tertiary:hover, .button--buy:hover, .hover.button--small, .hover.permission-bar__button, .hover.button--primary--small, .hover.button--secondary--small, .hover.checkout__basket-anchor, .hover.button--tertiary--small, .hover.button, .hover.checkout__submit, .hover.checkout__submit--us, .hover.discount__submit, .hover.button--primary, .hover.button--secondary, .hover.button--tertiary, .hover.button--buy, .button--small:active, .permission-bar__button:active, .button--primary--small:active, .button--secondary--small:active, .checkout__basket-anchor:active, .button--tertiary--small:active, .button:active, .checkout__submit:active, .checkout__submit--us:active, .discount__submit:active, .button--primary:active, .button--secondary:active, .button--tertiary:active, .button--buy:active, .button--small:focus, .permission-bar__button:focus, .button--primary--small:focus, .button--secondary--small:focus, .checkout__basket-anchor:focus, .button--tertiary--small:focus, .button:focus, .checkout__submit:focus, .checkout__submit--us:focus, .discount__submit:focus, .button--primary:focus, .button--secondary:focus, .button--tertiary:focus, .button--buy:focus, .pressed.button--small, .pressed.permission-bar__button, .pressed.button--primary--small, .pressed.button--secondary--small, .pressed.checkout__basket-anchor, .pressed.button--tertiary--small, .pressed.button, .pressed.checkout__submit, .pressed.checkout__submit--us, .pressed.discount__submit, .pressed.button--primary, .pressed.button--secondary, .pressed.button--tertiary, .pressed.button--buy {
    color: #fff;
    text-decoration: none; }
  .button--small:active, .permission-bar__button:active, .button--primary--small:active, .button--secondary--small:active, .checkout__basket-anchor:active, .button--tertiary--small:active, .button:active, .checkout__submit:active, .checkout__submit--us:active, .discount__submit:active, .button--primary:active, .button--secondary:active, .button--tertiary:active, .button--buy:active, .button--small:focus, .permission-bar__button:focus, .button--primary--small:focus, .button--secondary--small:focus, .checkout__basket-anchor:focus, .button--tertiary--small:focus, .button:focus, .checkout__submit:focus, .checkout__submit--us:focus, .discount__submit:focus, .button--primary:focus, .button--secondary:focus, .button--tertiary:focus, .button--buy:focus, .pressed.button--small, .pressed.permission-bar__button, .pressed.button--primary--small, .pressed.button--secondary--small, .pressed.checkout__basket-anchor, .pressed.button--tertiary--small, .pressed.button, .pressed.checkout__submit, .pressed.checkout__submit--us, .pressed.discount__submit, .pressed.button--primary, .pressed.button--secondary, .pressed.button--tertiary, .pressed.button--buy {
    border-bottom-width: 0;
    border-top-width: 0.3em;
    padding-top: 0.6em;
    padding-bottom: 0.8em; }
  .button--small:disabled, .permission-bar__button:disabled, .button--primary--small:disabled, .button--secondary--small:disabled, .checkout__basket-anchor:disabled, .button--tertiary--small:disabled, .button:disabled, .checkout__submit:disabled, .checkout__submit--us:disabled, .discount__submit:disabled, .button--primary:disabled, .button--secondary:disabled, .button--tertiary:disabled, .button--buy:disabled, .disabled.button--small, .disabled.permission-bar__button, .disabled.button--primary--small, .disabled.button--secondary--small, .disabled.checkout__basket-anchor, .disabled.button--tertiary--small, .disabled.button, .disabled.checkout__submit, .disabled.checkout__submit--us, .disabled.discount__submit, .disabled.button--primary, .disabled.button--secondary, .disabled.button--tertiary, .disabled.button--buy {
    border-bottom-width: 0.3em;
    border-top-width: 0;
    opacity: 0.5;
    padding-top: 0.8em;
    padding-bottom: 0.6em; }

.button--primary, .button--primary--small {
  background-color: #6d9343;
  border-color: #60813b; }
  .button--primary:hover, .button--primary--small:hover, .button--primary:active, .button--primary--small:active, .button--primary.hover, .hover.button--primary--small, .button--primary.pressed, .pressed.button--primary--small {
    background-color: #7aa54b; }
  .button--primary:disabled, .button--primary--small:disabled, .button--primary.disabled, .disabled.button--primary--small {
    background-color: #6d9343; }

.button--secondary, .button--secondary--small, .checkout__basket-anchor {
  background-color: #384d64;
  border-color: #2f4054; }
  .button--secondary:hover, .button--secondary--small:hover, .checkout__basket-anchor:hover, .button--secondary:active, .button--secondary--small:active, .checkout__basket-anchor:active, .button--secondary.hover, .hover.button--secondary--small, .hover.checkout__basket-anchor, .button--secondary.pressed, .pressed.button--secondary--small, .pressed.checkout__basket-anchor {
    background-color: #415a74; }
  .button--secondary:disabled, .button--secondary--small:disabled, .checkout__basket-anchor:disabled, .button--secondary.disabled, .disabled.button--secondary--small, .disabled.checkout__basket-anchor {
    background-color: #384d64; }

.button--tertiary, .button--tertiary--small {
  background-color: #353535;
  border-color: #232323; }
  .button--tertiary:hover, .button--tertiary--small:hover, .button--tertiary:active, .button--tertiary--small:active, .button--tertiary.hover, .hover.button--tertiary--small, .button--tertiary.pressed, .pressed.button--tertiary--small {
    background-color: #424242; }
  .button--tertiary:disabled, .button--tertiary--small:disabled, .button--tertiary.disabled, .disabled.button--tertiary--small {
    background-color: #353535; }

.button--buy, .checkout__basket-anchor {
  width: 100%;
  padding: 0.5em 3em 0.3em;
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  text-transform: uppercase; }
  .button--buy:before, .checkout__basket-anchor:before {
    display: inline-block;
    font-size: 32px;
    margin-right: 0.375em;
    margin-top: -0.375em;
    position: relative;
    top: 0.25em; }

[type=tel],
[type=email],
[type=number],
[type=text],
[type=search], textarea, select {
  margin: 10px 0 20px;
  margin:  1rem 0 2rem; }

[type=tel],
[type=email],
[type=number],
[type=text],
[type=search], textarea, select {
  border: 1px solid #ccc;
  display: block;
  height: 50px;
  line-height: 1.3;
  width: 100%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  font-size: 18px;
  font-size: 1.8rem;
  padding: 10px 18px;
  padding:  1rem 1.8rem; }
  @media (min-width: 30.0625em) {
    [type=tel],
    [type=email],
    [type=number],
    [type=text],
    [type=search], textarea, select {
      height: 50px;
      font-size: 16px;
      font-size: 1.6rem;
      padding: 10px 18px;
      padding:  1rem 1.8rem; } }
  .lt-ie9 [type=tel], .lt-ie9
  [type=email], .lt-ie9
  [type=number], .lt-ie9
  [type=text], .lt-ie9
  [type=search], .lt-ie9 textarea, .lt-ie9 select {
    height: 50px;
    font-size: 16px;
    font-size: 1.6rem;
    padding: 10px 18px;
    padding:  1rem 1.8rem; }

.permission-bar__label {
  display: none; }
  .lt-ie10 .permission-bar__label {
    display: block; }

[type=radio] + label, [type=checkbox] + label {
  display: block;
  line-height: 24px;
  min-height: 24px;
  margin: 5px 0;
  margin:  0.5rem 0;
  padding: 0 0 0 30px;
  padding:  0 0 0 3rem; }

.number-container {
  display: block;
  padding-bottom: 50px;
  position: relative;
  margin: 10px 0 20px;
  margin:  1rem 0 2rem; }
  @media (min-width: 30.0625em) {
    .number-container {
      padding-bottom: 50px; } }
  .lt-ie9 .number-container {
    padding-bottom: 50px; }

[type=tel],
[type=email],
[type=number],
[type=text],
[type=search], textarea {
  background-color: #fff; }
  [type=tel]:focus,
  [type=email]:focus,
  [type=number]:focus,
  [type=text]:focus,
  [type=search]:focus, textarea:focus, .focus[type=tel],
  .focus[type=email],
  .focus[type=number],
  .focus[type=text],
  .focus[type=search], textarea.focus {
    background-color: #f7f7f7; }

label {
  margin-top: 10px;
  margin-top: 1rem; }

[type=tel]::-ms-clear,
[type=email]::-ms-clear,
[type=number]::-ms-clear,
[type=text]::-ms-clear,
[type=search]::-ms-clear {
  width: 0;
  height: 0; }

[type=search] {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

textarea {
  height: auto; }

select {
  background: #fff url("../images/../images/forms/select-arrow.png") right 10px center no-repeat;
  height: 50px;
  line-height: 22px;
  padding: 0 0 0 18px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none; }
  @media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min--moz-device-pixel-ratio: 2), only screen and (-moz-min-device-pixel-ratio: 2), only screen and (-ms-min-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2 / 1), only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
    select {
      background-image: url("../images/forms@2x/select-arrow.png");
      background-size: image-width("forms@2x/select-arrow.png")/2 auto; } }
  @media (min-width: 30.0625em) {
    select {
      height: 50px;
      padding: 0 0 0 18px; } }
  .lt-ie9 select {
    height: 50px;
    padding: 0 0 0 18px; }
  select::-ms-expand {
    display: none; }

@-moz-document url-prefix() {
  select select {
    padding-top: 15px;
    text-indent: 0.01px;
    text-overflow: '';
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none; } }
  select.is-required {
    border-color: red; }
  select:focus, select.focus {
    background-color: #f7f7f7; }

.radio-container [type=radio] {
  position: absolute;
  left: -50px;
  top: 0; }

.radio-container {
  overflow: hidden;
  position: relative; }

[type=radio] + label {
  background: url("../images/forms/radio-btn-normal.png") left center no-repeat; }
  @media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min--moz-device-pixel-ratio: 2), only screen and (-moz-min-device-pixel-ratio: 2), only screen and (-ms-min-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2 / 1), only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
    [type=radio] + label {
      background-image: url("../images/forms/radio-btn-normal.png");
      background-size: image-width("../images/forms/radio-btn-normal.png")/2 auto; } }

[type=radio] + label:hover,
[type=radio]:focus + label,
[type=radio]:checked + label {
  color: black; }

[type=radio]:checked + label {
  background-image: url("../images/forms/radio-btn-checked.png"); }
  @media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min--moz-device-pixel-ratio: 2), only screen and (-moz-min-device-pixel-ratio: 2), only screen and (-ms-min-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2 / 1), only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
    [type=radio]:checked + label {
      background-image: url("../images/forms/radio-btn-checked.png"); } }

[type=radio]:disabled + label {
  color: #d9d9d9; }

.checkbox-container [type=checkbox], .checkout__accept-checkbox-container [type=checkbox] {
  position: absolute;
  left: -50px;
  top: 0; }

.checkbox-container, .checkout__accept-checkbox-container {
  overflow: hidden;
  position: relative; }

[type=checkbox] + label {
  background: url("../images/forms/checkbox-btn-normal.png") left center no-repeat; }
  @media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min--moz-device-pixel-ratio: 2), only screen and (-moz-min-device-pixel-ratio: 2), only screen and (-ms-min-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2 / 1), only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
    [type=checkbox] + label {
      background-image: url("../images/forms/checkbox-btn-normal.png");
      background-size: image-width("../images/forms/checkbox-btn-normal.png")/2 auto; } }
  [type=checkbox] + label:hover {
    color: black; }

[type=checkbox]:focus + label,
[type=checkbox]:checked + label {
  color: black; }

[type=checkbox]:checked + label {
  background-image: url("../images/forms/checkbox-btn-checked.png"); }
  @media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min--moz-device-pixel-ratio: 2), only screen and (-moz-min-device-pixel-ratio: 2), only screen and (-ms-min-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2 / 1), only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
    [type=checkbox]:checked + label {
      background-image: url("../images/forms/checkbox-btn-checked.png"); } }

[type=checkbox]:disabled + label {
  color: #d9d9d9; }

[type=number] {
  -moz-appearance: textfield; }
  [type=number]::-webkit-inner-spin-button, [type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0; }

.number-container {
  padding-left: 60px;
  padding-right: 60px; }
  @media (min-width: 30.0625em) {
    .number-container {
      padding-left: 60px;
      padding-right: 60px; } }
  .lt-ie9 .number-container {
    padding-left: 60px;
    padding-right: 60px; }

.number-container .number__input {
  float: left;
  margin: 0;
  text-align: center; }

.number__increase, .number__decrease {
  background: #f7f7f7;
  border: none;
  height: 50px;
  line-height: 50px;
  outline: 0;
  padding: 0;
  position: absolute;
  top: 0;
  width: 50px; }
  .number__increase:after, .number__decrease:after {
    position: absolute;
    left: 0;
    top: 0;
    text-indent: 0; }
    @media (min-width: 30.0625em) {
      .number__increase:after, .number__decrease:after {
        font-size: 20px; } }
    .lt-ie9 .number__increase:after, .lt-ie9 .number__decrease:after {
      font-size: 20px; }
    .number-container .number__increase:after, .number-container .number__decrease:after {
      height: 100%;
      line-height: inherit;
      width: 100%; }
  @media (min-width: 30.0625em) {
    .number__increase, .number__decrease {
      height: 50px;
      line-height: 50px;
      width: 50px; } }
  .lt-ie9 .number__increase, .lt-ie9 .number__decrease {
    height: 50px;
    line-height: 50px;
    width: 50px; }

.number__increase {
  right: 0; }

.number__decrease {
  left: 0; }

[type=range] {
  /*removes default webkit styles*/
  -webkit-appearance: none;
  /*fix for FF unable to apply focus style bug */
  border: 1px solid white;
  padding: 0;
  /*required for proper track sizing in FF*/
  width: 180px;
  height: 32px;
  /*hide the outline behind the border*/ }
  @media (max-width: 30em) {
    [type=range] {
      width: 100%; } }
  [type=range]::-webkit-slider-runnable-track {
    height: 10px;
    background: #f7f7f7;
    border: none;
    border-radius: 10px;
    width: 180px; }
    @media (max-width: 30em) {
      [type=range]::-webkit-slider-runnable-track {
        width: 100%; } }
  [type=range]::-webkit-slider-thumb {
    -webkit-appearance: none;
    border: none;
    height: 32px;
    width: 32px;
    border-radius: 50%;
    background: #384d64;
    margin-top: -11px; }
  [type=range]:focus {
    outline: none; }
  [type=range]:focus::-webkit-slider-runnable-track {
    background: #e5e5e5; }
  [type=range]::-moz-range-track {
    height: 10px;
    background: #f7f7f7;
    border: none;
    border-radius: 10px;
    width: 180px; }
    @media (max-width: 30em) {
      [type=range]::-moz-range-track {
        width: 100%; } }
  [type=range]::-moz-range-thumb {
    border: none;
    height: 32px;
    width: 32px;
    border-radius: 50%;
    background: #384d64; }
  [type=range]:-moz-focusring {
    outline: 1px solid white;
    outline-offset: -1px; }
  [type=range]::-ms-track {
    height: 10px;
    background: #f7f7f7;
    border: none;
    border-radius: 10px;
    /*remove default tick marks*/
    color: transparent;
    width: 180px; }
    @media (max-width: 30em) {
      [type=range]::-ms-track {
        width: 100%; } }
  [type=range]::-ms-fill-lower {
    outline: none;
    background: #f7f7f7;
    border-radius: 10px 0 0 10px; }
  [type=range]::-ms-thumb {
    border: none;
    height: 32px;
    width: 32px;
    border-radius: 50%;
    background: #384d64; }
  [type=range]:focus::-ms-track {
    background: #e5e5e5; }
  [type=range]:focus::-ms-fill-lower {
    background: #e5e5e5; }

blockquote {
  border-left: 2px solid #dbdbdb;
  font-weight: bold;
  margin: 0;
  width: 50%;
  padding: 0 0 0 20px;
  padding:  0 0 0 2rem; }
  blockquote p:before {
    content: '“'; }
  blockquote p:after {
    content: '”'; }
  blockquote cite {
    color: #666;
    line-height: 1.5;
    font-family: "Open Sans", sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 300;
    display: block; }
    @media (min-width: 48.0625em) {
      blockquote cite {
        font-size: 14px; } }
    .lt-ie9 blockquote cite {
      font-size: 14px; }

table {
  width: 100%; }

tr {
  background-color: #f5f5f5;
  border-bottom: 1px solid #d8d7d7; }

tr:nth-child(2n),
thead tr {
  background-color: transparent; }

th {
  text-align: left;
  font-weight: 300; }

th, td {
  padding: 15px 20px; }

.bullets, .thumbnail-bullets {
  cursor: default;
  display: inline-block;
  padding: 0;
  text-align: center; }

.bullets a, .thumbnail-bullets a {
  background-color: #fff;
  border: 2px solid #cdcdcd;
  cursor: pointer;
  display: inline-block;
  height: 12px;
  position: relative;
  width: 12px; }
  .bullets a:after, .thumbnail-bullets a:after {
    background-color: #384d64;
    content: "";
    display: none;
    position: absolute;
    bottom: 3px;
    left: 3px;
    right: 3px;
    top: 3px; }
  .bullets a.selected, .thumbnail-bullets a.selected {
    border-color: #384d64; }
    .bullets a.selected:after, .thumbnail-bullets a.selected:after {
      display: block; }

@media (min-width: 48.0625em) {
  .thumbnail-bullets a {
    height: auto;
    line-height: 0;
    width: auto;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    -o-border-radius: 0;
    border-radius: 0; }
    .thumbnail-bullets a.selected:after {
      display: none; } }

.lt-ie9 .thumbnail-bullets a, .thumbnail-bullets .lt-ie9 a {
  height: auto;
  line-height: 0;
  width: auto;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  -o-border-radius: 0;
  border-radius: 0; }
  .lt-ie9 .thumbnail-bullets a.selected:after, .thumbnail-bullets .lt-ie9 a.selected:after {
    display: none; }

.thumbnail-bullets img {
  display: none; }
  @media (min-width: 48.0625em) {
    .thumbnail-bullets img {
      display: inline-block; } }
  .lt-ie9 .thumbnail-bullets img, .thumbnail-bullets .lt-ie9 img {
    display: inline-block; }

.lt-ie9 body {
  min-width: 48.0625em; }

.container, .container--center, .global-message__content {
  max-width: 84em;
  margin-left: auto;
  margin-right: auto;
  padding: 0 10px;
  padding:  0 1rem; }
  .container:after, .container--center:after, .global-message__content:after {
    content: " ";
    display: block;
    clear: both; }
  @media (min-width: 30.0625em) {
    .container, .container--center, .global-message__content {
      padding: 0 20px;
      padding:  0 2rem; } }
  .lt-ie9 .container, .lt-ie9 .container--center, .lt-ie9 .global-message__content {
    padding: 0 20px;
    padding:  0 2rem; }

.image-block__content {
  padding: 2.5510204082%; }

@media (min-width: 25em) {
  .row {
    margin: 0 -20px; } }

.lt-ie9 .row {
  margin: 0 -20px; }

.img-fluid {
  width: 100%; }

.pt-1 {
  padding-top: 10px !important; }

.pt-2 {
  padding-top: 20px !important; }

.pt-3 {
  padding-top: 30px !important; }

.pt-4 {
  padding-top: 40px !important; }

.pt-5 {
  padding-top: 50px !important; }

.pt-5 {
  padding-top: 50px !important; }

.pt-6 {
  padding-top: 60px !important; }

.pt-7 {
  padding-top: 70px !important; }

.pt-8 {
  padding-top: 80px !important; }

.pt-9 {
  padding-top: 90px !important; }

.pb-1 {
  padding-bottom: 10px !important; }

.pb-2 {
  padding-bottom: 20px !important; }

.pb-3 {
  padding-bottom: 30px !important; }

.pb-4 {
  padding-bottom: 40px !important; }

.pb-5 {
  padding-bottom: 50px !important; }

.pb-5 {
  padding-bottom: 50px !important; }

.pb-6 {
  padding-bottom: 60px !important; }

.pb-7 {
  padding-bottom: 70px !important; }

.pb-8 {
  padding-bottom: 80px !important; }

.pb-9 {
  padding-bottom: 90px !important; }

@media (min-width: 30.0625em) {
  .pt-lg-9 {
    padding-top: 90px !important; } }

.lt-ie9 .pt-lg-9 {
  padding-top: 90px !important; }

/* v1.0.6 */
/* Core RS CSS file. 95% of time you shouldn't change anything here. */
.royalSlider {
  width: 600px;
  height: 400px;
  position: relative;
  direction: ltr; }

.royalSlider > * {
  float: left; }

.rsWebkit3d .rsSlide,
.rsWebkit3d .rsContainer,
.rsWebkit3d .rsThumbs,
.rsWebkit3d .rsPreloader,
.rsWebkit3d img,
.rsWebkit3d .rsOverflow,
.rsWebkit3d .rsBtnCenterer,
.rsWebkit3d .rsAbsoluteEl,
.rsWebkit3d .rsABlock,
.rsWebkit3d .rsLink {
  -webkit-backface-visibility: hidden; }

.rsFade.rsWebkit3d .rsSlide,
.rsFade.rsWebkit3d img,
.rsFade.rsWebkit3d .rsContainer {
  -webkit-transform: none; }

.rsOverflow {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  float: left;
  -webkit-tap-highlight-color: transparent; }

.rsVisibleNearbyWrap {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  left: 0;
  top: 0;
  -webkit-tap-highlight-color: transparent; }

.rsVisibleNearbyWrap .rsOverflow {
  position: absolute;
  left: 0;
  top: 0; }

.rsContainer {
  position: relative;
  width: 100%;
  height: 100%;
  -webkit-tap-highlight-color: transparent; }

.rsArrow,
.rsThumbsArrow {
  cursor: pointer; }

.rsThumb {
  float: left;
  position: relative; }

.rsArrow,
.rsNav,
.rsThumbsArrow {
  opacity: 1;
  -webkit-transition: opacity 0.3s linear;
  -moz-transition: opacity 0.3s linear;
  -o-transition: opacity 0.3s linear;
  transition: opacity 0.3s linear; }

.rsHidden {
  opacity: 0;
  visibility: hidden;
  -webkit-transition: visibility 0s linear 0.3s,opacity 0.3s linear;
  -moz-transition: visibility 0s linear 0.3s,opacity 0.3s linear;
  -o-transition: visibility 0s linear 0.3s,opacity 0.3s linear;
  transition: visibility 0s linear 0.3s,opacity 0.3s linear; }

.rsGCaption {
  width: 100%;
  float: left;
  text-align: center; }

/* Fullscreen options, very important ^^ */
.royalSlider.rsFullscreen {
  position: fixed !important;
  height: auto !important;
  width: auto !important;
  margin: 0 !important;
  padding: 0 !important;
  z-index: 2147483647 !important;
  top: 0 !important;
  left: 0 !important;
  bottom: 0 !important;
  right: 0 !important; }

.royalSlider .rsSlide.rsFakePreloader {
  opacity: 1 !important;
  -webkit-transition: 0s;
  -moz-transition: 0s;
  -o-transition: 0s;
  transition: 0s;
  display: none; }

.rsSlide {
  position: absolute;
  left: 0;
  top: 0;
  display: block;
  overflow: hidden;
  text-align: center;
  height: 100%;
  width: 100%; }

.royalSlider.rsAutoHeight,
.rsAutoHeight .rsSlide {
  height: auto; }

.rsContent {
  width: 100%;
  height: 100%;
  position: relative; }

.rsPreloader {
  position: absolute;
  z-index: 0; }

.rsNav {
  -moz-user-select: -moz-none;
  -webkit-user-select: none;
  user-select: none; }

.rsNavItem {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0.25); }

.rsThumbs {
  cursor: pointer;
  position: relative;
  overflow: hidden;
  float: left;
  z-index: 2; }

.rsTabs {
  float: left;
  background: none !important; }

.rsTabs,
.rsThumbs {
  -webkit-tap-highlight-color: transparent;
  -webkit-tap-highlight-color: transparent; }

.rsVideoContainer {
  width: auto;
  height: auto;
  line-height: 0;
  position: relative; }

.rsVideoFrameHolder {
  position: absolute;
  left: 0;
  top: 0;
  background: #141414;
  opacity: 0;
  -webkit-transition: .3s; }

.rsVideoFrameHolder.rsVideoActive {
  opacity: 1; }

.rsVideoContainer iframe,
.rsVideoContainer video,
.rsVideoContainer embed,
.rsVideoContainer .rsVideoObj {
  position: absolute;
  z-index: 50;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%; }

/* ios controls over video bug, shifting video */
.rsVideoContainer.rsIOSVideo iframe,
.rsVideoContainer.rsIOSVideo video,
.rsVideoContainer.rsIOSVideo embed {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding-right: 44px; }

.rsABlock {
  left: 0;
  top: 0;
  position: absolute;
  z-index: 15; }

img.rsImg {
  max-width: none; }

.grab-cursor {
  cursor: url(grab.png) 8 8, move; }

.grabbing-cursor {
  cursor: url(grabbing.png) 8 8, move; }

.rsNoDrag {
  cursor: auto; }

.rsLink {
  left: 0;
  top: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  display: block;
  z-index: 20;
  background: url(blank.gif); }

/******************************
*
*  RoyalSlider Universal Skin
*
*    1. Arrows
*    2. Bullets
*    3. Thumbnails
*    4. Tabs
*    5. Fullscreen button
*    6. Play/close video button
*    7. Preloader
*    8. Caption
*
*  Sprite: '../images/royal-slider-uni-theme.png'
*  Feel free to edit anything
*  If you don't some part - just delete it
*
******************************/
/* Background */
.rsUni,
.rsUni .rsOverflow,
.rsUni .rsSlide,
.rsUni .rsVideoFrameHolder,
.rsUni .rsThumbs {
  background: #fff;
  color: #FFF; }

/***************
*
*  1. Arrows
*
****************/
.rsUni .rsArrow {
  height: 100%;
  width: 60px;
  position: absolute;
  display: block;
  cursor: pointer;
  z-index: 1; }

.rsUni.rsVer .rsArrow {
  width: 100%;
  height: 60px; }

.rsUni.rsVer .rsArrowLeft {
  top: 0;
  left: 0; }

.rsUni.rsVer .rsArrowRight {
  bottom: 0;
  left: 0; }

.rsUni.rsHor .rsArrowLeft {
  left: 0;
  top: 0; }

.rsUni.rsHor .rsArrowRight {
  right: 0;
  top: 0; }

.rsUni .rsArrowIcn {
  width: 32px;
  height: 32px;
  top: 50%;
  left: 50%;
  margin-top: -16px;
  margin-left: -16px;
  position: absolute;
  cursor: pointer;
  background: url("../images/royal-slider-uni-theme.png");
  opacity: 0.5;
  border-radius: 2px; }

.rsUni .rsArrow:hover .rsArrowIcn {
  opacity: 1; }

.rsUni.rsHor .rsArrowLeft .rsArrowIcn {
  background-position: -64px -32px; }

.rsUni.rsHor .rsArrowRight .rsArrowIcn {
  background-position: -64px -64px; }

.rsUni.rsVer .rsArrowLeft .rsArrowIcn {
  background-position: -96px -32px; }

.rsUni.rsVer .rsArrowRight .rsArrowIcn {
  background-position: -96px -64px; }

.rsUni .rsArrowDisabled .rsArrowIcn {
  opacity: .1 !important;
  filter: alpha(opacity=20);
  *display: none; }

/***************
*
*  2. Bullets
*
****************/
.rsUni .rsBullets {
  position: relative;
  z-index: 2;
  padding-top: 4px;
  width: 100%;
  height: auto;
  margin: 0 auto;
  text-align: center;
  line-height: 5px;
  overflow: hidden; }

.rsUni .rsBullet {
  width: 8px;
  height: 8px;
  display: inline-block;
  *display: inline;
  *zoom: 1;
  padding: 5px; }

.rsUni .rsBullet span {
  display: block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #BBB; }

.rsUni .rsBullet.rsNavSelected span {
  background-color: #383838; }

/***************
*
*  3. Thumbnails
*
****************/
.rsUni .rsThumbsHor {
  width: 100%;
  height: 72px; }

.rsUni .rsThumbsVer {
  width: 96px;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0; }

.rsUni.rsWithThumbsHor .rsThumbsContainer {
  position: relative;
  height: 100%; }

.rsUni.rsWithThumbsVer .rsThumbsContainer {
  position: relative;
  width: 100%; }

.rsUni .rsThumb {
  float: left;
  overflow: hidden;
  width: 96px;
  height: 72px; }

.rsUni .rsThumb img {
  width: 100%; }

.rsUni .rsThumb.rsNavSelected {
  background: #333; }

.rsUni .rsThumb.rsNavSelected img {
  opacity: 0.7;
  filter: alpha(opacity=30); }

.rsUni .rsThumb.rsNavSelected .thumbIco {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  border: 2px solid #FFF;
  border: 2px solid rgba(255, 255, 255, 0.9);
  -webkit-backface-visibility: hidden; }

.rsUni .rsTmb {
  display: block; }

/* Thumbnails with text */
.rsUni .rsTmb h5 {
  font-size: 16px;
  margin: 0;
  padding: 0;
  line-height: 20px;
  color: #FFF; }

.rsUni .rsTmb span {
  color: #DDD;
  margin: 0;
  padding: 0;
  font-size: 13px;
  line-height: 18px; }

/* Thumbnails arrow icons */
.rsUni .rsThumbsArrow {
  height: 100%;
  width: 20px;
  position: absolute;
  display: block;
  cursor: pointer;
  z-index: 1; }

.rsUni.rsWithThumbsVer .rsThumbsArrow {
  width: 100%;
  height: 20px; }

.rsUni.rsWithThumbsVer .rsThumbsArrowLeft {
  top: 0;
  left: 0; }

.rsUni.rsWithThumbsVer .rsThumbsArrowRight {
  bottom: 0;
  left: 0; }

.rsUni.rsWithThumbsHor .rsThumbsArrowLeft {
  left: 0;
  top: 0; }

.rsUni.rsWithThumbsHor .rsThumbsArrowRight {
  right: 0;
  top: 0; }

.rsUni .rsThumbsArrowIcn {
  width: 16px;
  height: 16px;
  top: 50%;
  left: 50%;
  margin-top: -8px;
  margin-left: -8px;
  position: absolute;
  cursor: pointer;
  background: url("../stylesheets/royalslider/rs-default-inverted.png") transparent; }

.rsUni.rsWithThumbsHor .rsThumbsArrowLeft .rsThumbsArrowIcn {
  background-position: -128px -32px; }

.rsUni.rsWithThumbsHor .rsThumbsArrowRight .rsThumbsArrowIcn {
  background-position: -128px -48px; }

.rsUni.rsWithThumbsVer .rsThumbsArrowLeft .rsThumbsArrowIcn {
  background-position: -144px -32px; }

.rsUni.rsWithThumbsVer .rsThumbsArrowRight .rsThumbsArrowIcn {
  background-position: -144px -48px; }

.rsUni .rsThumbsArrowDisabled {
  display: none !important; }

/* Thumbnails resizing on smaller screens */
@media screen and (min-width: 0px) and (max-width: 800px) {
  .rsUni .rsThumb {
    width: 59px;
    height: 44px; }
  .rsUni .rsThumbsHor {
    height: 44px; }
  .rsUni .rsThumbsVer {
    width: 59px; } }

/***************
*
*  4. Tabs
*
****************/
.rsUni .rsTabs {
  width: 100%;
  height: auto;
  margin: 0 auto;
  text-align: center;
  overflow: hidden;
  padding-top: 12px;
  position: relative; }

.rsUni .rsTab {
  display: inline-block;
  cursor: pointer;
  text-align: center;
  height: auto;
  width: auto;
  color: #333;
  padding: 5px 13px 6px;
  min-width: 72px;
  border: 1px solid #D9D9DD;
  text-decoration: none;
  background: #FFF;
  border-right: none;
  *display: inline;
  *zoom: 1;
  *border-right: 1px solid #d9d9d9; }

.rsUni .rsTab:first-child {
  border-top-left-radius: 16px;
  border-bottom-left-radius: 16px; }

.rsUni .rsTab:last-child {
  border-top-right-radius: 16px;
  border-bottom-right-radius: 16px;
  border-right: 1px solid #D9D9DD; }

.rsUni .rsTab:active {
  background-color: #f4f4f4; }

.rsUni .rsTab.rsNavSelected {
  color: #FFF;
  background: #383838;
  border-color: #383838; }

/***************
*
*  5. Fullscreen button
*
****************/
.rsUni .rsFullscreenBtn {
  right: 0;
  top: 0;
  width: 44px;
  height: 44px;
  z-index: 2;
  display: block;
  position: absolute;
  cursor: pointer; }

.rsUni .rsFullscreenIcn {
  display: block;
  margin: 6px;
  width: 32px;
  height: 32px;
  background: url("../stylesheets/royalslider/rs-default-inverted.png") 0 0 transparent;
  opacity: 0.7;
  border-radius: 2px; }

.rsUni .rsFullscreenBtn:hover .rsFullscreenIcn {
  opacity: 1; }

.rsUni.rsFullscreen .rsFullscreenIcn {
  background-position: -32px 0; }

/***************
*
*  6. Play/close video button
*
****************/
.rsUni .rsPlayBtn {
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0.3);
  width: 64px;
  height: 64px;
  margin-left: -32px;
  margin-top: -32px;
  cursor: pointer; }

.rsUni .rsPlayBtnIcon {
  width: 64px;
  display: block;
  height: 64px;
  -webkit-border-radius: 4px;
  border-radius: 4px;
  -webkit-transition: .3s;
  -moz-transition: .3s;
  transition: .3s;
  background: url("../stylesheets/royalslider/rs-default-inverted.png") no-repeat 0 -32px;
  background-color: #383838;
  background-color: rgba(255, 255, 255, 0.75);
  *background-color: #383838; }

.rsUni .rsPlayBtn:hover .rsPlayBtnIcon {
  background-color: rgba(255, 255, 255, 0.9); }

.rsUni .rsBtnCenterer {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 20px;
  height: 20px; }

.rsUni .rsCloseVideoBtn {
  right: 0;
  top: 0;
  width: 44px;
  height: 44px;
  z-index: 50;
  position: absolute;
  cursor: pointer;
  -webkit-backface-visibility: hidden;
  -webkit-transform: translateZ(0); }

.rsUni .rsCloseVideoBtn.rsiOSBtn {
  top: -38px;
  right: -6px; }

.rsUni .rsCloseVideoIcn {
  margin: 6px;
  width: 32px;
  height: 32px;
  background: url("../stylesheets/royalslider/rs-default-inverted.png") -64px 0;
  background-color: #383838;
  background-color: rgba(255, 255, 255, 0.75);
  *background-color: #383838; }

.rsUni .rsCloseVideoIcn:hover {
  background-color: rgba(255, 255, 255, 0.9); }

/***************
*
*  7. Preloader
*
****************/
.rsUni .rsPreloader {
  width: 20px;
  height: 20px;
  background-image: url("../stylesheets/royalslider/preloader-white.gif");
  left: 50%;
  top: 50%;
  margin-left: -10px;
  margin-top: -10px; }

/***************
*
*  8. Global caption
*
****************/
.rsUni .rsGCaption {
  position: absolute;
  float: none;
  bottom: 6px;
  left: 6px;
  text-align: left;
  background: #222;
  color: #FFF;
  padding: 2px 8px;
  width: auto;
  font-size: 12px;
  border-radius: 2px; }

.page {
  -webkit-transition: padding-top 200ms ease;
  -webkit-transition-delay: 300ms;
  -moz-transition: padding-top 200ms ease 300ms;
  -o-transition: padding-top 200ms ease 300ms;
  transition: padding-top 200ms ease 300ms;
  padding-top: 60px; }
  body.has-global-message .page {
    padding-top: 145px;
    -webkit-transition: none;
    -moz-transition: none;
    -o-transition: none;
    transition: none; }
  @media (min-width: 30.0625em) {
    .page {
      padding-top: 115px; }
      body.has-global-message .page {
        padding-top: 198px; } }
  .lt-ie9 .page {
    padding-top: 115px; }
    body.has-global-message .lt-ie9 .page {
      padding-top: 198px; }
  @media (min-width: 48.0625em) {
    .page {
      padding-top: 165px; }
      body.has-global-message .page {
        padding-top: 207px; } }
  .lt-ie9 .page {
    padding-top: 165px; }
    body.has-global-message .lt-ie9 .page {
      padding-top: 207px; }
  @media (min-width: 73.5em) {
    .page {
      padding-top: 115px; }
      body.has-global-message .page {
        padding-top: 167px; } }
  @media (min-width: 1175px) and (max-width: 1380px) {
    .page {
      padding-top: 165px; } }

@media (min-width: 48.0625em) {
  .main--has-aside, .main--checkout {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    width: 50%;
    float: left; } }

.lt-ie9 .main--has-aside, .lt-ie9 .main--checkout {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  width: 50%;
  float: left; }

@media (min-width: 73.5em) {
  .main--has-aside, .main--checkout {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    width: 66.6666666667%;
    float: left; } }

@media (min-width: 73.5em) {
  .main--checkout {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    width: 58.3333333333%;
    float: left; } }

@media (min-width: 48.0625em) {
  .aside, .aside--checkout {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    width: 50%;
    float: left;
    padding-left: 2.5510204082%; } }

.lt-ie9 .aside, .lt-ie9 .aside--checkout {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  width: 50%;
  float: left;
  padding-left: 2.5510204082%; }

@media (min-width: 73.5em) {
  .aside, .aside--checkout {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    width: 33.3333333333%;
    float: left; } }

@media (min-width: 48.0625em) {
  .aside--checkout {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    width: 41.6666666667%;
    float: right; } }

.lt-ie9 .aside--checkout {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  width: 41.6666666667%;
  float: right; }

.aside .basket, .aside--checkout .basket {
  margin: 0; }
  @media (min-width: 48.0625em) {
    .aside .basket, .aside--checkout .basket {
      margin-top: 20px; } }
  .lt-ie9 .aside .basket, .lt-ie9 .aside--checkout .basket {
    margin-top: 20px; }

.main--has-aside, .main--checkout, .aside--checkout {
  margin-bottom: 5%; }
  @media (min-width: 48.0625em) {
    .main--has-aside, .main--checkout, .aside--checkout {
      margin-bottom: 4%; } }
  .lt-ie9 .main--has-aside, .lt-ie9 .main--checkout, .lt-ie9 .aside--checkout {
    margin-bottom: 4%; }

.section, .errors, .image-block, .image-block--left, .image-block--right, .checkout__gls, .blog-post {
  margin: 5% 0; }
  @media (min-width: 48.0625em) {
    .section, .errors, .image-block, .image-block--left, .image-block--right, .checkout__gls, .blog-post {
      margin: 4% 0; } }
  .lt-ie9 .section, .lt-ie9 .errors, .lt-ie9 .image-block, .lt-ie9 .image-block--left, .lt-ie9 .image-block--right, .lt-ie9 .checkout__gls, .lt-ie9 .blog-post {
    margin: 4% 0; }
  .section--dark, .section--highlight, .section--campaign, .errors {
    border-bottom: 1px solid transparent;
    border-top: 1px solid transparent;
    clear: both;
    padding: 5% 0; }
    @media (min-width: 48.0625em) {
      .section--dark, .section--highlight, .section--campaign, .errors {
        padding: 2.5% 0; } }
    .lt-ie9 .section--dark, .lt-ie9 .section--highlight, .lt-ie9 .section--campaign, .lt-ie9 .errors {
      padding: 2.5% 0; }
  @media (min-width: 48.0625em) {
    .section--limit {
      margin: 30px 0; } }
  .lt-ie9 .section--limit {
    margin: 30px 0; }
  .section--dark {
    border-color: #2f4054; }
  .section--highlight {
    border-color: #eaeaea; }
  .section--campaign {
    border: none; }
    @media (min-width: 48.0625em) {
      .section--campaign {
        padding: 4% 0; } }
    .lt-ie9 .section--campaign {
      padding: 4% 0; }
  @media (min-width: 25em) {
    .section__header--center {
      text-align: center; } }
  .lt-ie9 .section__header--center {
    text-align: center; }
  @media (min-width: 48.0625em) {
    .section__header--center {
      margin: 0 auto;
      width: 83.3333333333%; } }
  .lt-ie9 .section__header--center {
    margin: 0 auto;
    width: 83.3333333333%; }
  @media (min-width: 73.5em) {
    .section__header--center {
      width: 66.6666666667%; } }
  .section__header h1, .section__header--center h1 {
    margin-top: 0; }
  .section__header-subject + h1,
  .section__header-subject + h2,
  .section__header-subject + h3 {
    margin-top: 0; }
  .section__text {
    margin: auto; }
    @media (min-width: 48.0625em) {
      .section__text {
        max-width: 60em; } }
    .lt-ie9 .section__text {
      max-width: 60em; }
    .section__text .button--small, .section__text .permission-bar__button, .section__text .button--primary--small, .section__text .button--secondary--small, .section__text .checkout__basket-anchor, .section__text .button--tertiary--small, .section__text .button, .section__text .checkout__submit, .section__text .checkout__submit--us, .section__text .discount__submit, .section__text .button--primary, .section__text .button--secondary, .section__text .button--tertiary, .section__text .button--buy {
      margin-top: 1.25em; }

@media (min-width: 48.0625em) {
  .container--center {
    text-align: center; } }

.lt-ie9 .container--center {
  text-align: center; }

.divider {
  border: none;
  border-top: 1px solid #dbdbdb;
  margin: 0; }

@media (min-width: 48.0625em) {
  .text--split {
    -webkit-columns: 2;
    -moz-columns: 2;
    -ms-columns: 2;
    -o-columns: 2;
    columns: 2;
    -webkit-column-gap: 30px;
    -moz-column-gap: 30px;
    -ms-column-gap: 30px;
    -o-column-gap: 30px;
    column-gap: 30px; }
    .text--split p:first-child {
      margin-top: 0; } }

.lt-ie9 .text--split {
  -webkit-columns: 2;
  -moz-columns: 2;
  -ms-columns: 2;
  -o-columns: 2;
  columns: 2;
  -webkit-column-gap: 30px;
  -moz-column-gap: 30px;
  -ms-column-gap: 30px;
  -o-column-gap: 30px;
  column-gap: 30px; }
  .lt-ie9 .text--split p:first-child {
    margin-top: 0; }

.text--center {
  text-align: center; }

@media (min-width: 48.0625em) {
  .textcol, .textcol--first {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    width: 50%;
    float: left;
    padding-left: 1.2755102041%; } }

.lt-ie9 .textcol, .lt-ie9 .textcol--first {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  width: 50%;
  float: left;
  padding-left: 1.2755102041%; }

@media (min-width: 48.0625em) {
  .textcol--first {
    padding-left: 0;
    padding-right: 1.2755102041%; } }

.lt-ie9 .textcol--first {
  padding-left: 0;
  padding-right: 1.2755102041%; }

.errors {
  background-color: #F7AFAF;
  border-color: #d62c2d;
  color: #272324; }
  .errors__header {
    margin-top: 0; }
  .errors__list {
    margin-bottom: 0; }

.raptor-container {
  position: relative;
  max-height: 2000px;
  overflow: hidden;
  transition: max-height 1.8s ease-in-out; }
  .raptor-container.is-loading {
    max-height: 32px;
    min-height: 32px; }
    .raptor-container.is-loading:after {
      content: '';
      background: url("../images/loader.gif") no-repeat;
      position: absolute;
      top: 50%;
      left: 50%;
      width: 32px;
      height: 32px;
      margin: -16px 0 0 -16px; }

.nav__item--has-children > .nav__list {
  max-height: 0;
  overflow: hidden; }
  .nav__item--has-children > .active.nav__list {
    max-height: none; }

@media (max-width: 30em) {
  .no-js#scanpan:target body,
  .no-js body:target, body.show-menu, body.show-basket {
    overflow: hidden;
    position: fixed;
    top: 0; } }

.page-overlay {
  background-color: rgba(0, 0, 0, 0.5);
  display: none;
  height: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  z-index: 3;
  width: 100%; }
  .no-js#scanpan:target .page-overlay,
  .no-js #shop:target .page-overlay,
  .show-menu .page-overlay,
  .show-basket .page-overlay {
    display: block; }
  @media (min-width: 30.0625em) {
    .page-overlay .no-js#scanpan:target,
    .show-menu .page-overlay {
      display: none; } }
  .lt-ie9 .page-overlay .no-js#scanpan:target,
  .show-menu .lt-ie9 .page-overlay {
    display: none; }

@media (min-width: 30.0625em) {
  .nav {
    text-align: center; } }

.lt-ie9 .nav {
  text-align: center; }

@media (min-width: 30.0625em) {
  .nav__list .nav__list {
    background-color: #373334;
    min-width: 256px;
    position: absolute;
    text-align: left; }
    .nav__list .nav__list .nav__item, .nav__list .nav__list .nav__item--has-children {
      border-top: 1px solid #404040; }
      .nav__list .nav__list .nav__item:first-child, .nav__list .nav__list .nav__item--has-children:first-child {
        border-top: none; } }

.lt-ie9 .nav__list .nav__list {
  background-color: #373334;
  min-width: 256px;
  position: absolute;
  text-align: left; }
  .lt-ie9 .nav__list .nav__list .nav__item, .lt-ie9 .nav__list .nav__list .nav__item--has-children {
    border-top: 1px solid #404040; }
    .lt-ie9 .nav__list .nav__list .nav__item:first-child, .lt-ie9 .nav__list .nav__list .nav__item--has-children:first-child {
      border-top: none; }

@media (max-width: 30em) {
  .nav__item.active > .nav__list, .active.nav__item--has-children > .nav__list {
    display: block; } }

@media (min-width: 30.0625em) {
  .nav__list {
    display: inline-block; }
    .no-js .nav__item:hover > .nav__list, .no-js .nav__item--has-children:hover > .nav__list,
    .nav__item.hover > .nav__list, .hover.nav__item--has-children > .nav__list {
      display: block; } }

.lt-ie9 .nav__list {
  display: inline-block; }
  .no-js .nav__item:hover > .lt-ie9 .nav__list, .no-js .nav__item--has-children:hover > .lt-ie9 .nav__list,
  .nav__item.hover > .lt-ie9 .nav__list, .hover.nav__item--has-children > .lt-ie9 .nav__list {
    display: block; }

@media (min-width: 30.0625em) {
  .nav__item, .nav__item--has-children {
    float: left; }
    .nav__item .nav__item, .nav__item--has-children .nav__item, .nav__item .nav__item--has-children, .nav__item--has-children .nav__item--has-children {
      float: none; }
    .nav__item:last-child, .nav__item--has-children:last-child {
      position: absolute;
      right: 0; } }

.lt-ie9 .nav__item, .lt-ie9 .nav__item--has-children {
  float: left; }
  .lt-ie9 .nav__item .lt-ie9 .nav__item, .lt-ie9 .nav__item--has-children .lt-ie9 .nav__item, .lt-ie9 .nav__item .lt-ie9 .nav__item--has-children, .lt-ie9 .nav__item--has-children .lt-ie9 .nav__item--has-children {
    float: none; }
  .lt-ie9 .nav__item:last-child, .lt-ie9 .nav__item--has-children:last-child {
    position: absolute;
    right: 0; }

@media (min-width: 30.0625em) {
  .nav__item--has-children > .nav__list {
    display: none;
    overflow: visible;
    max-height: none !important; } }

.lt-ie9 .nav__item--has-children > .nav__list {
  display: none;
  overflow: visible;
  max-height: none !important; }

.nav__link {
  display: block;
  font-weight: 300;
  padding: 15px;
  width: 100%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }
  .nav__link, .nav__link:hover, .nav__link:focus, .nav__link:active {
    color: #fff;
    text-decoration: none; }
  @media (min-width: 30.0625em) {
    .nav__link {
      display: inline-block; }
      .no-js .nav__item--has-children:hover > .nav__link,
      .nav__item--has-children.hover > .nav__link {
        background-color: #373334;
        color: #af9b56; }
        .no-js .nav__item--has-children:hover > .nav__link:after,
        .nav__item--has-children.hover > .nav__link:after {
          color: inherit; } }
  .lt-ie9 .nav__link {
    display: inline-block; }
    .no-js .nav__item--has-children:hover > .lt-ie9 .nav__link,
    .nav__item--has-children.hover > .lt-ie9 .nav__link {
      background-color: #373334;
      color: #af9b56; }
      .no-js .nav__item--has-children:hover > .lt-ie9 .nav__link:after,
      .nav__item--has-children.hover > .lt-ie9 .nav__link:after {
        color: inherit; }
  .nav__item--has-children > .nav__link {
    position: relative; }
    .nav__item--has-children > .nav__link:hover:after {
      color: inherit; }
    .nav__item--has-children > .nav__link:after {
      color: #999;
      float: right;
      margin-top: -6px;
      position: absolute;
      right: 10px;
      top: 50%;
      font-size: 11px;
      font-size: 1.1rem;
      content: "";
      font-family: "icons";
      font-style: normal;
      font-variant: normal;
      font-weight: normal;
      height: 1em;
      line-height: 1em;
      speak: none;
      text-indent: 0;
      text-transform: none;
      -moz-osx-font-smoothing: grayscale;
      -webkit-font-smoothing: antialiased; }
    .nav__item--has-children > .nav__link.active:after {
      -webkit-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -ms-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg); }
  @media (max-width: 30em) {
    .nav__link {
      border-bottom: 1px solid #404040;
      display: block;
      padding: 0.8em 8%;
      font-size: 16px;
      font-size: 1.6rem; }
      .nav__item--has-children .nav__list .nav__link {
        color: #999;
        padding: 0.8em 12%; } }
  @media (min-width: 30.0625em) {
    .nav__link:hover, .nav__link:focus, .nav__link:active {
      background-color: #373334;
      color: #af9b56; }
      .nav__link:hover:after, .nav__link:focus:after, .nav__link:active:after {
        color: inherit; }
    .nav__item.active > .nav__link, .active.nav__item--has-children > .nav__link {
      color: #af9b56; }
      .nav__item.active > .nav__link.highlight--secondary, .active.nav__item--has-children > .nav__link.highlight--secondary {
        color: #272324; } }
  .lt-ie9 .nav__link:hover, .lt-ie9 .nav__link:focus, .lt-ie9 .nav__link:active {
    background-color: #373334;
    color: #af9b56; }
    .lt-ie9 .nav__link:hover:after, .lt-ie9 .nav__link:focus:after, .lt-ie9 .nav__link:active:after {
      color: inherit; }
  .nav__item.active > .lt-ie9 .nav__link, .active.nav__item--has-children > .lt-ie9 .nav__link {
    color: #af9b56; }
    .nav__item.active > .lt-ie9 .nav__link.highlight--secondary, .active.nav__item--has-children > .lt-ie9 .nav__link.highlight--secondary {
      color: #272324; }
  @media (min-width: 48.0625em) {
    .nav__link {
      padding: 19px 23px;
      font-size: 16px;
      font-size: 1.6rem; }
      .nav__item--has-children .nav__list .nav__link {
        font-size: 16px;
        font-size: 1.6rem; }
      .nav__link:after {
        position: relative;
        top: -2px; } }
  .lt-ie9 .nav__link {
    padding: 19px 23px;
    font-size: 16px;
    font-size: 1.6rem; }
    .nav__item--has-children .nav__list .lt-ie9 .nav__link {
      font-size: 16px;
      font-size: 1.6rem; }
    .lt-ie9 .nav__link:after {
      position: relative;
      top: -2px; }

.nav__highlight--senary {
  color: #272324;
  background-color: #fcdf7a; }

.header-nav {
  box-sizing: border-box;
  display: none;
  position: relative; }
  @media (min-width: 30.0625em) {
    .header-nav {
      display: block;
      text-align: center; } }
  .lt-ie9 .header-nav {
    display: block;
    text-align: center; }

.header-nav__list {
  padding: 0 0 0 20px;
  margin: 0; }
  @media (min-width: 73.5em) {
    .header-nav__list {
      padding: 0;
      display: inline-block; } }
  .header-nav__list.has-level-1 {
    float: left;
    margin: 60px 0;
    width: 100%; }
  .header-nav__list.has-level-2 {
    margin: 0;
    width: 100%; }

.header-nav__container {
  position: relative; }

.header-nav__item {
  float: left;
  margin-left: 20px; }
  @media (min-width: 73.5em) {
    .header-nav__item.last-child {
      position: absolute;
      right: 0; }
      .header-nav__item.last-child .header-nav__link {
        padding-right: 0; }
        .header-nav__item.last-child .header-nav__link:after {
          padding: 0;
          left: 0; } }
  @media (max-width: 1380px) {
    .header-nav__item.last-child {
      position: relative; } }
  .has-column-count-4 .header-nav__item {
    float: left;
    width: 25%; }
    @media (min-width: 30.0625em) {
      .has-column-count-4 .header-nav__item:last-child {
        position: relative; } }
    .lt-ie9 .has-column-count-4 .header-nav__item:last-child {
      position: relative; }
    @media (min-width: 48.0625em) {
      .has-column-count-4 .header-nav__item {
        width: 25%; }
        .has-column-count-4 .header-nav__item:nth-child(5n+1) {
          clear: none; }
        .has-column-count-4 .header-nav__item:nth-child(3n+1) {
          clear: none; }
        .has-column-count-4 .header-nav__item:nth-child(4n+1) {
          clear: left; } }
    .lt-ie9 .has-column-count-4 .header-nav__item {
      width: 25%; }
      .lt-ie9 .has-column-count-4 .header-nav__item:nth-child(5n+1) {
        clear: none; }
      .lt-ie9 .has-column-count-4 .header-nav__item:nth-child(3n+1) {
        clear: none; }
      .lt-ie9 .has-column-count-4 .header-nav__item:nth-child(4n+1) {
        clear: left; }
  .has-level-1 > .header-nav__item {
    float: left;
    margin: 0 0 30px;
    position: relative; }
    @media (min-width: 48.0625em) {
      .has-level-1 > .header-nav__item:nth-child(-n+2)::after {
        background-color: #e3e3e3;
        content: "";
        height: 110%;
        position: absolute;
        right: 20%;
        top: 0;
        width: 1px; } }
    .lt-ie9 .has-level-1 > .header-nav__item:nth-child(-n+2)::after {
      background-color: #e3e3e3;
      content: "";
      height: 110%;
      position: absolute;
      right: 20%;
      top: 0;
      width: 1px; }
  .has-level-2 .header-nav__item {
    float: none;
    margin: 0;
    width: 75%; }
    .has-level-2 .header-nav__item:first-child .header-nav__link {
      margin: 0; }
  .header-nav__item:first-child {
    margin-left: 0; }
    .header-nav__item:first-child .header-nav__link {
      padding-left: 0; }
      .header-nav__item:first-child .header-nav__link.header-nav__highlight--secondary, .header-nav__item:first-child .header-nav__link.header-nav__highlight--senary {
        padding-left: 10px; }

.tablet .header-nav > .header-nav__list > .header-nav__item.has-children:not(.last-child) > span {
  padding: 0 0.7em;
  position: relative; }
  .tablet .header-nav > .header-nav__list > .header-nav__item.has-children:not(.last-child) > span::after {
    content: "";
    font-family: "icons";
    font-style: normal;
    font-variant: normal;
    font-weight: normal;
    height: 1em;
    line-height: 1em;
    speak: none;
    text-indent: 0;
    text-transform: none;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    line-height: 67px;
    color: #fff;
    font-size: 9px;
    position: absolute; }

.tablet .header-nav > .header-nav__list > .header-nav__item.is-active.has-children:not(.last-child) > span::after {
  transform: rotate(180deg);
  top: 35px; }

.header-nav__top-level {
  position: absolute;
  display: none;
  top: 22px; }
  .header-nav__top-level.is-active {
    display: block; }
  .header-nav__top-level .header-nav__top-level--link {
    text-decoration: none;
    font-family: "Open Sans", sans-serif;
    font-size: 10px;
    border: 1px solid #000;
    padding: 5px 10px;
    border-radius: 5px; }

.header-nav__link {
  color: #fff;
  display: block;
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  text-decoration: none;
  padding: 20px 10px;
  position: relative;
  z-index: 1;
  display: inline-block; }
  .is-active > .header-nav__link::after {
    border-bottom: 7px solid #AE9C57;
    content: "";
    padding: 0 6px;
    position: absolute;
    left: -11px;
    bottom: 0;
    width: 100%; }
  .is-active > .header-nav__link, .header-nav__link.is-active, .header-nav__link.is-inpath {
    color: white; }
  .is-current > .header-nav__link,
  .is-inpath > .header-nav__link {
    color: #fff; }
  .header-nav__link:hover, .header-nav__link:focus, .header-nav__link:active {
    color: #fff;
    text-decoration: none; }
  .has-level-1 .header-nav__link {
    font-size: 21px;
    height: auto;
    line-height: normal;
    margin-bottom: 30px;
    padding: 0;
    color: #000; }
    .has-level-1 .header-nav__link:hover, .has-level-1 .header-nav__link:focus, .has-level-1 .header-nav__link:active {
      color: #000;
      text-decoration: none; }
    .has-level-1 .header-nav__link::after {
      display: none; }
  .has-level-2 .header-nav__link {
    color: #666666;
    font-size: 16px;
    margin: 10px 0 0; }
    .has-level-2 .header-nav__link::after {
      display: none; }
    .has-level-2 .header-nav__link:hover {
      color: #000; }

.header-nav__highlight--secondary,
.header-nav__highlight--senary {
  color: #272324;
  font-weight: 600; }
  .header-nav__highlight--secondary::after,
  .header-nav__highlight--senary::after {
    border-bottom: none; }

.header-nav__highlight--secondary {
  background-color: #af9b56; }

.header-nav__highlight--senary {
  background-color: #fcdf7a; }

.header-nav__link.header-nav__highlight--secondary:hover,
.header-nav__link.header-nav__highlight--senary:hover {
  color: #272324; }

.header-nav__item.is-active .header-nav__highlight--secondary,
.header-nav__item.is-active .header-nav__highlight--senary {
  color: #272324; }
  .header-nav__item.is-active .header-nav__highlight--secondary::after,
  .header-nav__item.is-active .header-nav__highlight--senary::after {
    border-bottom: none; }

.header-nav__item.is-active .header-nav__highlight--secondary {
  background-color: #b7a567; }

.header-nav__item.is-active .header-nav__highlight--senary {
  background-color: #fde593; }

.nav-dropdown {
  background-color: #fff;
  box-shadow: 0 7px 49px 0 rgba(0, 0, 0, 0.25);
  height: 0;
  min-height: 350px;
  margin: 0 auto;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  left: 0;
  right: 0;
  transition: visibility 0ms linear 250ms, opacity 250ms ease;
  visibility: hidden;
  width: 100%;
  z-index: 9;
  text-align: left; }
  .is-active + .nav-dropdown,
  .is-active > .nav-dropdown {
    height: auto;
    opacity: 1;
    transition: visibility 0 linear, opacity 250ms ease;
    visibility: visible; }
  .is-active + .nav-dropdown {
    display: block; }

.header-nav__cta {
  box-sizing: border-box;
  float: left;
  position: relative;
  transition: opacity 250ms ease;
  z-index: 10;
  margin-top: -30px; }
  .header-nav__cta .splat, .header-nav__cta .splat--secondary, .header-nav__cta .splat--tertiary, .header-nav__cta .splat--large-alt, .header-nav__cta .splat--large, .header-nav__cta .splat--bestseller--large, .header-nav__cta .splat--bit--large, .header-nav__cta .splat--bestseller, .header-nav__cta .splat--bit {
    background: #AE9C57;
    height: auto;
    pointer-events: none;
    position: absolute;
    top: 0;
    right: 0;
    width: 50px;
    height: 44px;
    z-index: 10;
    text-align: center;
    color: #000;
    padding: 18px 15px; }
    .header-nav__cta .splat .from, .header-nav__cta .splat--secondary .from, .header-nav__cta .splat--tertiary .from, .header-nav__cta .splat--large-alt .from, .header-nav__cta .splat--large .from, .header-nav__cta .splat--bestseller--large .from, .header-nav__cta .splat--bit--large .from, .header-nav__cta .splat--bestseller .from, .header-nav__cta .splat--bit .from {
      font-size: 15px;
      font-style: italic;
      font-family: 'Times New Roman', Times, serif;
      line-height: 0.8; }
    .header-nav__cta .splat .price, .header-nav__cta .splat--secondary .price, .header-nav__cta .splat--tertiary .price, .header-nav__cta .splat--large-alt .price, .header-nav__cta .splat--large .price, .header-nav__cta .splat--bestseller--large .price, .header-nav__cta .splat--bit--large .price, .header-nav__cta .splat--bestseller .price, .header-nav__cta .splat--bit .price {
      font-size: 21px;
      font-weight: 600; }
    @media (min-width: 30.0625em) {
      .header-nav__cta .splat, .header-nav__cta .splat--secondary, .header-nav__cta .splat--tertiary, .header-nav__cta .splat--large-alt, .header-nav__cta .splat--large, .header-nav__cta .splat--bestseller--large, .header-nav__cta .splat--bit--large, .header-nav__cta .splat--bestseller, .header-nav__cta .splat--bit {
        transform: scale(0.5, 0.5); } }
    .lt-ie9 .header-nav__cta .splat, .lt-ie9 .header-nav__cta .splat--secondary, .lt-ie9 .header-nav__cta .splat--tertiary, .lt-ie9 .header-nav__cta .splat--large-alt, .lt-ie9 .header-nav__cta .splat--large, .lt-ie9 .header-nav__cta .splat--bestseller--large, .lt-ie9 .header-nav__cta .splat--bit--large, .lt-ie9 .header-nav__cta .splat--bestseller, .lt-ie9 .header-nav__cta .splat--bit {
      transform: scale(0.5, 0.5); }
    @media (min-width: 48.0625em) {
      .header-nav__cta .splat, .header-nav__cta .splat--secondary, .header-nav__cta .splat--tertiary, .header-nav__cta .splat--large-alt, .header-nav__cta .splat--large, .header-nav__cta .splat--bestseller--large, .header-nav__cta .splat--bit--large, .header-nav__cta .splat--bestseller, .header-nav__cta .splat--bit {
        transform: none;
        top: -50px; } }
    .lt-ie9 .header-nav__cta .splat, .lt-ie9 .header-nav__cta .splat--secondary, .lt-ie9 .header-nav__cta .splat--tertiary, .lt-ie9 .header-nav__cta .splat--large-alt, .lt-ie9 .header-nav__cta .splat--large, .lt-ie9 .header-nav__cta .splat--bestseller--large, .lt-ie9 .header-nav__cta .splat--bit--large, .lt-ie9 .header-nav__cta .splat--bestseller, .lt-ie9 .header-nav__cta .splat--bit {
      transform: none;
      top: -50px; }

.header-nav__cta-image {
  max-width: 80%; }

.header-nav__top-button {
  position: fixed;
  left: 50%;
  top: 73px;
  min-width: 225px;
  transform: translateX(-50%);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  background-color: #000;
  padding: 0 10px 11px 30px;
  margin: 0;
  z-index: 10; }
  .header-nav__top-button:hover {
    background-color: #1a1a1a; }
  .header-nav__top-button .icon-next-circle::before {
    height: 26px;
    width: 26px;
    font-size: 26px;
    margin-left: 15px; }
  @media (min-width: 30.0625em) {
    .header-nav__top-button {
      top: 115px; } }
  .lt-ie9 .header-nav__top-button {
    top: 115px; }
  @media (min-width: 48.0625em) {
    .header-nav__top-button {
      position: relative;
      top: auto;
      width: auto;
      background-color: #272324;
      box-shadow: none;
      border-radius: 0;
      display: inline-block;
      padding: 0 20px;
      line-height: 4;
      height: 64px; }
      .header-nav__top-button:hover {
        background-color: #ccb100; }
      .header-nav__top-button .icon-next-circle {
        top: 0.75em; }
        .header-nav__top-button .icon-next-circle::before {
          height: 35px;
          width: 35px;
          font-size: 35px; } }
  .lt-ie9 .header-nav__top-button {
    position: relative;
    top: auto;
    width: auto;
    background-color: #272324;
    box-shadow: none;
    border-radius: 0;
    display: inline-block;
    padding: 0 20px;
    line-height: 4;
    height: 64px; }
    .lt-ie9 .header-nav__top-button:hover {
      background-color: #ccb100; }
    .lt-ie9 .header-nav__top-button .icon-next-circle {
      top: 0.75em; }
      .lt-ie9 .header-nav__top-button .icon-next-circle::before {
        height: 35px;
        width: 35px;
        font-size: 35px; }

.image-block, .image-block--left, .image-block--right {
  position: relative;
  overflow: hidden; }
  .lt-ie9 .image-block__container {
    position: relative;
    max-width: 84em;
    margin-left: auto;
    margin-right: auto;
    padding: 0 10px;
    padding:  0 1rem; }
    .lt-ie9 .image-block__container:after {
      content: " ";
      display: block;
      clear: both; }
    @media (min-width: 30.0625em) {
      .lt-ie9 .image-block__container {
        padding: 0 20px;
        padding:  0 2rem; } }
    .lt-ie9 .lt-ie9 .image-block__container {
      padding: 0 20px;
      padding:  0 2rem; }
  @media (min-width: 73.5em) {
    .image-block__container {
      max-width: 84em;
      margin-left: auto;
      margin-right: auto;
      padding: 0 10px;
      padding:  0 1rem; }
      .image-block__container:after {
        content: " ";
        display: block;
        clear: both; } }
  @media (min-width: 73.5em) and (min-width: 30.0625em) {
    .image-block__container {
      padding: 0 20px;
      padding:  0 2rem; } }
  @media (min-width: 73.5em) {
      .lt-ie9 .image-block__container {
        padding: 0 20px;
        padding:  0 2rem; } }
  .image-block__content {
    padding-bottom: 0; }
    @media (min-width: 62.5em) {
      .image-block__content {
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        width: 50%;
        float: left; } }
    .lt-ie9 .image-block__content {
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
      width: 50%;
      float: left; }
    @media (min-width: 73.5em) {
      .image-block__content {
        padding-left: 0;
        width: 33.3333333333%; } }
    @media (min-width: 1000px) and (max-width: 1580px) {
      .image-block__content {
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        width: 50%;
        float: left;
        min-height: 550px; } }
    @media (min-width: 62.5em) {
      .image-block--left .image-block__content {
        float: right; } }
    .lt-ie9 .image-block--left .image-block__content {
      float: right; }
    @media (min-width: 73.5em) {
      .image-block--left .image-block__content {
        padding-left: 1.875em;
        padding-right: 0; } }
    .image-block__content .button--small, .image-block__content .permission-bar__button, .image-block__content .button--primary--small, .image-block__content .button--secondary--small, .image-block__content .checkout__basket-anchor, .image-block__content .button--tertiary--small, .image-block__content .button, .image-block__content .checkout__submit, .image-block__content .checkout__submit--us, .image-block__content .discount__submit, .image-block__content .button--primary, .image-block__content .button--secondary, .image-block__content .button--tertiary, .image-block__content .button--buy {
      margin-top: 1.25em; }
      @media (min-width: 73.5em) {
        .image-block__content .button--small, .image-block__content .permission-bar__button, .image-block__content .button--primary--small, .image-block__content .button--secondary--small, .image-block__content .checkout__basket-anchor, .image-block__content .button--tertiary--small, .image-block__content .button, .image-block__content .checkout__submit, .image-block__content .checkout__submit--us, .image-block__content .discount__submit, .image-block__content .button--primary, .image-block__content .button--secondary, .image-block__content .button--tertiary, .image-block__content .button--buy {
          margin-top: 2.5em; } }
  .image-block__header, .section__text-header {
    margin-top: 0; }
  .image-block__picture {
    position: relative;
    display: block;
    line-height: 0; }
    @media (min-width: 62.5em) {
      .image-block__picture {
        height: 100%;
        overflow: hidden;
        position: absolute;
        right: 0;
        top: 0;
        z-index: 1;
        width: 50%; } }
    .lt-ie9 .image-block__picture {
      height: 100%;
      overflow: hidden;
      position: absolute;
      right: 0;
      top: 0;
      z-index: 1;
      width: 50%; }
    @media (min-width: 73.5em) {
      .image-block__picture {
        overflow: visible;
        position: relative;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        width: 66.6666666667%;
        float: right; } }
    @media (min-width: 62.5em) {
      .image-block--left .image-block__picture {
        left: 0;
        right: auto; } }
    .lt-ie9 .image-block--left .image-block__picture {
      left: 0;
      right: auto; }
    @media (min-width: 73.5em) {
      .image-block--left .image-block__picture {
        float: left; } }
    @media (min-width: 1000px) and (max-width: 1580px) {
      .image-block__picture {
        height: 100%;
        overflow: hidden;
        position: absolute;
        right: 0;
        top: 0;
        z-index: 1;
        width: 50%; } }
  .image-block__image {
    width: 100%; }
    @media (min-width: 62.5em) {
      .image-block__image {
        height: 100%;
        width: auto; } }
    .lt-ie9 .image-block__image {
      height: 100%;
      width: auto; }
    @media (min-width: 73.5em) {
      .image-block__image {
        height: auto;
        width: 100%; } }
    @media (min-width: 62.5em) {
      .image-block--left .image-block__image {
        float: right; } }
    .lt-ie9 .image-block--left .image-block__image {
      float: right; }
  .image-block .splat, .image-block--left .splat, .image-block--right .splat, .image-block .splat--secondary, .image-block--left .splat--secondary, .image-block--right .splat--secondary, .image-block .splat--tertiary, .image-block--left .splat--tertiary, .image-block--right .splat--tertiary, .image-block .splat--large-alt, .image-block--left .splat--large-alt, .image-block--right .splat--large-alt, .image-block .splat--large, .image-block--left .splat--large, .image-block--right .splat--large, .image-block .splat--bestseller--large, .image-block--left .splat--bestseller--large, .image-block--right .splat--bestseller--large, .image-block .splat--bit--large, .image-block--left .splat--bit--large, .image-block--right .splat--bit--large, .image-block .splat--bestseller, .image-block--left .splat--bestseller, .image-block--right .splat--bestseller, .image-block .splat--bit, .image-block--left .splat--bit, .image-block--right .splat--bit {
    position: absolute;
    left: 0;
    top: 0; }

@media (min-width: 1000px) and (max-width: 1580px) {
  .image-stretch--left .image-block__content,
  .image-stretch--right .image-block__content {
    background-color: #384d64; } }

.checkout__accept-checkbox-container {
  display: inline-block;
  float: left;
  margin-bottom: 18px;
  margin-right: 2em; }
  @media (min-width: 73.5em) {
    .checkout__accept-checkbox-container {
      margin-top: 10px;
      margin-bottom: 0; } }

@media (min-width: 48.0625em) {
  .checkout__basket-anchor {
    display: none; } }

.lt-ie9 .checkout__basket-anchor {
  display: none; }

.checkout__basket-anchor:before {
  font-size: 25px; }

.checkout__shippingmethod {
  display: none; }

.checkout__submit, .checkout__submit--us {
  margin: 0; }
  @media (min-width: 73.5em) {
    .checkout__submit, .checkout__submit--us {
      float: right; } }
  .checkout__submit--us {
    position: relative;
    text-indent: 0;
    -webkit-transition: all 200ms ease-in-out;
    -moz-transition: all 200ms ease-in-out;
    -o-transition: all 200ms ease-in-out;
    transition: all 200ms ease-in-out; }
    .checkout__submit--us:disabled {
      text-indent: -300px; }
      .checkout__submit--us:disabled:before {
        background: transparent url("../images/loader.gif") no-repeat;
        content: '';
        height: 32px;
        position: absolute;
        left: 50%;
        top: 50%;
        width: 32px;
        margin: -16px 0 0 -16px;
        margin:  -1.6rem 0 0 -1.6rem; }

.checkout__payment--is-hidden {
  display: none; }

.checkout__payment-icon {
  vertical-align: middle; }

.checkout__phone {
  display: block;
  font-weight: 300;
  margin: 10px 0;
  margin:  1rem 0; }
  .checkout__phone:before {
    color: #af9b56;
    font-size: 22px;
    margin-right: 12px; }

.checkout__opening {
  margin: 0; }

.checkout__gls {
  margin-top: 0; }

.checkout__gls-results {
  clear: both;
  margin-bottom: 20px;
  margin-bottom: 2rem; }

.checkout__hidden {
  display: none; }
  .checkout__hidden.is-active {
    display: block; }

.discount {
  position: relative; }
  .discount__submit {
    position: absolute;
    top: 0;
    right: 0;
    padding: 14px 40px 10px;
    padding:  1.4rem 4rem 1rem; }
    @media (min-width: 48.0625em) {
      .discount__submit {
        padding: 12px 40px 8px;
        padding:  1.2rem 4rem 0.8rem; } }
    .lt-ie9 .discount__submit {
      padding: 12px 40px 8px;
      padding:  1.2rem 4rem 0.8rem; }

.main--checkout .billing__address-container {
  display: inline-block;
  width: 100%; }

.main--checkout .shippingmethods__method {
  display: block;
  margin: 0;
  padding: 5px 0;
  line-height: 20px; }
  @media (min-width: 73.5em) {
    .main--checkout .shippingmethods__method {
      width: 100%;
      float: left; } }
  .main--checkout .shippingmethods__method input[type="radio"] {
    position: relative;
    top: 2px; }

.main--checkout .shippingmethods__methods {
  overflow: auto; }
  @media (min-width: 73.5em) {
    .main--checkout .shippingmethods__methods {
      display: flex;
      margin: 0 -5px; } }
  .main--checkout .shippingmethods__methods-container {
    width: 100%;
    box-sizing: border-box; }
    @media (min-width: 73.5em) {
      .main--checkout .shippingmethods__methods-container {
        width: 50%;
        padding: 0 5px; } }

.main--checkout .section--highlight .container, .main--checkout .section--highlight .container--center {
  position: relative; }

@media (max-width: 73.4375em) {
  .main--checkout .section--highlight .checkbox-container, .main--checkout .section--highlight .checkout__accept-checkbox-container {
    width: 100%; } }

@media (min-width: 73.5em) {
  .main--checkout .section--highlight .checkout__submit, .main--checkout .section--highlight .checkout__submit--us {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 20px; } }

.main--checkout__field-invalid {
  border-color: red; }

.main--checkout__invalid-text {
  color: red; }
  .main--checkout__invalid-text a {
    color: red; }

.social__item--facebook, .social__item--twitter, .social__item--gplus {
  display: inline-block;
  height: 30px;
  overflow: hidden;
  width: 120px; }

.global-message--primary, .global-message, .global-message--secondary, .global-message--tertiary, .global-message--quaternary, .global-message--quinary, .global-message--senary, .global-message--xmas, .global-message--xmas-alt {
  border-bottom: 1px solid #404040;
  max-height: 0;
  opacity: 0;
  overflow: hidden;
  width: 100%;
  -webkit-transition: opacity 300ms ease, max-height 200ms ease;
  -webkit-transition-delay: 0s, 300ms;
  -moz-transition: opacity 300ms ease, max-height 200ms ease 300ms;
  -o-transition: opacity 300ms ease, max-height 200ms ease 300ms;
  transition: opacity 300ms ease, max-height 200ms ease 300ms;
  /**
   * Rendering theme colors and selectors
   */ }
  .global-message--primary:first-child, .global-message:first-child, .global-message--secondary:first-child, .global-message--tertiary:first-child, .global-message--quaternary:first-child, .global-message--quinary:first-child, .global-message--senary:first-child, .global-message--xmas:first-child, .global-message--xmas-alt:first-child {
    border-top: none; }
  .is-active.global-message--primary, .is-active.global-message, .is-active.global-message--secondary, .is-active.global-message--tertiary, .is-active.global-message--quaternary, .is-active.global-message--quinary, .is-active.global-message--senary, .is-active.global-message--xmas, .is-active.global-message--xmas-alt {
    opacity: 1;
    max-height: 150px;
    -webkit-transition: none;
    -moz-transition: none;
    -o-transition: none;
    transition: none; }
    @media (min-width: 30.0625em) {
      .is-active.global-message--primary, .is-active.global-message, .is-active.global-message--secondary, .is-active.global-message--tertiary, .is-active.global-message--quaternary, .is-active.global-message--quinary, .is-active.global-message--senary, .is-active.global-message--xmas, .is-active.global-message--xmas-alt {
        max-height: 80px; } }
    .lt-ie9 .is-active.global-message--primary, .lt-ie9 .is-active.global-message, .lt-ie9 .is-active.global-message--secondary, .lt-ie9 .is-active.global-message--tertiary, .lt-ie9 .is-active.global-message--quaternary, .lt-ie9 .is-active.global-message--quinary, .lt-ie9 .is-active.global-message--senary, .lt-ie9 .is-active.global-message--xmas, .lt-ie9 .is-active.global-message--xmas-alt {
      max-height: 80px; }
    @media (min-width: 48.0625em) {
      .is-active.global-message--primary, .is-active.global-message, .is-active.global-message--secondary, .is-active.global-message--tertiary, .is-active.global-message--quaternary, .is-active.global-message--quinary, .is-active.global-message--senary, .is-active.global-message--xmas, .is-active.global-message--xmas-alt {
        max-height: 60px; } }
    .lt-ie9 .is-active.global-message--primary, .lt-ie9 .is-active.global-message, .lt-ie9 .is-active.global-message--secondary, .lt-ie9 .is-active.global-message--tertiary, .lt-ie9 .is-active.global-message--quaternary, .lt-ie9 .is-active.global-message--quinary, .lt-ie9 .is-active.global-message--senary, .lt-ie9 .is-active.global-message--xmas, .lt-ie9 .is-active.global-message--xmas-alt {
      max-height: 60px; }
  @media (max-width: 30em) {
    .header .global-message--primary, .header .global-message, .header .global-message--secondary, .header .global-message--tertiary, .header .global-message--quaternary, .header .global-message--quinary, .header .global-message--senary, .header .global-message--xmas, .header .global-message--xmas-alt {
      position: absolute;
      top: 60px; } }
  .global-message, .global-message--primary, .global-message--secondary, .global-message--tertiary, .global-message--quaternary, .global-message--quinary, .global-message--senary, .global-message--xmas, .global-message--xmas-alt {
    background-color: #272324;
    color: #fff; }
    .global-message .global-message__close, .global-message--primary .global-message__close, .global-message--secondary .global-message__close, .global-message--tertiary .global-message__close, .global-message--quaternary .global-message__close, .global-message--quinary .global-message__close, .global-message--senary .global-message__close, .global-message--xmas .global-message__close, .global-message--xmas-alt .global-message__close {
      background-color: #999;
      border-color: #999;
      color: #272324; }
      .global-message .global-message__close:hover, .global-message--primary .global-message__close:hover, .global-message--secondary .global-message__close:hover, .global-message--tertiary .global-message__close:hover, .global-message--quaternary .global-message__close:hover, .global-message--quinary .global-message__close:hover, .global-message--senary .global-message__close:hover, .global-message--xmas .global-message__close:hover, .global-message--xmas-alt .global-message__close:hover {
        background-color: #272324;
        color: #999; }
  .global-message--secondary {
    background-color: #af9b56;
    color: #fff; }
    .global-message--secondary .global-message__close {
      background-color: #736536;
      border-color: #736536;
      color: #af9b56; }
      .global-message--secondary .global-message__close:hover {
        background-color: #af9b56;
        color: #736536; }
  .global-message--tertiary {
    background-color: #384d64;
    color: #fff; }
    .global-message--tertiary .global-message__close {
      background-color: #7894b3;
      border-color: #7894b3;
      color: #384d64; }
      .global-message--tertiary .global-message__close:hover {
        background-color: #384d64;
        color: #7894b3; }
  .global-message--quaternary {
    background-color: #6d9343;
    color: #fff; }
    .global-message--quaternary .global-message__close {
      background-color: #a0c27a;
      border-color: #a0c27a;
      color: #6d9343; }
      .global-message--quaternary .global-message__close:hover {
        background-color: #6d9343;
        color: #a0c27a; }
  .global-message--quinary {
    background-color: #f7f7f7;
    color: #666; }
    .global-message--quinary .global-message__close {
      background-color: #272324;
      border-color: #272324;
      color: #f7f7f7; }
      .global-message--quinary .global-message__close:hover {
        background-color: #f7f7f7;
        color: #272324; }
  .global-message--senary {
    background-color: #fcdf7a;
    color: #666; }
    .global-message--senary .global-message__close {
      background-color: #cea105;
      border-color: #cea105;
      color: #fcdf7a; }
      .global-message--senary .global-message__close:hover {
        background-color: #fcdf7a;
        color: #cea105; }
  .global-message--xmas {
    background-color: #ea4e49;
    color: #fff; }
    .global-message--xmas .global-message__close {
      background-color: #c11c16;
      border-color: #c11c16;
      color: #ea4e49; }
      .global-message--xmas .global-message__close:hover {
        background-color: #ea4e49;
        color: #c11c16; }
  .global-message--xmas-alt {
    background-color: #310041;
    color: #fff; }
    .global-message--xmas-alt .global-message__close {
      background-color: #ba5bd9;
      border-color: #ba5bd9;
      color: #310041; }
      .global-message--xmas-alt .global-message__close:hover {
        background-color: #310041;
        color: #ba5bd9; }
  .global-message__content {
    padding-bottom: 10px;
    padding-top: 8px;
    position: relative; }
  .global-message__text {
    text-align: center;
    padding-right: 34px;
    padding-right: 3.4rem; }
    .global-message__text p {
      display: inline; }
  .global-message__counter {
    font-weight: bold;
    padding-right: 34px;
    text-align: center; }
  .global-message__close {
    height: 24px;
    position: absolute;
    margin-top: -12px;
    top: 50%;
    width: 24px;
    right: 10px;
    right: 1rem; }
    @media (min-width: 30.0625em) {
      .global-message__close {
        right: 20px;
        right: 2rem; } }
    .lt-ie9 .global-message__close {
      right: 20px;
      right: 2rem; }
    .global-message__close:after {
      font-size: 24px;
      line-height: 22px; }

.global-message a, .global-message--primary a, .global-message--secondary a, .global-message--tertiary a, .global-message--quaternary a, .global-message--quinary a, .global-message--senary a, .global-message--xmas a, .global-message--xmas-alt a, .global-message p, .global-message--primary p, .global-message--secondary p, .global-message--tertiary p, .global-message--quaternary p, .global-message--quinary p, .global-message--senary p, .global-message--xmas p, .global-message--xmas-alt p, .global-message p strong, .global-message--primary p strong, .global-message--secondary p strong, .global-message--tertiary p strong, .global-message--quaternary p strong, .global-message--quinary p strong, .global-message--senary p strong, .global-message--xmas p strong, .global-message--xmas-alt p strong {
  color: #fff; }

.global-message--quinary a, .global-message--quinary p, .global-message--quinary p strong,
.global-message--senary a,
.global-message--senary p,
.global-message--senary p strong {
  color: #666; }

@-webkit-keyframes popup {
  0% {
    -webkit-transform: translateY(0px);
    -moz-transform: translateY(0px);
    -ms-transform: translateY(0px);
    -o-transform: translateY(0px);
    transform: translateY(0px); }
  40% {
    -webkit-transform: translateY(-80px);
    -moz-transform: translateY(-80px);
    -ms-transform: translateY(-80px);
    -o-transform: translateY(-80px);
    transform: translateY(-80px); }
  60% {
    -webkit-transform: translateY(-66px);
    -moz-transform: translateY(-66px);
    -ms-transform: translateY(-66px);
    -o-transform: translateY(-66px);
    transform: translateY(-66px); }
  80% {
    -webkit-transform: translateY(-73px);
    -moz-transform: translateY(-73px);
    -ms-transform: translateY(-73px);
    -o-transform: translateY(-73px);
    transform: translateY(-73px); }
  100% {
    -webkit-transform: translateY(-70px);
    -moz-transform: translateY(-70px);
    -ms-transform: translateY(-70px);
    -o-transform: translateY(-70px);
    transform: translateY(-70px); } }

@-moz-keyframes popup {
  0% {
    -webkit-transform: translateY(0px);
    -moz-transform: translateY(0px);
    -ms-transform: translateY(0px);
    -o-transform: translateY(0px);
    transform: translateY(0px); }
  40% {
    -webkit-transform: translateY(-80px);
    -moz-transform: translateY(-80px);
    -ms-transform: translateY(-80px);
    -o-transform: translateY(-80px);
    transform: translateY(-80px); }
  60% {
    -webkit-transform: translateY(-66px);
    -moz-transform: translateY(-66px);
    -ms-transform: translateY(-66px);
    -o-transform: translateY(-66px);
    transform: translateY(-66px); }
  80% {
    -webkit-transform: translateY(-73px);
    -moz-transform: translateY(-73px);
    -ms-transform: translateY(-73px);
    -o-transform: translateY(-73px);
    transform: translateY(-73px); }
  100% {
    -webkit-transform: translateY(-70px);
    -moz-transform: translateY(-70px);
    -ms-transform: translateY(-70px);
    -o-transform: translateY(-70px);
    transform: translateY(-70px); } }

@-o-keyframes popup {
  0% {
    -webkit-transform: translateY(0px);
    -moz-transform: translateY(0px);
    -ms-transform: translateY(0px);
    -o-transform: translateY(0px);
    transform: translateY(0px); }
  40% {
    -webkit-transform: translateY(-80px);
    -moz-transform: translateY(-80px);
    -ms-transform: translateY(-80px);
    -o-transform: translateY(-80px);
    transform: translateY(-80px); }
  60% {
    -webkit-transform: translateY(-66px);
    -moz-transform: translateY(-66px);
    -ms-transform: translateY(-66px);
    -o-transform: translateY(-66px);
    transform: translateY(-66px); }
  80% {
    -webkit-transform: translateY(-73px);
    -moz-transform: translateY(-73px);
    -ms-transform: translateY(-73px);
    -o-transform: translateY(-73px);
    transform: translateY(-73px); }
  100% {
    -webkit-transform: translateY(-70px);
    -moz-transform: translateY(-70px);
    -ms-transform: translateY(-70px);
    -o-transform: translateY(-70px);
    transform: translateY(-70px); } }

@keyframes popup {
  0% {
    -webkit-transform: translateY(0px);
    -moz-transform: translateY(0px);
    -ms-transform: translateY(0px);
    -o-transform: translateY(0px);
    transform: translateY(0px); }
  40% {
    -webkit-transform: translateY(-80px);
    -moz-transform: translateY(-80px);
    -ms-transform: translateY(-80px);
    -o-transform: translateY(-80px);
    transform: translateY(-80px); }
  60% {
    -webkit-transform: translateY(-66px);
    -moz-transform: translateY(-66px);
    -ms-transform: translateY(-66px);
    -o-transform: translateY(-66px);
    transform: translateY(-66px); }
  80% {
    -webkit-transform: translateY(-73px);
    -moz-transform: translateY(-73px);
    -ms-transform: translateY(-73px);
    -o-transform: translateY(-73px);
    transform: translateY(-73px); }
  100% {
    -webkit-transform: translateY(-70px);
    -moz-transform: translateY(-70px);
    -ms-transform: translateY(-70px);
    -o-transform: translateY(-70px);
    transform: translateY(-70px); } }

@-webkit-keyframes teaser {
  0% {
    -webkit-transform: translateY(-70px);
    -moz-transform: translateY(-70px);
    -ms-transform: translateY(-70px);
    -o-transform: translateY(-70px);
    transform: translateY(-70px); }
  40% {
    -webkit-transform: translateY(-100px);
    -moz-transform: translateY(-100px);
    -ms-transform: translateY(-100px);
    -o-transform: translateY(-100px);
    transform: translateY(-100px); }
  60% {
    -webkit-transform: translateY(-100px);
    -moz-transform: translateY(-100px);
    -ms-transform: translateY(-100px);
    -o-transform: translateY(-100px);
    transform: translateY(-100px); }
  70% {
    -webkit-transform: translateY(-66px);
    -moz-transform: translateY(-66px);
    -ms-transform: translateY(-66px);
    -o-transform: translateY(-66px);
    transform: translateY(-66px); }
  85% {
    -webkit-transform: translateY(-73px);
    -moz-transform: translateY(-73px);
    -ms-transform: translateY(-73px);
    -o-transform: translateY(-73px);
    transform: translateY(-73px); }
  100% {
    -webkit-transform: translateY(-70px);
    -moz-transform: translateY(-70px);
    -ms-transform: translateY(-70px);
    -o-transform: translateY(-70px);
    transform: translateY(-70px); } }

@-moz-keyframes teaser {
  0% {
    -webkit-transform: translateY(-70px);
    -moz-transform: translateY(-70px);
    -ms-transform: translateY(-70px);
    -o-transform: translateY(-70px);
    transform: translateY(-70px); }
  40% {
    -webkit-transform: translateY(-100px);
    -moz-transform: translateY(-100px);
    -ms-transform: translateY(-100px);
    -o-transform: translateY(-100px);
    transform: translateY(-100px); }
  60% {
    -webkit-transform: translateY(-100px);
    -moz-transform: translateY(-100px);
    -ms-transform: translateY(-100px);
    -o-transform: translateY(-100px);
    transform: translateY(-100px); }
  70% {
    -webkit-transform: translateY(-66px);
    -moz-transform: translateY(-66px);
    -ms-transform: translateY(-66px);
    -o-transform: translateY(-66px);
    transform: translateY(-66px); }
  85% {
    -webkit-transform: translateY(-73px);
    -moz-transform: translateY(-73px);
    -ms-transform: translateY(-73px);
    -o-transform: translateY(-73px);
    transform: translateY(-73px); }
  100% {
    -webkit-transform: translateY(-70px);
    -moz-transform: translateY(-70px);
    -ms-transform: translateY(-70px);
    -o-transform: translateY(-70px);
    transform: translateY(-70px); } }

@-o-keyframes teaser {
  0% {
    -webkit-transform: translateY(-70px);
    -moz-transform: translateY(-70px);
    -ms-transform: translateY(-70px);
    -o-transform: translateY(-70px);
    transform: translateY(-70px); }
  40% {
    -webkit-transform: translateY(-100px);
    -moz-transform: translateY(-100px);
    -ms-transform: translateY(-100px);
    -o-transform: translateY(-100px);
    transform: translateY(-100px); }
  60% {
    -webkit-transform: translateY(-100px);
    -moz-transform: translateY(-100px);
    -ms-transform: translateY(-100px);
    -o-transform: translateY(-100px);
    transform: translateY(-100px); }
  70% {
    -webkit-transform: translateY(-66px);
    -moz-transform: translateY(-66px);
    -ms-transform: translateY(-66px);
    -o-transform: translateY(-66px);
    transform: translateY(-66px); }
  85% {
    -webkit-transform: translateY(-73px);
    -moz-transform: translateY(-73px);
    -ms-transform: translateY(-73px);
    -o-transform: translateY(-73px);
    transform: translateY(-73px); }
  100% {
    -webkit-transform: translateY(-70px);
    -moz-transform: translateY(-70px);
    -ms-transform: translateY(-70px);
    -o-transform: translateY(-70px);
    transform: translateY(-70px); } }

@keyframes teaser {
  0% {
    -webkit-transform: translateY(-70px);
    -moz-transform: translateY(-70px);
    -ms-transform: translateY(-70px);
    -o-transform: translateY(-70px);
    transform: translateY(-70px); }
  40% {
    -webkit-transform: translateY(-100px);
    -moz-transform: translateY(-100px);
    -ms-transform: translateY(-100px);
    -o-transform: translateY(-100px);
    transform: translateY(-100px); }
  60% {
    -webkit-transform: translateY(-100px);
    -moz-transform: translateY(-100px);
    -ms-transform: translateY(-100px);
    -o-transform: translateY(-100px);
    transform: translateY(-100px); }
  70% {
    -webkit-transform: translateY(-66px);
    -moz-transform: translateY(-66px);
    -ms-transform: translateY(-66px);
    -o-transform: translateY(-66px);
    transform: translateY(-66px); }
  85% {
    -webkit-transform: translateY(-73px);
    -moz-transform: translateY(-73px);
    -ms-transform: translateY(-73px);
    -o-transform: translateY(-73px);
    transform: translateY(-73px); }
  100% {
    -webkit-transform: translateY(-70px);
    -moz-transform: translateY(-70px);
    -ms-transform: translateY(-70px);
    -o-transform: translateY(-70px);
    transform: translateY(-70px); } }

.permission-bar {
  background-color: #1b1b19;
  display: none;
  height: 540px;
  overflow: hidden;
  position: fixed;
  bottom: -540px;
  right: 20px;
  z-index: 9;
  text-decoration: none;
  width: 390px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  font-size: 16px;
  font-size: 1.6rem;
  padding: 70px 20px 20px 20px;
  padding:  7rem 2rem 2rem 2rem;
  -webkit-transition: bottom 0.3s ease-out;
  -moz-transition: bottom 0.3s ease-out;
  -o-transition: bottom 0.3s ease-out;
  transition: bottom 0.3s ease-out;
  -webkit-animation: popup 1s 6s forwards, teaser 2s 30s;
  -moz-animation: popup 1s 6s forwards, teaser 2s 30s;
  -o-animation: popup 1s 6s forwards, teaser 2s 30s;
  animation: popup 1s 6s forwards, teaser 2s 30s; }
  @media (min-width: 48.0625em) {
    .permission-bar {
      display: block; } }
  .lt-ie9 .permission-bar {
    display: block; }
  .permission-bar:hover {
    bottom: -535px;
    color: #fff; }
  .permission-bar.is-active {
    bottom: -70px; }
  .lt-ie10 .permission-bar {
    bottom: 0;
    padding-bottom: 0;
    height: 70px; }
    .lt-ie10 .permission-bar:hover {
      height: 75px; }
    .lt-ie10 .permission-bar.is-active {
      height: 584px;
      padding-bottom: 20px;
      padding-bottom: 2rem; }
  .permission-bar__headline {
    color: #af9b56;
    cursor: pointer;
    height: 70px;
    font-weight: 300;
    margin: 0;
    position: absolute;
    left: 0;
    top: 0;
    text-align: center;
    text-decoration: none;
    width: 100%;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    font-size: 34px;
    font-size: 3.4rem;
    padding: 12px 0 0;
    padding:  1.2rem 0 0; }
    .permission-bar__headline:hover {
      color: #af9b56;
      text-decoration: none; }
  .permission-bar__text {
    color: #fff;
    font-family: "Open Sans", sans-serif;
    margin-top: 0;
    text-align: center;
    font-size: 16px;
    font-size: 1.6rem; }
    .permission-bar__text p:first-child {
      margin-top: 0; }
    .permission-bar__text a {
      color: #af9b56; }
    .permission-bar__text img {
      display: block;
      margin: auto; }
  .permission-bar__button {
    width: 100%;
    margin-top: 10px;
    margin-top: 1rem; }
  .permission-bar__input {
    font-size: 14px;
    font-size: 1.4rem;
    margin: 0 0 10px;
    margin:  0 0 1rem;
    height: 39px;
    height: 3.9rem; }
  .permission-bar__close {
    position: absolute;
    right: 4px;
    top: -29px;
    -webkit-transition: top 0.3s 0.5s;
    -webkit-transition-delay: ease-out;
    -moz-transition: top 0.3s 0.5s ease-out;
    -o-transition: top 0.3s 0.5s ease-out;
    transition: top 0.3s 0.5s ease-out; }
    .is-active .permission-bar__close {
      top: 4px; }

.blog-topimage {
  width: 100%;
  background-color: #000;
  height: 30vw;
  overflow: hidden;
  position: relative;
  min-height: 340px;
  max-height: 500px; }

.blog-topimage__image {
  opacity: 0.4;
  position: absolute;
  left: 50%;
  vertical-align: top;
  height: 100%;
  transform: translate(-50%, 0); }
  @media (min-width: 48.0625em) {
    .blog-topimage__image {
      transform: translate(0, -50%);
      top: 50%;
      left: auto;
      width: 100%;
      height: auto; } }
  .lt-ie9 .blog-topimage__image {
    transform: translate(0, -50%);
    top: 50%;
    left: auto;
    width: 100%;
    height: auto; }

.blog-nav {
  margin-top: 44px;
  padding: 10px 0 2px; }
  .blog-nav__title {
    display: inline-block;
    float: left;
    padding: 5px 10px 5px 0;
    padding:  0.5rem 1rem 0.5rem 0; }
  .blog-nav__share {
    margin-bottom: 8px;
    margin-bottom: 0.8rem; }
    @media (min-width: 48.0625em) {
      .blog-nav__share {
        float: right; } }
    .lt-ie9 .blog-nav__share {
      float: right; }
  .blog-nav__share-tools {
    float: left;
    margin-top: 5px;
    margin-top: 0.5rem; }

.blog-nav__tags {
  box-sizing: border-box;
  height: auto !important;
  margin-bottom: 30px;
  opacity: 1;
  overflow: hidden;
  text-align: center;
  transition: opacity 0.5s ease-in-out;
  width: 100%; }
  .blog-nav__tags.is-loading {
    height: 0 !important;
    opacity: 0; }
  .blog-nav__tags .rsThumb {
    display: inline-block;
    float: none; }
  .blog-nav__tags .rsThumbsContainer {
    text-align: center; }
  .blog-nav__tags .rsThumbs {
    width: 100% !important; }
  .blog-nav__tags .rsNavSelected {
    border: none; }

.blog-nav__tags--blue {
  background-color: #394d64;
  height: 56px !important;
  text-align: center;
  width: 100%;
  margin: 0; }
  .blog-nav__tags--blue .rsNavItem {
    border: none; }
  .blog-nav__tags--blue .blog-nav__tags-item {
    color: #fff;
    font-size: 16px;
    font-weight: 100;
    opacity: 0.5;
    margin: 0 10px;
    padding: 14px 0 18px;
    position: relative; }
    .blog-nav__tags--blue .blog-nav__tags-item.is-active {
      opacity: 1; }
    .blog-nav__tags--blue .blog-nav__tags-item.is-active::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 3px;
      background-color: #fff; }

.blog__header {
  text-align: center; }

.blog-nav__tags-item {
  display: inline-block; }

.blog-nav__tag {
  padding: 5px 14px;
  background-color: transparent;
  display: inline-block;
  border: 1px solid #d6d6d6;
  border-radius: 3px;
  transition: background-color 0.3s ease-in-out;
  width: 100px; }
  .blog-nav__tag:hover {
    background-color: #e3e3e3; }

.blog-post__author-image {
  border-radius: 50%;
  overflow: hidden;
  width: 63px;
  height: 63px;
  margin: 10px auto; }

.blog-post__top-content {
  -webkit-font-smoothing: antialiased;
  color: #fff;
  margin: 0;
  position: absolute;
  left: 50%;
  top: 50%;
  text-align: center;
  transform: translate(-50%, -50%);
  z-index: 1; }

.blog-post__header {
  color: #fff;
  margin-top: 0;
  display: inline-block; }
  @media (min-width: 25em) {
    .blog-post__header {
      text-align: center; } }
  .lt-ie9 .blog-post__header {
    text-align: center; }
  @media (min-width: 48.0625em) {
    .blog-post__header {
      font-size: 55px; } }
  .lt-ie9 .blog-post__header {
    font-size: 55px; }

.blog-post__subheader {
  color: #000;
  margin: 0;
  font-size: 24px;
  margin: 0 auto 40px;
  line-height: 1.6;
  max-width: 700px; }

.blog-post__date {
  font-size: 16px;
  font-style: italic;
  font-family: "Times", "Times New Roman", serif;
  text-align: center; }

.blog-post {
  margin-top: 0;
  padding-top: 0;
  border: none; }
  .blog-post__details {
    font-family: "Times", "Times New Roman", serif;
    font-style: italic;
    text-align: center; }
  .blog-post__comment {
    min-height: 150px; }

.blog-post__body, .blog-comment__item {
  box-sizing: border-box;
  margin: 0 auto;
  max-width: 980px;
  padding: 0 10px; }
  .blog-post__body img, .blog-comment__item img {
    display: block;
    max-width: 100%; }

@media (min-width: 25em) {
  .blog-item {
    padding: 0 20px;
    min-height: 13.5714285714em;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    width: 50%;
    float: left; } }

.lt-ie9 .blog-item {
  padding: 0 20px;
  min-height: 13.5714285714em;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  width: 50%;
  float: left; }

@media (min-width: 30.0625em) {
  .blog-item {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    width: 50%;
    float: left; } }

.lt-ie9 .blog-item {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  width: 50%;
  float: left; }

@media (min-width: 48.0625em) {
  .blog-item {
    min-height: 18.5714285714em;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    width: 33.3333333333%;
    float: left; } }

.lt-ie9 .blog-item {
  min-height: 18.5714285714em;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  width: 33.3333333333%;
  float: left; }

.blog-item:nth-of-type(3) {
  clear: left; }

.blog-item__image-container {
  line-height: 0;
  position: relative; }

.blog-item__image {
  width: 100%; }

.blog-item__content {
  padding: 20px; }
  @media (min-width: 25em) {
    .blog-item__content {
      min-height: 130px; } }
  .lt-ie9 .blog-item__content {
    min-height: 130px; }

.blog-item__date {
  color: #000;
  float: right;
  font-family: "Times", "Times New Roman", serif;
  font-size: 14px;
  font-style: italic;
  opacity: 0.5; }

.blog-item__tags {
  color: #B69D4B;
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  font-weight: 600; }

.blog-item--large {
  width: 100%; }
  @media (min-width: 30.0625em) {
    .blog-item--large {
      float: left;
      width: 50%; } }
  .lt-ie9 .blog-item--large {
    float: left;
    width: 50%; }
  @media (min-width: 25em) {
    .blog-item--large .blog-item__content {
      min-height: 180px; } }
  .lt-ie9 .blog-item--large .blog-item__content {
    min-height: 180px; }
  @media (min-width: 30.0625em) {
    .blog-item--large .blog-item__header {
      font-size: 24px; } }
  .lt-ie9 .blog-item--large .blog-item__header {
    font-size: 24px; }

.blog-comment {
  margin: 0 auto; }
  @media (min-width: 48.0625em) {
    .blog-comment {
      width: 80%; } }
  .lt-ie9 .blog-comment {
    width: 80%; }
  @media (min-width: 73.5em) {
    .blog-comment {
      width: 75%; } }
  .blog-comment__item {
    background-color: #fff;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    margin-bottom: 1px;
    margin-bottom: 0.1rem; }
  .blog-comment__text-container {
    float: left; }
  .blog-comment__header {
    font-family: "Times", "Times New Roman", serif;
    font-style: italic;
    margin-top: 0; }
  .blog-comment__image {
    float: left;
    height: 50px;
    width: 50px;
    margin-right: 20px;
    margin-right: 2rem; }
