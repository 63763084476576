// Templates are building blocks and don’t stand on their own and rarely describe look and feel.
// Templates are single, repeatable patterns that can be put together to form a component.
// A template is something you wouldn’t ever need to target in your application logic, and therefore can be safely extended with a preprocessor.

%list-clean {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

%dotted-list {
  @extend %list-clean;
  li {
    padding-left: 0.8em;
    position: relative;
    &:before {
      content:"\00b7";
      display: inline;
      position: absolute;
        left: 0;
    }
  }
}

%trim-text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

// Clearfix
// ==========================================================================

@mixin clearfix {
  // For modern browsers
  // 1. The space content is one way to avoid an Opera bug when the
  //    contenteditable attribute is included anywhere else in the document.
  //    Otherwise it causes space to appear at the top and bottom of elements
  //    that are clearfixed.
  // 2. The use of `table` rather than `block` is only necessary if using
  //    `:before` to contain the top-margins of child elements.
  &:before,
  &:after {
    content: " ";
    display: table;
  }

  &:after {
    clear: both;
  }

  // For IE 6/7 only
  *zoom: 1;
}

%clearfix { @include clearfix; }

%border-box { @include box-sizing(border-box); }

%border-circle { @include border-radius(100%); } // in some cases it doesn't work properly with 50% - it might be sub-pixel rounding

%no-rounding { @include border-radius(0); }

%background {
  &--primary { background-color: $brand-color-primary; }
  &--secondary { background-color: $brand-color-secondary; }
  &--tertiary { background-color: $brand-color-tertiary; }
  &--quaternary { background-color: $brand-color-quaternary; }
  &--quinary { background-color: $brand-color-quinary; }
  &--senary { background-color: $brand-color-senary; }
}


$close-button-size: 24px !default;

%close-button {
  background-color: $close-button-background-color;
  border: 1px solid $close-button-border-color;
  color: $close-button-color;
  cursor: pointer;
  height: $close-button-size;
  overflow: hidden;
  position: relative;
  text-align: center;
  text-indent: 99px;
  width: $close-button-size;
  @include box-sizing(border-box);

  &:hover { color: $close-button-color; }

  &:after {
    content: "\d7";
    display: inline-block;
    font-size: $close-button-size;
    line-height: floor($close-button-size * 0.95) - 2; // subtracting border
    text-indent: 0;
    position: absolute;
      left: 0;
      top: 0;
    width: 100%
  }
}
