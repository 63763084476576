
// Typography
// ==========================================================================

// Text behaviors
// --------------------------------------

$types : (
  text-default : (
    defaults : (
      color: $color-text,
      line-height: 1.5,
      font-family: $font,
      font-size: 12px,
      font-style: normal,
      font-weight: 300
    ),
    $layout-xl : (
      font-size: 14px
    )
  ),
  text-large : (
    defaults : (
      font-size: 14px,
      line-height: 1.3,
    ),
    $layout-xl : (
      font-size: 16px
    )
  ),
  text-xlarge : (
    defaults : (
      line-height: 1.4,
      font-size: 14px
    ),
    $layout-xl : (
      font-size: 18px
    )
  ),
  text-xxlarge : (
    defaults : (
      line-height: 1.4,
      font-size: 16px
    ),
    $layout-xl : (
      font-size: 20px
    )
  ),
  text-small : (
    defaults : (
      font-size: 11px
    ),
    $layout-xl : (
      font-size: 13px
    )
  ),
  text-xsmall : (
    defaults : (
      font-size: 10px
    ),
    $layout-xl : (
      font-size: 11px
    )
  ),
  header-large : (
    extend : "%header",
    defaults : (
      color: $color-display,
      font-weight: 300,
      margin-bottom: 0.5em
    )
  ),
  header-large-1 : (
    extend : "%header-large",
    defaults : (
      line-height: 1.1,
      font-size: 24px
    ),
    $layout-xl : (
      font-size: 48px
    )
  ),
  header-large-2 : (
    extend : "%header-large",
    defaults : (
      line-height: 1.2,
      font-size: 18px
    ),
    $layout-xl : (
      font-size: 28px
    )
  ),
  header-large-3 : (
    extend : "%header-large" "%text-xlarge",
    defaults : (
      font-family: $font-display,
      font-style: italic
    )
  ),
  header-small : (
    extend : "%header",
    defaults : (
      color: $color-display,
      line-height: 1.4,
      font-weight: bold,
      margin-bottom: 0.8em
    )
  ),
  header-small-1 : (
    extend : "%header-small" "%text-large"
  ),
  lead : (
    extend : "%header-large" "%text-large",
    defaults: (
      line-height: 1.63
    )
  ),
  subject : (
    extend : "%lead",
    defaults : (
      font-family: $font-display,
      font-style: italic
    )
  ),
  caption : (
    defaults : (
      font-size: 10px
    ),
    $layout-xl : (
      font-size: 12px
    )
  ),
  button : (
    extend : "%lead"
  ),
  button-small : (
    extend : "%caption"
  )
);

// This placeholder selector is used to define header
// specific styles in other partials
%header {}

// Setting type placeholder selectors
// according to $types map in settings
@include set-type-placeholders;

// --------------------------------------

%emphasize {
  @include use-type( header-small );
  line-height: 1.5;
}

h1,
.h1 { @include use-type( header-large-1 ); }
h2,
.h2 { @include use-type( header-large-2 ); }
h3,
.h3 { @include use-type( header-large-3 ); }
h4,
.h4 { @include use-type( header-small-1 ); }

.lead {
  margin-bottom: 1em;
  @include use-type( lead );
  @include susy-breakpoint( $layout-xl... ) { margin-bottom: 2em; }
  @include susy-breakpoint( $layout-xxl... ) { margin-bottom: 3em; }
}

// Paragraphs
p {
  @include rem( margin-bottom, 10px );

  a { border-bottom: 2px solid $brand-color-secondary; }
}

// Links
%link {
  color: $color-link;
  font-weight: normal;
  text-decoration: none;

  &:hover,
  &:focus {
    color: $color-link-hover;
  }
  &:focus {
    box-shadow: 0;
    outline: dotted thin $color-link-hover;
  }
  &:active {
    color: $color-link-active;
    text-decoration: none;
  }
  &[href^=tel]{
    color:inherit;
    text-decoration:none;
  }
}

a { @extend %link; }

// Text selection
::selection {
  background: $color-selection-bg;
  color: $color-selection;
  text-shadow: none;
}

::-moz-selection {
  background: $color-selection-bg;
  color: $color-selection;
  text-shadow: none;
}

// Formatting (inline)
em { font-style: italic; }
strong,
b { @extend %emphasize; }

figcaption,
.caption,
.label { @include use-type( caption ); }

@mixin invert-text {
  color: $color-text-inverse;
  a { color: $message-information-color; }
  #{ headings(1,3) },
  strong,
  b { color: inherit; }
}

.invert-text {
  @include invert-text;
  %button { color: inherit; }
}

.subject {
  display: block;
  @include use-type(subject);
  &:after {
    border-bottom: 1px solid $color-display;
    content: "";
    display: inline-block;
    margin-left: 5px;
    position: relative;
      bottom: -3px;
    width: 1.5em;

    .invert-text & { border-color: $color-text-inverse; }
  }
}
