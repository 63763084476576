// Lists
ul { list-style: disc; }
ol { list-style: decimal; }

nav ul { list-style: none; }

// ==========================================================================
// Icon list

%list-icon {
  display: inline;
  position: relative;
    bottom: px-to-em(-2px, $font-size-large);
  @include rem( margin-right, 10px );
}

%icon-list { @extend %list-clean; }

%icon-list-item {
  margin-bottom: 0; // overriding header margin
  @include use-type(subject);
  @include susy-breakpoint( $layout-l... ) {
    display: inline-block;
    margin-right: px-to-em(40px, 16px); // 16px is header-large-3 on desktop
    &:last-child { margin-right: 0; }
  }
  &:before { @extend %list-icon; }
}

// ==========================================================================
// Tick list

.tick-list {
  @extend %icon-list;
  &__item {
    @extend %icon-list-item;
    &:before { @extend %icon--tick; }
  }
}

// ==========================================================================
// Feature list

.feature-list {
  @extend %icon-list;
  &__item {

    &.deactive {
      &,
      &:before { color: $color-light; }
      .feature-list__item-text { text-decoration: line-through; }
    }

    &:before {
      @extend %list-icon;
      color: $color-display;
      font-size: px-to-em(25px, $font-size-large);
    }
    // Extending icons
    @each $name, $settings in $icons-features {
      &--#{ $name } {
        @extend .feature-list__item !optional;
        @extend .icon--#{ $name };
      }
    }
  }
}
