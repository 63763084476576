
.page {
  // ----------------------------------------
  // Adding padding to cater for fixed header
  $assumed-global-message-height-mobile: 85px; // 3 lines of text
  $assumed-global-message-height-tablet: 73px; // 2 lines of text
  $assumed-global-message-height-desktop: 52px; // 1 line of text

  // Setting transition with delay, like in _message.scss
  @include transition( padding-top 200ms ease 300ms ); // Animate out

  // Adding necessary padding
  padding-top: $header-height-mobile;
  body.has-global-message & {
    padding-top: $header-height-mobile + $assumed-global-message-height-mobile;
    @include transition(none); // Animate in (no animation)
  }
  @include susy-breakpoint( $layout-l... ) {
    padding-top: $header-height-tablet + $header-nav-height;
    body.has-global-message & {
      // Adding assumed message height
      padding-top: $header-height-mobile + $header-nav-height + $assumed-global-message-height-tablet;
    }
  }
  @include susy-breakpoint( $layout-xl... ) {
    padding-top: $header-height-desktop + $header-nav-height + 50px;
    body.has-global-message & {
      // Adding assumed message height
      padding-top: $header-height-desktop + $header-nav-height + $assumed-global-message-height-desktop + 40px;
    }
  }
  @include susy-breakpoint( $layout-xxl... ) {
    padding-top: $header-height-desktop + $header-nav-height;
    body.has-global-message & {
      // Adding assumed message height
      padding-top: $header-height-desktop + $header-nav-height + $assumed-global-message-height-desktop;
    }
  }

  @media (min-width: 1175px) and (max-width: 1380px) {
    padding-top: $header-height-desktop + $header-nav-height + 50px;
  }
}

.main {
  &--has-aside {
    @extend %section-space-bottom;
    @include susy-breakpoint( $layout-xl... ) {
      @include span(6);
    }
    @include susy-breakpoint( $layout-xxl... ) {
      @include span(8);
    }
  }
  // Making extra space for basket
  &--checkout {
    @extend .main--has-aside;
    @include susy-breakpoint( $layout-xxl... ) {
      @include span(7);
    }
  }
}

.aside {
  @include susy-breakpoint( $layout-xl... ) {
    @include span(6);
    padding-left: column-padding();
  }
  @include susy-breakpoint( $layout-xxl... ) {
    @include span(4);
  }

  // Making extra space for basket
  &--checkout {
    @extend .aside;
    @extend %section-space-bottom;

    @include susy-breakpoint( $layout-xl... ) { @include span(5 omega); }
  }

  // Setting offset of basket
  .basket {
    margin: 0;

    @include susy-breakpoint( $layout-xl... ) { margin-top: 20px; }
  }
}

%section-space-bottom {
  margin-bottom: 5%;
  @include susy-breakpoint( $layout-xl... ) { margin-bottom: 4%; }
}

.section {
  @extend %clearfix;
  margin: 5% 0;

  @include susy-breakpoint( $layout-xl... ) { margin: 4% 0; }

  @at-root {
    %section--highlighted {
      border: {
        bottom: 1px solid transparent;
        top: 1px solid transparent;
      }
      clear: both;
      padding: 5% 0;

      @include susy-breakpoint( $layout-xl... ) { padding: 2.5% 0; }
    }
  }

  &--limit {
    @include susy-breakpoint( $layout-xl... ) { margin: 30px 0; }
  }

  &--dark {
    @extend %background--tertiary;
    @extend %section--highlighted;
    border-color: darken( $brand-color-tertiary, 5% );
  }

  &--highlight {
    @extend %background--quinary;
    @extend %section--highlighted;
    border-color: darken( $brand-color-quinary, 5% );
  }

  &--campaign {
    @extend %background--senary;
    @extend %section--highlighted;
    border: none;

    @include susy-breakpoint( $layout-xl... ) { padding: 4% 0; }
  }

  &__header {
    &--center {
      @extend .section__header;
      @include susy-breakpoint( $layout-m... ) { text-align: center; }
      @include susy-breakpoint( $layout-xl... ) {
        margin: 0 auto;
        width: span(10);
      }
      @include susy-breakpoint( $layout-xxl... ) { width: span(8); }

    }

    h1 { margin-top: 0; }
  }

  &__header-subject {
    @extend .subject;

    + h1,
    + h2,
    + h3 { margin-top: 0; }
  }

  &__text {
    margin: auto;

    @include susy-breakpoint( $layout-xl... ) { max-width: 60em; }

    %button { margin-top: px-to-em(20px, 16px); }
  }

  &__text-header { @extend .image-block__header; }

  &__text-subject { @extend .subject; } // in core/base/_typography
}

.container {
  @extend %container;

  &--center {
    @extend .container;
    @include susy-breakpoint( $layout-xl... ) { text-align: center; }
  }
}

.divider {
  border: none;
  border-top: 1px solid $color-border;
  margin: 0;
}

.text--split {
  @include susy-breakpoint( $layout-xl... ) {
    @include columns(2);
    @include column-gap( $gutter-width );

    p:first-child { margin-top: 0; } // Fixing weird bug that creates offset
  }
}

.text--center {
  text-align: center;
}

.textcol {
  @include susy-breakpoint( $layout-xl... ) {
    @include span(6);
    padding-left: column-padding() / 2;
  }
  &--first {
    @extend .textcol;
    @include susy-breakpoint( $layout-xl... ) {
      padding-left: 0;
      padding-right: column-padding() / 2;
    }
  }
}

// Form errors
.errors {
  @extend .section;
  @extend %section--highlighted;
  background-color: $message-error-background;
  border-color: $message-error-color;
  color: $color-display;

  &__header { margin-top: 0; }
  &__list { margin-bottom: 0; }
}

.raptor-container {
  position: relative;
  max-height: 2000px;

  overflow: hidden;
  transition: max-height 1.8s ease-in-out;

  &.is-loading {
    max-height: 32px;
    min-height: 32px;

    &:after {
      content: '';
      background: url( '../images/loader.gif' ) no-repeat;
      position: absolute;
        top: 50%;
        left: 50%;
      width: 32px;
      height: 32px;
      margin: -16px 0 0 -16px;
    }
  }
}
