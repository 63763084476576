
$image-block-layout-xl: join(( px-to-em(1000px), $cols-xl), $layout-ie-fallback);

.image-block {
  @extend %clearfix;
  @extend .section;
  position: relative;
  overflow: hidden;

  &--left,
  &--right { @extend .image-block; }

  &__container {
    #{ $layout-ie-fallback } & {
      position: relative;
      @include custom-container;
    }
    @include susy-breakpoint( $layout-xxl... ) { @include custom-container; }
  }

  &__content {
    @extend %column-padding;
    padding-bottom: 0;
    @include use-type(text-large);

    @include susy-breakpoint( $image-block-layout-xl... ) { @include span(6); }
    
    @include susy-breakpoint( $layout-xxl... ) {
      padding-left: 0;
      width: span(4);
    }

    @media (min-width: 1000px) and (max-width: 1580px) {
      @include span(6);
      min-height: 550px;
    }

    .image-block--left & {
      @include susy-breakpoint( $image-block-layout-xl... ) { float: right; }
      @include susy-breakpoint( $layout-xxl... ) {
        padding-left: px-to-em($gutter-width, 16px);
        padding-right: 0;
      }
    }

    %button {
      margin-top: px-to-em(20px, 16px);

      @include susy-breakpoint( $layout-xxl... ) { margin-top: px-to-em(40px, 16px); }
    }
  }

  &__subject { @extend .subject; } // in core/base/_typography

  &__header {
    margin-top: 0;
    @include use-type(header-large-1);
  }

  &__picture {
    position: relative;
    display: block;
    line-height: 0;

    @include susy-breakpoint( $image-block-layout-xl... ) {
      height: 100%;
      overflow: hidden;
      position: absolute;
        right: 0;
        top: 0;
        z-index: 1;
      width: 50%;
    }

    @include susy-breakpoint( $layout-xxl... ) {
      overflow: visible;
      position: relative;
      @include span(8 last);
    }

    .image-block--left & {
      @include susy-breakpoint( $image-block-layout-xl... ) {
        left: 0;
        right: auto;
      }
      @include susy-breakpoint( $layout-xxl... ) {
        float: left;
      }
    }

    @media (min-width: 1000px) and (max-width: 1580px) {
      height: 100%;
      overflow: hidden;
      position: absolute;
      right: 0;
      top: 0;
      z-index: 1;
      width: 50%;
    }

  }
  &__image {
    width: 100%;

    @include susy-breakpoint( $image-block-layout-xl... ) {
      height: 100%;
      width: auto;
    }
    @include susy-breakpoint( $layout-xxl... ) {
      height: auto;
      width: 100%;
    }

    .image-block--left & {
      @include susy-breakpoint( $image-block-layout-xl... ) { float: right; }
    }
  }

  .splat {
    position: absolute;
      left: 0;
      top: 0;
  }
}

@media (min-width: 1000px) and (max-width: 1580px) {
  .image-stretch--left,
  .image-stretch--right {
    .image-block__content {
      background-color: $brand-color-tertiary;
    }
  }
}
