// Layout rules divide the page into sections. Layouts hold one or more components together.
// Layout rules are anything related to the position of the element in the document.
// e.g.: columns span, display, positioning and more
// Visual styling should be in _global, _components or _templates
// Layout rules are also responsible for layout changes due to browser size (responsive)

// Grid stuff
// ==========================================================================

body { @extend %container-ie-fallback; }

%container-ie-fallback {
  #{ $layout-ie-fallback } & { min-width: px-to-em($bp-l-xl); }
}

@mixin custom-container {
  @include container;
  @include rem( padding, 0 $grid-padding / 2 );

  @include susy-breakpoint( $layout-l... ) {
    @include rem( padding, 0 $grid-padding );
  }
}

%container { @include custom-container; }

@function column-padding( $context: $bp-xl-xxl ) {
  @return percentage( $gutter-width / $context );
}

%column-padding { padding: column-padding(); }

%column-gutter {
  padding-left: column-padding() / 2;
  padding-right: column-padding() / 2;
}

%block-element-margin { @include rem( margin-bottom, 20px ); }

.row {
  @include susy-breakpoint( $layout-m... ) { margin: 0 -20px; }
}

.img-fluid { width: 100%; }

.pt-1 { padding-top: 10px !important; }
.pt-2 { padding-top: 20px !important; }
.pt-3 { padding-top: 30px !important; }
.pt-4 { padding-top: 40px !important; }
.pt-5 { padding-top: 50px !important; }
.pt-5 { padding-top: 50px !important; }
.pt-6 { padding-top: 60px !important; }
.pt-7 { padding-top: 70px !important; }
.pt-8 { padding-top: 80px !important; }
.pt-9 { padding-top: 90px !important; }

.pb-1 { padding-bottom: 10px !important; }
.pb-2 { padding-bottom: 20px !important; }
.pb-3 { padding-bottom: 30px !important; }
.pb-4 { padding-bottom: 40px !important; }
.pb-5 { padding-bottom: 50px !important; }
.pb-5 { padding-bottom: 50px !important; }
.pb-6 { padding-bottom: 60px !important; }
.pb-7 { padding-bottom: 70px !important; }
.pb-8 { padding-bottom: 80px !important; }
.pb-9 { padding-bottom: 90px !important; }

.pt-lg-9 {
  @include susy-breakpoint( $layout-l... ) { padding-top: 90px !important; }
}